import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import portfoliodata from "../../assets/json/portfolio.json"
import { Link } from "react-router-dom";
import  "./portfolioDetails.css"
import { useParams } from 'react-router-dom';
import styled from "styled-components";

// const StyledDiv = styled.div`
//    background-image: url("../../assets/images/MobileappTrainning.jpg");
   
// `;

const StyledImageDiv = styled.div`
    background-image: ${(props) => 'url("' + props.imageUrl + '")'};
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 21vw;
    width: 23vw;
    border: 1px solid #000;
    @media screen and (max-width: 768px) {
        height: 343px;
        width: 100%;    
    }
`;

const PortfolioDetails = () => {
    const params = useParams();
    const [filteredDatas, setFilterdData] = useState({});

    useEffect(() => {
        let projectType = params.projectType;
        console.log("fjfj",projectType);
        let Obj = {};
        portfoliodata && portfoliodata.portfolios && portfoliodata.portfolios.forEach((item) => {
            if (item.url) {
              
                if (item.url === projectType) {
                    Obj = item;

                }
                setFilterdData(Obj);
            }

        })
    }, [params])
    console.log("filteredDatas",filteredDatas);
    
    return (

        <>
                      <div className="container detailportfolio-information">
                            <div>
                            <h3 className="information-project-top-head-aboutproject">Details</h3>
                            </div>
                             
                            <h4  className="details-portfolio-heading3-detail" >About Customer/Requirement</h4>
                            <p className="details-portfolio-heading3-detail">{filteredDatas && filteredDatas.details && filteredDatas.details.client}</p>

                            <h4  className="details-portfolio-heading3-detail" >Images</h4>
                            <div className="row">
                            <div className="col-md-6">
                            {/* <div className="image-card" /> */}
                            <StyledImageDiv imageUrl={filteredDatas && filteredDatas.details && filteredDatas.details.images} />
                            </div>
                            
                            <div className="col-md-6">
                            {/* <div className="image-card" /> */}
                            <StyledImageDiv imageUrl={filteredDatas && filteredDatas.details && filteredDatas.details.images} />
                            </div>
                            </div>

                            <h4  className="details-portfolio-heading3-detail">Challenges</h4>
                            <p className="details-portfolio-heading3-detail">{filteredDatas && filteredDatas.details && filteredDatas.details.challenge}</p>

                            <h4  className="details-portfolio-heading3-detail"> Our Solution</h4>
                            <p className="details-portfolio-heading3-detail">{filteredDatas && filteredDatas.details && filteredDatas.details.solution}</p>

                            <h4  className="details-portfolio-heading3-detail">Key Features</h4>
                            <p className="details-portfolio-heading3-detail">{filteredDatas && filteredDatas.details && filteredDatas.details.features}</p>

                            <h4  className="details-portfolio-heading3-detail">Tools & Technologies</h4>
                            <div className="padding_bottom_20px">
                            <ul>
                            <li className="">{filteredDatas && filteredDatas.details && filteredDatas.details.stack.client}</li>
                            <li className="">{filteredDatas && filteredDatas.details && filteredDatas.details.stack.server}</li>
                            <li className="">{filteredDatas && filteredDatas.details && filteredDatas.details.stack.db}</li>
                            <li className="">{filteredDatas && filteredDatas.details && filteredDatas.details.stack.services}</li>
                            <li className="">{filteredDatas && filteredDatas.details && filteredDatas.details.stack.cloud}</li>
                            <li className="">{filteredDatas && filteredDatas.details && filteredDatas.details.stack.other}</li>

                            </ul>
                            </div>
                           
                        </div>
                        
                        {/* </div> */}
                    {/* </>
                 ))}  */}

        </>

    )
}
export default PortfolioDetails;