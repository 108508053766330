import React, { Component, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./TechCarousel.css";
import Img1 from "../../assets/images/Python.png";
import Img2 from "../../assets/images/Swift.png";
import Img3 from "../../assets/images/react.svg";
import Img4 from "../../assets/images/html5.svg";
import Img5 from "../../assets/images/css3.svg";
import Img6 from "../../assets/images/javalogo.png";
import Img7 from "../../assets/images/JavaScript.png";
import Img9 from "../../assets/images/wordpress.svg";
import Img10 from "../../assets/images/Flutter.svg";
import Img11 from "../../assets/images/Laravel.svg";
import Img12 from "../../assets/images/Node.svg";
import Img13 from "../../assets/images/Magento.png";
import Img14 from "../../assets/images/Angular.png";
import Img15 from "../../assets/images/Vue.png";
import Img16 from "../../assets/images/Php.png";
import Img17 from "../../assets/images/nextjs.svg";
import Img18 from "../../assets/images/Tailwind.png";

const TechCarousel = () => {
  return (
    <div>
      <div class="container owl">
        {window.innerWidth > 570 ? (
          <OwlCarousel items={4} loop autoplay dots={false} margin={8}>
            <div className="image-box">
              <img className="TechImg" src={Img3}></img>
              <h4 className="text">React</h4>
            </div>
            <div className="image-box">
              <img className="TechImg" src={Img14}></img>
              <h4 className="text">Angular</h4>
            </div>
            <div className="image-box">
              <img className="TechImg" src={Img15}></img>
              <h4 className="text">Vue</h4>
            </div>
            <div className="image-box">
              <img className="TechImg" src={Img17}></img>
              <h4 className="text">Next.js</h4>
            </div>
            <div className="image-box">
              <img className="TechImg" src={Img4}></img>
              <h4 className="text">Html</h4>
            </div>
            <div className="image-box">
              <img className="TechImg" src={Img5}></img>
              <h4 className="text">CSS</h4>
            </div>
            <div className="image-box">
              <img className="TechImg" src={Img18}></img>
              <h4 className="text">Tailwind CSS</h4>
            </div>
            <div className="image-box">
              <img className="TechImg" src={Img7}></img>
              <h4 className="text">JavaScript</h4>
            </div>
            <div className="image-box">
              <img className="TechImg" src={Img10}></img>
              <h4 className="text">Flutter</h4>
            </div>
            <div className="image-box">
              <img className="TechImg" src={Img2}></img>
              <h4 className="text">Swift</h4>
            </div>
            <div className="image-box">
              <img className="TechImg" src={Img1}></img>
              <h4 className="text">Python</h4>
            </div>
            <div className="image-box">
              <img className="TechImg" src={Img12}></img>
              <h4 className="text">Node</h4>
            </div>
            <div className="image-box">
              <img className="TechImg" src={Img6}></img>
              <h4 className="text">Java</h4>
            </div>
            <div className="image-box">
              <img className="TechImg" src={Img16}></img>
              <h4 className="text">PHP</h4>
            </div>
            <div className="image-box">
              <img className="TechImg" src={Img11}></img>
              <h4 className="text">Laravel</h4>
            </div>
            <div className="image-box">
              <img className="TechImg" src={Img13}></img>
              <h4 className="text">Magento</h4>
            </div>
            <div className="image-box">
              <img className="TechImg" src={Img9}></img>
              <h4 className="text">Wordpress</h4>
            </div>
          </OwlCarousel>
        ) : (
          <OwlCarousel items={3} loop autoplay dots={false} margin={8}>
            <div className="image-box">
              <img className="mobile-img" src={Img3}></img>
              <h4 className="text">React</h4>
            </div>
            <div className="image-box">
              <img className="mobile-img" src={Img14}></img>
              <h4 className="text">Angular</h4>
            </div>
            <div className="image-box">
              <img className="mobile-img" src={Img15}></img>
              <h4 className="text">Vue</h4>
            </div>
            <div className="image-box">
              <img className="mobile-img" src={Img17}></img>
              <h4 className="text">Next.js</h4>
            </div>
            <div className="image-box">
              <img className="mobile-img" src={Img4}></img>
              <h4 className="text">Html</h4>
            </div>
            <div className="image-box">
              <img className="mobile-img" src={Img5}></img>
              <h4 className="text">CSS</h4>
            </div>
            <div className="image-box">
              <img className="mobile-img" src={Img18}></img>
              <h4 className="text">Tailwind CSS</h4>
            </div>
            <div className="image-box">
              <img className="mobile-img" src={Img7}></img>
              <h4 className="text">JavaScript</h4>
            </div>
            <div className="image-box">
              <img className="mobile-img" src={Img10}></img>
              <h4 className="text">Flutter</h4>
            </div>
            <div className="image-box">
              <img className="mobile-img" src={Img2}></img>
              <h4 className="text">Swift</h4>
            </div>
            <div className="image-box">
              <img className="mobile-img" src={Img12}></img>
              <h4 className="text">Node</h4>
            </div>
            <div className="image-box">
              <img className="mobile-img" src={Img1}></img>
              <h4 className="text">Python</h4>
            </div>
            <div className="image-box">
              <img className="mobile-img" src={Img6}></img>
              <h4 className="text">Java</h4>
            </div>
            <div className="image-box">
              <img className="mobile-img" src={Img16}></img>
              <h4 className="text">PHP</h4>
            </div>
            <div className="image-box">
              <img className="mobile-img" src={Img11}></img>
              <h4 className="text">Laravel</h4>
            </div>
            <div className="image-box">
              <img className="mobile-img" src={Img13}></img>
              <h4 className="text">Magento</h4>
            </div>
            <div className="image-box">
              <img className="mobile-img" src={Img9}></img>
              <h4 className="text">Wordpress</h4>
            </div>
          </OwlCarousel>
        )}
      </div>
    </div>
  );
};

export default TechCarousel;
