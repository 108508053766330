import React from 'react';
import "./HowWeWork.css";
import Methodology from "../../../assets/images/Methodology.jpg";
import ProductMindset from "../../../assets/images/ProductMindset.jpg";
import Transparency from "../../../assets/images/Transparency.jpg";
import Techexpert from "../../../assets/images/Techexpert.jpg";
import communication from "../../../assets/images/communication.jpg";
import propertyprotection from "../../../assets/images/propertyprotection.jpg";

const HowWeWork = () => {
    return (
        <>
            <div className='HowWeWorkHeader'>
                <h1>HOW WE WORK</h1>
                <div className="BarHowwework"data-aos="flip-right"></div>
            </div>
            <div className='container'>
                <div className="row"data-aos="fade-right">
                    <div className='col-lg-4 col-md-auto cartsServices'>
                        <div className="serviceBox ">
                            <div>
                                <img className="serviceIcon" src={Methodology} alt="Methodology" />
                            </div>
                            <h4>Methodology</h4>
                            <p>For a customer-centric approach, ensuring the highest quality standards and cost-efficiency</p>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-auto cartsServices '>
                        <div className="serviceBox">
                            <div>
                                <img className="serviceIcon" src={ProductMindset} alt="Product Mind"/>
                            </div>
                            <h4>Product Mindset</h4>
                            <p>To comprehend and execute the potential of the product</p>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-auto cartsServices'>
                        <div className="serviceBox ">
                            <div>
                                <img className="serviceIcon" src={Transparency} alt="Transparency"/>
                            </div>
                            <h4>Full Transparency</h4>
                            <p>Availability of full information without secret viewpoints</p>
                        </div>
                    </div>
                </div>
                <div className='ServiceSecondRow'>
                    <div className='row'data-aos="fade-right">
                        <div className='col-lg-4 col-md-auto cartsServices'>
                            <div className="serviceBox ">
                                <div>
                                    <img className="serviceIcon" src={Techexpert} alt="Tech Expert"/>
                                </div>
                                <h4>Deep Technology Expertise</h4>
                                <p>For enhanced and dynamic project ecosystem</p>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-auto cartsServices'>
                            <div className="serviceBox">
                                <div>
                                    <img className="serviceIcon" src={communication} alt="communication"/>
                                </div>
                                <h4>Effective Communication</h4>
                                <p>For positive and useful feedback</p>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-auto cartsServices'>
                            <div className="serviceBox ">
                                <div>
                                    <img className="serviceIcon" src={propertyprotection} alt="Property"/>
                                </div>
                                <h4>Intellectual property protection</h4>
                                <p>To help protect your priceless entrepreneurship</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HowWeWork