import React from "react";
import Courses from "../../../assets/json/courses.json";
import DifferentCourses from "../../../components/Dfferentcourses/different";
import "./TrainingCart.css";
import { Helmet } from "react-helmet";

const TrainingCourses = () => {
  console.log("course :", Courses);
  return (
    <div className="Divhead container">
      <h2>TRAINING AND PLACEMENT PROGRAMS</h2>
      <div className="row ">
        {Courses &&
          Courses.course &&
          Courses.course.map((course, index) => (
            <>
              <div key={index} className="col-sm-4 courses-train">
                <DifferentCourses
                  image_url={course.image_url}
                  name={course.name}
                  description={course.short_description}
                  detailurl={course.detail_url}
                  Id={course.id}
                />
              </div>
            </>
          ))}
      </div>
    </div>
  );
};
export default TrainingCourses;
