import React from "react";
import { Link } from "react-router-dom";
import "./WebDevlopment.css";
import ServiceBox from "../ServiceBox";
import Button from "../../../components/Button";
import Cart from "../../../components/Cart";
import webdevlopmentPage from "../../../assets/images/webdevlopmentPage.jpg";
import testtestandtest from "../../../assets/images/test-test-and-test.jpg";
import UiUx from "../../../assets/images/UiUx.jpg";
import { Helmet } from "react-helmet";

const WebDevlopment = () => {
  return (
    <>
      <div>
      <Helmet>
        <title>Web App Development Company in Noida | Perfectkode</title>
        <meta
          name="description"
          content="Looking for a reliable web app development company? PerfectKode excels in delivering customized web app solutions that precisely match your business requirements."
        />
        <meta name="keywords" content="web app development company" />
      </Helmet>
        <div className="WebDevlopment">
          Web Development Company
          <h5>Providing Scalable and Robust Mobility Solutions</h5>
          <div>
            <Link to="/get-a-quote">
              {" "}
              <Button ButtonText="Get A Quote" />
            </Link>
          </div>
        </div>
        <div className="WebDevCon1">
          <div className="container">
            <h1 className="WebHeader">Web App Development Company</h1>
              <center>
                  <div
                    data-aos="flip-right"
                    className="h-down-line-under1"
                  ></div>
                </center>
            <div className="contentWebDev">
              <p>
                With many years of experience in the web app development
                company, PerfectKode Software Technologies has developed some of
                the finest Web apps that have driven growth, reduced costs, and
                won more customers. With our team possessing the skills and
                expertise, we can take up any complex project and deliver it
                with ease. With numerous projects done by us, we can proudly say
                that we are one of the best Web developers around.
              </p>
              <p>
                Our clients get more than just a website. Our collaborative
                approach to web development includes careful analysis, planning
                and design; followed by efficient software development and
                testing.
              </p>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <div className="text-snap">
                  <div className="cutom-shadow No_text ">
                    <span>5</span>
                    <sup className="orange-text">+</sup>
                    <p className="black-text">Years of Experience</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="text-snap">
                  <div className="cutom-shadow No_text ">
                    <span>200</span>
                    <sup className="orange-text">+</sup>
                    <p className="black-text">Web & App Devloped</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="text-snap">
                  <div className="cutom-shadow No_text ">
                    <span>80</span>
                    <sup className="orange-text">%</sup>
                    <p className="black-text">Repeated Clients</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <h2 className="WebHeader">
              We Transform Your Web Development Idea Into Smarter Solution
            </h2>
              <center>
                  <div
                    data-aos="flip-right"
                    className="h-down-line-under4"
                  ></div>
                </center>
            <div className="contentWebDev">
              <p>
                {" "}
                Our company's core competency lies in delivering smart web-based
                solutions that completely transforms your business revenue to
                new heights. Whether you are looking for an area of expertise,
                dedicated web development platform, or a partner. We transform
                your web development idea into a smarter solution to work with.
                we offer the best offshore model that helps you launch the
                projects successfully without any hassle. Let us know about your
                idea, and we will help you make it big
              </p>
              <p>
                There’s nothing more important to us than helping your company
                scale and succeed, which is why we start by conducting market
                research and analysing your existing data. That way, we can
                tailor a web design strategy to fit you and help your company
                scale and succeed.
              </p>
            </div>
          </div>
        </div>
        <div className="container WebDevCon2top24">
          <h2 className="">Complete Web App Development Solutions</h2>
            <center>
                  <div
                    data-aos="flip-right"
                    className="h-down-line-under3"
                  ></div>
                </center>
          <div className="contentWebDev">
            <p>
              We offer a complete range of web based services to our global
              clients. Whether it’s a simple website or an eCommerce platform,
              we ensure that your online business has the rock solid foundation,
              robust architecture and highly interactive user experience which
              will help you to market your products effectively and increase
              revenues.
            </p>
            <p>
              Whether it is a cloud or on-premise system, we develop high-grade
              web apps with frictionless user experience, excellent performance
              and ironclad security.
            </p>
          </div>
        </div>
        <div>
          <Cart
            CartImg1={webdevlopmentPage}
            Heading1="Web Development"
            Paragraph1="Our web app development company has a team of experts who have strong knowledge and years of experience in Web development, we ensure to build high-performance Web-Apps that are both engaging and monetizing. With our capabilities, we not only develop your app but strategize your entire product lifecycle to leverage long-term business growth. "
            CartImg2={testtestandtest}
            Heading2="Test, Test and Test"
            Paragraph2="Testing is one way we ensure that your desired website or application works as it should. With our excellent web development services, you can rest assured that you have a great product at the end of the road."
            CartImg3={UiUx}
            Heading3="User Interface"
            Paragraph3="We develop and design creative, and innovative Web apps that have the ability to clearly announce your brand story and leave a mark on the audience. We know design is critical for a great UI. And you can rest assured that we always work to make sure your app looks incredibly beautiful. "
          />
        </div>
        <div className="container">
          <ServiceBox
            Heading="You Have a Vision And You Need Someone Who Can Bring it to Life"
            ParraFirst="Partnering with us means you get a team of developers who are as passionate about your business as you are."
            ParraSecond="Connect with us today and get started with full-stack web app development."
          />
        </div>
      </div>
    </>
  );
};
export default WebDevlopment;
