import React from "react";
import { Carousel } from 'react-bootstrap';
import "./Crousellifeat222.css";
import officefun1 from "../../../assets/images/officefun1.png"
import multicul2 from "../../../assets/images/multicul2.png"
import cafe3 from "../../../assets/images/cafe3.png"
import training4 from "../../../assets/images/training4.png"
import funparty5 from "../../../assets/images/funparty5.png"
import highspirit6 from "../../../assets/images/highspirit6.png"
import corresponsibility7 from "../../../assets/images/corresponsibility7.png"
import contest8 from "../../../assets/images/contest8.png"
import crave9 from "../../../assets/images/crave9.png"
import womenspecial10 from "../../../assets/images/womenspecial10.png"

const Crousellifeat2 = () => {

    return (
        <div className="container-carousle-life-at-second-page">
            <Carousel >

                <Carousel.Item>
                    <div className="row">
                        <div className=" col-lg-6">
                            <img
                                // className="d-block-w-100"
                                className="image-carosel-width"
                                src={officefun1}
                                alt="First slide"
                            />
                            <div className="text-heading-para-lifeat">
                                <div className="fun-heading-para-crow-fun">
                                <h3 className="fun-fun-heading-activity">Work & Fun</h3>
                                <p className="para-fun-para-lifeat-activity">PerfectKode is a unique organization in the sense that it provides its employees with a platform to learn and grow. We are passionate about our work, and we have fun too. We are as good at partying as we are at our work.</p>
                                </div>
                            </div>

                        </div>
                        <div className=" col-lg-6 ">
                            <img
                                // className="d-block-w-100"
                                className="image-carosel-width"
                                src={multicul2}
                                alt="First slide"
                            />
                            <div className="text-heading-para-lifeat">
                            <div className="fun-heading-para-crow-fun">
                                <h3 className="fun-fun-heading-activity">Multiculturism</h3>
                                <p className="para-fun-para-lifeat-activity">Perfectkode Software Solution is a multi-cultural company. People from different parts of the country work together at Perfectkode and make it a multiculturism. This gives you great opportunities to socialize with people from different backgrounds and celebrate festivals from diverse cultures.</p>
                            </div>
                            </div>
                        </div>
                    </div>


                    <Carousel.Caption>

                    </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item>
                    <div className="row">
                        <div className=" col-lg-6 ">
                            <img
                                // className="d-block-w-100"
                                className="image-carosel-width"
                                src={cafe3}
                                alt="First slide"
                            />
                            <div className="text-heading-para-lifeat">
                                <h3 className="fun-fun-heading-activity">Cafeteria</h3>
                                <p className="para-fun-para-lifeat-activity"> Our private cafeteria is the place to go for a delicious bite to eat. We serve sumptuous snacks and healthy meals – things that feed your body, mind and soul. Nothing warms up the body like a good meal.</p>
                            </div>
                        </div>

                        <div className=" col-lg-6">
                            <img
                                // className="d-block-w-100 "
                                className="image-carosel-width"
                                src={training4}
                                alt="First slide"
                            />
                            <div className="text-heading-para-lifeat">
                                <h3 className="fun-fun-heading-activity">Trainings</h3>
                                <p className="para-fun-para-lifeat-activity">Our goal is to help you achieve your full potential. With a company-wide commitment to learning, we will challenge you to grow and help you expand your skills.</p>
                            </div>
                        </div>
                    </div>



                    <Carousel.Caption>

                    </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item>
                    <div className="row">
                        <div className=" col-lg-6 ">
                            <img
                                // className="d-block-w-100-hh"
                                className="image-carosel-width"
                                src={funparty5}
                                alt="First slide"
                            />
                            <div className="text-heading-para-lifeat">
                                <h3 className="fun-fun-heading-activity">Fun & Parties</h3>
                                <p className="para-fun-para-lifeat-activity">We are a group of vibrant, enthusiastic and fun people. We love to party, we love going out and having fun, and we love celebrating each other's successes as well as achievements. We organise annual and semi-annual parties, monthly birthday parties and celebrations & surprise parties too!</p>
                            </div>
                        </div>
                        <div className=" col-lg-6 ">
                            <img
                                // className="d-block w-100"
                                className="image-carosel-width"
                                src={highspirit6}
                                alt="First slide"
                            />
                            <div className="text-heading-para-lifeat">
                                <h3 className="fun-fun-heading-activity">High Spirits</h3>
                                <p className="para-fun-para-lifeat-activity">We know how important it is to keep the spirits of your employees high and healthy. The right mix of indoor and outdoor games, coupled with occasional parties, help shift the mood in the office.</p>
                            </div>
                        </div>
                    </div>



                    <Carousel.Caption>

                    </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item>
                    <div className="row">
                        <div className=" col-lg-6 ">
                            <img
                                // className="d-block-w-100-pp"
                                className="image-carosel-width"
                                src={corresponsibility7}
                                alt="First slide"
                            />
                            <div className="text-heading-para-lifeat">
                                <h3 className="fun-fun-heading-activity">Corporate Social Responsibility</h3>
                                <p className="para-fun-para-lifeat-activity">We are committed to being responsible corporate citizens and contributing to the communities where we work. Our CSR ventures are some of the ways in which we like to give back to the community and make it a better place for all.</p>
                            </div>
                        </div>
                        <div className=" col-lg-6">
                            <img
                                // className="d-block w-100"
                                className="image-carosel-width"
                                src={contest8}
                                alt="First slide"
                            />
                            <div className="text-heading-para-lifeat">
                                <h3 className="fun-fun-heading-activity">Hackathons and contests</h3>

                                <p className="para-fun-para-lifeat-activity">We conduct hackathons and contests for various causes as well as for our employees to explore their talent. We have a very efficient team who will make sure that our Hackathon ideas are designed properly and used in the best manner possible. The prizes are far more than we expect!</p>
                            </div>
                        </div>
                    </div>


                    <Carousel.Caption>

                    </Carousel.Caption>
                </Carousel.Item>


                <Carousel.Item>
                    <div className="row">
                        <div className=" col-lg-6 ">
                            <img
                                // className="d-block w-100 "
                                className="image-carosel-width"
                                src={crave9}
                                alt="First slide"
                            />
                            <div className="text-heading-para-lifeat">
                                <h3 className="fun-fun-heading-activity">Crave For Weekends?</h3>
                                <p className="para-fun-para-lifeat-activity">Enjoy working in our state-of-the-art, technology-filled, and professional working environment. Our workstation with all the latest tools and technologies on offer is designed to promote productivity and boost your efficiency. You would never crave for weekends when you've everything you need right here!</p>
                            </div>
                        </div>
                        <div className=" col-lg-6">
                            <img
                                // className="d-block w-100"
                                className="image-carosel-width"
                                src={womenspecial10}
                                alt="First slide"
                            />
                            <div className="text-heading-para-lifeat">
                                <h3 className="fun-fun-heading-activity">Women are special!</h3>

                                <p className="para-fun-para-lifeat-activity">Women are special because they balance more things, they work harder and they spread more smiles. At Perfectkode, we like to celebrate women in everything we do. We take extra care of their safety, comfort and timings and create a world where they can connect with each other over great food.</p>
                            </div>
                        </div>
                    </div>


                    <Carousel.Caption>

                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel >
        </div>
    )
}

export default Crousellifeat2;