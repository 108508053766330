import React from "react";
import{ImQuotesLeft} from 'react-icons/im';
import './style.css';

const TestimonialCard = ({details}) => {
    console.log('DEtails ' , details);
    const { clientName , words} = details;
    return(
    <div className="container cardBody">
        <div className="card">
<div className="quotes-icon"><ImQuotesLeft /></div>
        
<div className="cardInfo">
    <h3>
    {clientName}
    </h3>
    <h4>
    {words}
    </h4>
</div>


        </div>

    </div>
)
}
export default TestimonialCard;