import React from 'react';
import "./Mvp.css";
import MVPBanner from "../../../assets/images/MVPBanner.jpg";
import ProcessMVP from "../../../assets/images/ProcessMVP.png";
import MindPicMVP from "../../../assets/images/m6.jpg";
import youwillgrow from "../../../../src/assets/images/youwillgrow.png"
import mvp from "../../../assets/images/m1.jpg"
import mvpdvlp from "../../../assets/images/m2.jpg"
import mvptime from "../../../assets/images/m3.jpg"
import mvpbest1 from "../../../assets/images/m4.jpg"
import mvp5 from "../../../assets/images/m5.jpg"
import ServiceBox from '../../services/ServiceBox';

const Mvp = () => {
    const containerStyle = {
        position: 'relative',
        perspective: '1000px',
      };
    
      const stepStyle = {
        position: 'absolute',
        transformStyle: 'preserve-3d',
    
       
        borderRadius: '5px',
        top:"-23px"
      };
    
      const numberStyle = {
   
        transformStyle: 'preserve-3d',
        display: 'inline-block',
        backgroundColor: '#333',
        color: '#fff',
        padding: '5px 18px',
        // borderRadius: '50%',
        marginRight: '5px',
        fontWeight: 'bold',
      };
    
    return (
        <div className='MVPMain'>
     <div style={{ position: 'relative' }}>
      <img  style={{
            width: '100%',
            height: '100%',
            opacity: 0.8,
            filter: 'brightness(90%)',
        
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)'
        }} src={MVPBanner} alt="MVP Banner"/>
      <p className='mvpheadimgtext'>DEVELOPING A SUCCESSFUL MVP

</p>
<div className='line'></div>
    </div>
            <div className='MVPHeader'>
                <h1>How PerfectKode Helps Startups To Develop MVP</h1>
                <div className="MVPBar"data-aos="flip-right"></div>
            </div>
            <div className="ContentMVPBG">
                <div className='container' >
                    <div className='mvbbequalgap'>
                <div className='row 'data-aos="fade-right">
                    <div className='col-lg-6 col-md-auto rowcendiv'>
              <div>
                            
                                <div className='AboutWhoWeAre'> <h1>What Is MVP?</h1></div>
                                <center><div data-aos="flip-right" className='Underline_WWhoWeAre'></div></center>
                                <div className='Who_we_are'>
                                    <p>   An MVP also known as Minimum Viable Product is the bare minimum of your product. It has the core functionalities</p>
                                    
                                <p> that your product needs to have and can be tested on your early adopters. It is designed in a way that</p>
                              <p>   the user feedback can be integrated for scaling its development, making it a market fit.</p>
                                </div>
                        
                                </div>
                    </div>
                    <div className='col-lg-6 col-md-auto'>
                        <div>
                            <img className='Who_we_are_img'    src={mvp} alt='Who we are?'></img>
                        </div>
                    </div>
                </div>
                </div>
                <div className='mvbbequalgap'>
                    <div className="row" data-aos="fade-right">
                    <div className='col-lg-6 col-md-auto'>
                        <div>
                            <img className='Who_we_are_img'    src={mvpdvlp} alt='Who we are?'></img>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-auto rowcendiv'>
              
                            
              <div className='AboutWhoWeAre'> <h1> MVP Development Methodology</h1></div>
              <center><div data-aos="flip-right" className='Underline_WWhoWeAre'></div></center>
              <div className='Who_we_are'>
                  <p>We are agile practitioners and believe that our agile approach resonates well with the wholesome idea of MVP development.</p>
                  
              <p>By adopting an agile approach, you can break down your product development into smaller, manageable increments, allowing you to quickly validate</p>
              <p>assumptions, gather feedback, and make necessary adjustments. This iterative process enables teams to respond to changing market demands and</p>
              <p>incorporate user feedback throughout the development cycle.</p>

              </div>
      
    
  </div>
                    
                      
                    </div>
                    </div>
<div className='mvbbequalgap'>
                    <div className="row" data-aos="fade-right">
                    <div className='col-lg-6 col-md-auto rowcendiv'>
              
                            
              <div className='AboutWhoWeAre'> <h1>    How Much Time Does It Take To Build An MVP?</h1></div>
              <center><div data-aos="flip-right" className='Underline_WWhoWeAre'></div></center>
              <div className='Who_we_are'>
                  <p> One of the biggest reasons why businesses go for MVO development instead of launching the full-fledged</p>
                  
              <p>product in the first go is the time it saves. Depending on the product idea you have and how complex</p>
              <p>        the core features are, an MVP can be launched anytime between 3 months and 12 months.</p>

              </div>
      
    
  </div>
  <div className='col-lg-6 col-md-auto'>
                        <div>
=
                            <img className='Who_we_are_img'    src={mvptime} alt='Who we are?'></img>
                        </div>
                    </div>
                       
                    </div>
                    </div>
                    <div className='mvbbequalgap'>
                    <div className="row" data-aos="fade-right">
                    <div className='col-lg-6 col-md-auto'>
                        <div>
                            <img className='Who_we_are_img'    src={mvpbest1} alt='Who we are?'></img>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-auto rowcendiv'>
              
                            
              <div className='AboutWhoWeAre'> <h1>Why Should I Choose You For MVP Development?</h1></div>
              <center><div data-aos="flip-right" className='Underline_WWhoWeAre'></div></center>
              <div className='Who_we_are'>
                  <p>Having worked on over 200 products in the past years, we have the experience, expertise,</p>
                  
              <p>and technology stack that you require to launch a successful MVP. We discover your product’s core</p>
              <p>offerings and build a minimum viable product. After a quick development, reviews, and interactions,</p>
              <p>we launch your prototype. And don’t worry, you can take the same team forward as you scale your product.</p>

              </div>
      
    
  </div>
                    
                      
                    </div>
                    </div>
                    <div className='mvbbequalgap'>
                    <div className="row" data-aos="fade-right">
                    <div className='col-lg-6 col-md-auto rowcendiv'>
              
                            
              <div className='AboutWhoWeAre'> <h1> Are There Any Companies That Have Succeeded With MVP?</h1></div>
              <center><div data-aos="flip-right" className='Underline_WWhoWeAre'></div></center>
              <div className='Who_we_are'>
                  <p>Companies like Facebook, Dropbox, Airbnb, Uber, and Instagram all began with Minimum Viable Products (MVPs) that allowed them to validate their concept quickly.
</p>
                  
              <p>Facebook started as a simple networking platform exclusive to Harvard students. It gained popularity and expanded to other universities before eventually opening up to the public. Similarly, Uber began as a basic black car service app in San Francisco and gradually expanded its services based on user demand.</p>
              <p>Instagram, initially a photo-sharing app with basic filters, gained popularity by offering a simple and intuitive user experience. They continuously added new features and filters based on user feedback, evolving into a global platform that revolutionised the way people share photos and connect with others.</p>


              </div>
    
  </div>
  <div className='col-lg-6 col-md-auto'>
                        <div>
=
                            <img className='Who_we_are_img'       src={mvp5} alt='Who we are?'></img>
                        </div>
                    </div>
                       
                    </div>
                    </div>
                  
          
            

                  
                </div>
                <div>
                    <img className='MindPivMVP' style={{ width: '100%', height: "100%" }} src={MindPicMVP} alt="Mind MVP"/>
               
                </div>
            </div>
            <div>
                <div className='MVPHeaderOurProcess'>
                    <h1>
                        Our Process
                    </h1>
                </div>
                <div className="Bar"></div>
                <div className="contentMVP2">
                    <p>
                    PerfectKode builds success from the ground up. We utilize agile methodologies and our extensive experience to create high-impact Minimum Viable Products (MVPs). Here's how:
                    </p>
                    <div className="row"style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className='col-lg-1'></div>
                        <div className='col-lg-3'>
    <div className='cardspadiing'style={{ margin: '15px', borderRadius: '10px',  transform: 'translateZ(0)', transition: 'transform 0.25s' }}>
        <h2 className='pointscss pointsfont'>Streamlined Process</h2>
        <p className='pointscss'>We focus on essential features first, ensuring a polished initial prototype.</p>
    </div>
    </div>
    <div className='col-lg-3'>
    <div className='cardspadiing' style={{ margin: '15px', borderRadius: '10px',  transform: 'translateZ(0)', transition: 'transform 0.25s' }}>
        <h2 className='pointscss pointsfont'>Agile & Iterative</h2>
        <p className='pointscss'>We prioritize user feedback, adapting quickly to refine your MVP.</p>
    </div>
    </div>
    <div className='col-lg-3'>
    <div className='cardspadiing' style={{ margin: '15px', borderRadius: '10px',  transform: 'translateZ(0)', transition: 'transform 0.25s' }}>
        <h2 className='pointscss pointsfont'>Resonant Solutions</h2>
        <p className='pointscss'>The result? MVPs that align with your business goals and user needs.</p>
    </div>
    </div>
    <div className='col-lg-1'></div>
</div>


                    <p>This commitment to excellence from the start lays a strong foundation for product growth and long-term success. Partner with PerfectKode to innovate and achieve market leadership.</p>
                    {/* <div className='row'>
                        <div className='col-lg-1'></div>
                        <div className='col-lg-2'>
    <div className='widthresponsive' style={{ margin: '15px', padding: '20px',position:"relative", backgroundColor: '#f0f0f0', borderRadius: '10px', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)', transform: 'translateZ(0)', transition: 'transform 0.25s' }}>
        <div className="headersteps" style={stepStyle}>
              <span className="number" style={numberStyle}>01</span>
              <span className="dotstyle" >.</span>
              <span className="number-text" >STEP</span>
            </div>
            <div>
        <h6>DISCOVERY& PROPOSAL</h6>
        <p>We repurpose information in meaningful ways for your users. We analyze your target market, prioritize core features, and find issues it aspires to solve.</p>
        </div>
        <span className="linetop" style={{ position: 'absolute', top: '-19px', left: '60%', transform: 'translateX(-50%)', width: '91%', height: '2px', backgroundColor: '#333' }}></span>
        <span className="lineright" style={{ position: 'absolute', top: '37%', right: '-15px', transform: 'translateY(-50%)', width: '2px', height: '223px', backgroundColor: '#333' }}></span>
        
    </div>
    </div>
    <div className='col-lg-2'>
    <div className='widthresponsive' style={{ margin: '15px', padding: '20px',position:"relative", backgroundColor: '#f0f0f0', borderRadius: '10px', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)', transform: 'translateZ(0)', transition: 'transform 0.25s' }}>
        <div className="headersteps" style={stepStyle}>
              <span className="number" style={numberStyle}>02</span>
              <span className="dotstyle" >.</span>
              <span className="number-text" >STEP</span>
            </div>
        <h6>AGILE DEVELOPMENT</h6>
        <p>Once you have a prototype of your product, you'll want to get feedback from users, make iterations based on that feedback and add more features accordingly.</p>
        <span className="linetop" style={{ position: 'absolute', top: '-19px', left: '60%', transform: 'translateX(-50%)', width: '91%', height: '2px', backgroundColor: '#333' }}></span>
        <span className="lineright" style={{ position: 'absolute', top: '37%', right: '-15px', transform: 'translateY(-50%)', width: '2px', height: '223px', backgroundColor: '#333' }}></span>
        
    </div>
    </div>
    <div className='col-lg-2'>
    <div className='widthresponsive' style={{ margin: '15px', padding: '20px',position:"relative", backgroundColor: '#f0f0f0', borderRadius: '10px', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)', transform: 'translateZ(0)', transition: 'transform 0.25s' }}>
        <div className="headersteps" style={stepStyle}>
              <span className="number" style={numberStyle}>03</span>
              <span className="dotstyle" >.</span>
              <span className="number-text" >STEP</span>
            </div>
        <h6>STRATEGY & PROPOSAL</h6>
        <p>First, we start with ballpark estimation and tech architecture design, then add more details and estimate timelines.</p>
        <span className="linetop" style={{ position: 'absolute', top: '-19px', left: '60%', transform: 'translateX(-50%)', width: '91%', height: '2px', backgroundColor: '#333' }}></span>
        <span className="lineright" style={{ position: 'absolute', top: '37%', right: '-15px', transform: 'translateY(-50%)', width: '2px', height: '223px', backgroundColor: '#333' }}></span>
        
    </div>
    </div>
    <div className='col-lg-2'>
    <div className='widthresponsive' style={{ margin: '15px', padding: '20px',position:"relative", backgroundColor: '#f0f0f0', borderRadius: '10px', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)', transform: 'translateZ(0)', transition: 'transform 0.25s' }}>
        <div className="headersteps" style={stepStyle}>
              <span className="number" style={numberStyle}>04</span>
              <span className="dotstyle" >.</span>
              <span className="number-text" >STEP</span>
            </div>
        <h6>END USER FEEDBACK</h6>
        <p>Get your product in front of hundreds of potential customers to test market demand, refine your offering and gain customer feedback.</p>
        <span className="linetop" style={{ position: 'absolute', top: '-19px', left: '60%', transform: 'translateX(-50%)', width: '91%', height: '2px', backgroundColor: '#333' }}></span>
        <span className="lineright" style={{ position: 'absolute', top: '37%', right: '-15px', transform: 'translateY(-50%)', width: '2px', height: '223px', backgroundColor: '#333' }}></span>
        
    </div>
    </div>
    <div className='col-lg-2'>
    <div className='widthresponsive' style={{ margin: '15px', padding: '20px',position:"relative", backgroundColor: '#f0f0f0', borderRadius: '10px', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)', transform: 'translateZ(0)', transition: 'transform 0.25s' }}>
        <div className="headersteps" style={stepStyle}>
              <span className="number" style={numberStyle}>05</span>
              <span className="dotstyle" >.</span>
              <span className="number-text" >STEP</span>
            </div>
        <h6>PROJECT KICK-OFF</h6>
        <p>Bring design and development teams together to kick start your sprint with a series of UX sprint workshops.</p>
        <span className="linetop" style={{ position: 'absolute', top: '-19px', left: '60%', transform: 'translateX(-50%)', width: '91%', height: '2px', backgroundColor: '#333' }}></span>
        <span className="lineright" style={{ position: 'absolute', top: '37%', right: '-15px', transform: 'translateY(-50%)', width: '2px', height: '223px', backgroundColor: '#333' }}></span>
        
    </div>
    </div>
    <div className='col-lg-1'></div>
    
</div> */}
<div className='row' style={{ display: 'flex',margin:"70px 0px" }}>
    <div className='col-lg-1'></div>
    <div className='col-lg-2' style={{ display: 'flex' }}>
        <div className='widthresponsive' style={{ flex: 1, padding: '20px', position: "relative", backgroundColor: '#f0f0f0', borderRadius: '10px', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)', transform: 'translateZ(0)', transition: 'transform 0.25s', display: 'flex', flexDirection: 'column' }}>
            <div className="headersteps" style={{ marginBottom: '15px' }}>
                <span className="number" style={{ fontSize: '24px', fontWeight: 'bold' }}>01</span>
                <span className="dotstyle" style={{ fontSize: '24px', fontWeight: 'bold' }}>.</span>
                <span className="number-text" style={{ fontSize: '18px', fontWeight: 'bold' }}>STEP</span>
            </div>
            <div className='paddingofflow'>
                <h6 className='headingflow'>DISCOVERY & PROPOSAL</h6>
                <p>We repurpose information in meaningful ways for your users. We analyze your target market, prioritize core features, and find issues it aspires to solve.</p>
            </div>
            <span className="linetop" style={{ position: 'absolute', top: '-19px', left: '60%', transform: 'translateX(-50%)', width: '91%', height: '2px', backgroundColor: '#333' }}></span>
        {/* <span className="lineright" style={{ position: 'absolute', top: '37%', right: '-15px', transform: 'translateY(-50%)', width: '2px', height: '223px', backgroundColor: '#333' }}></span> */}
        </div>
    </div>
    <div className='col-lg-2' style={{ display: 'flex' }}>
        <div className='widthresponsive' style={{ flex: 1, padding: '20px', position: "relative", backgroundColor: '#f0f0f0', borderRadius: '10px', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)', transform: 'translateZ(0)', transition: 'transform 0.25s', display: 'flex', flexDirection: 'column' }}>
            <div className="headersteps" style={{ marginBottom: '15px' }}>
                <span className="number" style={{ fontSize: '24px', fontWeight: 'bold' }}>02</span>
                <span className="dotstyle" style={{ fontSize: '24px', fontWeight: 'bold' }}>.</span>
                <span className="number-text" style={{ fontSize: '18px', fontWeight: 'bold' }}>STEP</span>
            </div>
            <div className='paddingofflow'>
                <h6 className='headingflow'>AGILE DEVELOPMENT</h6>
                <p>Once you have a prototype of your product, you'll want to get feedback from users, make iterations based on that feedback and add more features accordingly.</p>
            </div>
            <span className="linetop" style={{ position: 'absolute', top: '-19px', left: '60%', transform: 'translateX(-50%)', width: '91%', height: '2px', backgroundColor: '#333' }}></span>
        {/* <span className="lineright" style={{ position: 'absolute', top: '37%', right: '-15px', transform: 'translateY(-50%)', width: '2px', height: '223px', backgroundColor: '#333' }}></span> */}
        </div>
    </div>
    <div className='col-lg-2' style={{ display: 'flex' }}>
        <div className='widthresponsive' style={{ flex: 1, padding: '20px', position: "relative", backgroundColor: '#f0f0f0', borderRadius: '10px', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)', transform: 'translateZ(0)', transition: 'transform 0.25s', display: 'flex', flexDirection: 'column' }}>
            <div className="headersteps" style={{ marginBottom: '15px' }}>
                <span className="number" style={{ fontSize: '24px', fontWeight: 'bold' }}>03</span>
                <span className="dotstyle" style={{ fontSize: '24px', fontWeight: 'bold' }}>.</span>
                <span className="number-text" style={{ fontSize: '18px', fontWeight: 'bold' }}>STEP</span>
            </div>
            <div className='paddingofflow'>
                <h6 className='headingflow'>STRATEGY & PROPOSAL</h6>
                <p>First, we start with ballpark estimation and tech architecture design, then add more details and estimate timelines.</p>
            </div>
            <span className="linetop" style={{ position: 'absolute', top: '-19px', left: '60%', transform: 'translateX(-50%)', width: '91%', height: '2px', backgroundColor: '#333' }}></span>
        {/* <span className="lineright" style={{ position: 'absolute', top: '37%', right: '-15px', transform: 'translateY(-50%)', width: '2px', height: '223px', backgroundColor: '#333' }}></span> */}
        </div>
    </div>
    <div className='col-lg-2' style={{ display: 'flex' }}>
        <div className='widthresponsive' style={{ flex: 1, padding: '20px', position: "relative", backgroundColor: '#f0f0f0', borderRadius: '10px', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)', transform: 'translateZ(0)', transition: 'transform 0.25s', display: 'flex', flexDirection: 'column' }}>
            <div className="headersteps" style={{ marginBottom: '15px' }}>
                <span className="number" style={{ fontSize: '24px', fontWeight: 'bold' }}>04</span>
                <span className="dotstyle" style={{ fontSize: '24px', fontWeight: 'bold' }}>.</span>
                <span className="number-text" style={{ fontSize: '18px', fontWeight: 'bold' }}>STEP</span>
            </div>
            <div className='paddingofflow'>
                <h6 className='headingflow'>END USER FEEDBACK</h6>
                <p>Get your product in front of hundreds of potential customers to test market demand, refine your offering and gain customer feedback.</p>
            </div>
            <span className="linetop" style={{ position: 'absolute', top: '-19px', left: '60%', transform: 'translateX(-50%)', width: '91%', height: '2px', backgroundColor: '#333' }}></span>
        {/* <span className="lineright" style={{ position: 'absolute', top: '37%', right: '-15px', transform: 'translateY(-50%)', width: '2px', height: '223px', backgroundColor: '#333' }}></span> */}
        </div>
    </div>
    <div className='col-lg-2' style={{ display: 'flex' }}>
        <div className='widthresponsive' style={{ flex: 1, padding: '20px', position: "relative", backgroundColor: '#f0f0f0', borderRadius: '10px', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)', transform: 'translateZ(0)', transition: 'transform 0.25s', display: 'flex', flexDirection: 'column' }}>
            <div className="headersteps" style={{ marginBottom: '15px' }}>
                <span className="number" style={{ fontSize: '24px', fontWeight: 'bold' }}>05</span>
                <span className="dotstyle" style={{ fontSize: '24px', fontWeight: 'bold' }}>.</span>
                <span className="number-text" style={{ fontSize: '18px', fontWeight: 'bold' }}>STEP</span>
            </div>
            <div className='paddingofflow'>
                <h6 className='headingflow'>PROJECT KICK-OFF</h6>
                <p>Bring design and development teams together to kick start your sprint with a series of UX sprint workshops.</p>
            </div>
            <span className="linetop" style={{ position: 'absolute', top: '-19px', left: '60%', transform: 'translateX(-50%)', width: '91%', height: '2px', backgroundColor: '#333' }}></span>
        {/* <span className="lineright" style={{ position: 'absolute', top: '37%', right: '-15px', transform: 'translateY(-50%)', width: '2px', height: '223px', backgroundColor: '#333' }}></span> */}
        </div>
    </div>
    <div className='col-lg-1'></div>
</div>

                </div>
            
            </div>
            <div className="container">
          <ServiceBox
            Heading="Do You Have Dreams? Let's Make Them Reality."
            ParraFirst="Your Vision, Our Expertise. Let's Build it Together."
            ParraSecond="Connect with us today and get started with MVP development."
          />
        </div>
            {/* <div className='ProcessMVPImg'>

                <img className='InfographicMVP' style={{ width: "auto", display: "flex", margin: "auto" }} alt='Process MVP' src={ProcessMVP} />

            </div> */}

        </div >
    )
}

export default Mvp