import React from 'react';
import "./GetaQuote.css";
import { Link } from 'react-router-dom';

const GetaQuote = () =>{
    return(
        <div className='stickyButton'>
            <p>
                <Link className='GetaQuoteTextStyle' to='/get-a-quote'><b>Get a Quote</b></Link>
            </p>
    </div>
    )
}
export default GetaQuote;
