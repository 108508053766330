import React from 'react';
import "./ServiceBox.css";
import Button from '../../components/Button';
import { Link } from 'react-router-dom';
import ServiceBoxImg from "../../assets/images/ServiceBoxImg.jpg"

const ServiceBox = ({Heading,ParraFirst,ParraSecond}) => {
    return (
        <div className='ServiceBox'>
            <div className="container">
                <div className="row">
                    <h2 className="BlackText">{Heading} </h2>
                </div>
                <div className="row">
                    <p className="BlackText">{ParraFirst}</p>
                </div>
                <div className="row">
                    <p className="BlackText">{ParraSecond}</p>
                </div>
                <div>
                    <Link to="/get-a-quote">  <Button ButtonText="Get A Quote"/></Link> 
                </div>
            </div>
        </div>
    )
}

export default ServiceBox