import React from 'react';
import "./Enterprisebenchmark.css"
import { MdOutlineDevicesOther } from 'react-icons/md';
import { AiFillSafetyCertificate } from 'react-icons/ai';
import { BsGraphUp } from 'react-icons/bs';

const EnterPriseBenchMark = () => {
    return (
        <>
            <h2 className='HeaderBenchMark'>Enterprise application benchmarks</h2>
            <div className="container">
                <div className='row'data-aos="fade-right">
                    <div className='col-lg-4 md-auto benchmarkbox'>
                        <div className='IndustriesWeServeBox'>
                            <div className='iconsBenchMark'>
                                <MdOutlineDevicesOther />
                            </div>
                            <div>
                                <h4>Scalability</h4>
                                <p>Must function across numerous devices, networks, and teams spread over continents and time zones</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4 md-auto benchmarkbox'>
                        <div className='IndustriesWeServeBox'>
                            <div className='iconsBenchMark'>
                                <AiFillSafetyCertificate />
                            </div>
                            <div>
                                <h4>Security</h4>
                                <p>
                                    should allow users to access relevant information they need and keep private data secure.</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4 md-auto benchmarkbox'>
                        <div className='IndustriesWeServeBox'>
                            <div className='iconsBenchMark'>
                                <BsGraphUp />
                            </div>
                            <div>
                                <h4>Efficiency</h4>
                                <p>Must be able to handle large amounts of data and many concurrent users and also must be easy to build and update.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default EnterPriseBenchMark