import React, { useState } from 'react';
import "./WhyUs.css";
import WhyPerfectkodeImg from "../../assets/images/WhyPerfectkodeImg.png"
import reason from "../../assets/images/reason.jpg"
import data from "../../assets/json/about.json"

const WhyUs = () => {

    const [hidePeople, sethidePeople] = useState(true);

    const [activeItemSecond, setActiveItemSecond] = useState(true);
    const [activeItemThird, setActiveItemThird] = useState(true);
    const onPeople = () => {
        sethidePeople(true);
        sethideProcess(false);
        sethideRresponsiveness(false);
        setActiveItem(false);
        setActiveItemSecond(false);
        setActiveItemThird(false);
    }

    const [hideProcess, sethideProcess] = useState(false);
    const [activeItem, setActiveItem] = useState(true);
    const onProcess = () => {
        sethidePeople(false);
        sethideProcess(true);
        sethideRresponsiveness(false);
        setActiveItem(false);
        setActiveItemSecond(false);
        setActiveItemThird(false);
    }

    const [hideRresponsiveness, sethideRresponsiveness] = useState(false);
    const onRresponsiveness = () => {
        sethidePeople(false);
        sethideProcess(false);
        sethideRresponsiveness(true);
        setActiveItem(false);
        setActiveItemSecond(false);
        setActiveItemThird(false);
    }
    return (
        <div className='WhyUsMain'>
            <div className='container'>
                <div className=''>
                    <div className='WhyUsContainer1'>
                        <div className='Why_perfectkode'>WHY PERFECTKODE?</div>
                        <div className='row WhyUsContainer1'>
                            <div className='col-lg-6 col-md-auto'>
                                <div>
                                    <img className='WhyUsImg' src={WhyPerfectkodeImg} alt='Why Perfectkode ?'></img>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-auto'>
                                {data && data.WhyUsContent && data.WhyUsContent.map((item) => (
                                    <div className='WhyUsContent'>
                                        <p>
                                            {item.description1}
                                        </p>
                                        <p>
                                            {item.description2}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-auto'>
                            <h2 className='whyChooseUsH2'>
                                Here are some of the reasons for choosing us:
                            </h2>
                            <div className='col-lg-6 col-md-auto Onclicks'>
                                <div className='Onclick_People'>
                                    <a
                                        onClick={() => setActiveItem("PEOPLE")}
                                        value={activeItem}
                                    >
                                        <p onClick={() => onPeople()} style={{
                                            color: hidePeople === true ? "rgb(231, 112, 33)" : "",
                                            textDecoration: hidePeople === true ? "underline" : "",
                                        }}
                                            id={"PEOPLE"}>
                                            <b>PEOPLE</b>
                                        </p>
                                    </a>
                                </div>
                                <div className='Onclick_Process'>
                                    <a
                                        onClick={() => setActiveItemSecond("PROCESS")}
                                        value={activeItemSecond}
                                        style={{

                                            color: hideProcess === true ? "rgb(231, 112, 33)" : "",
                                            textDecoration: hideProcess === true ? "underline" : "",
                                        }}
                                        id={"PROCESS"}>
                                        <p onClick={() => onProcess()}>
                                            <b>PROCESS</b>
                                        </p>
                                    </a>
                                </div>
                                <div className='Onclick_Pesponsiveness'>
                                    <a
                                        onClick={() => setActiveItemThird("RESPONSIVE")}
                                        value={activeItemThird}
                                        style={{

                                            color: hideRresponsiveness === true ? "rgb(231, 112, 33)" : "",
                                            textDecoration: hideRresponsiveness === true ? "underline" : "",
                                        }}
                                        id={"RESPONSIVE"}>
                                        <p onClick={() => onRresponsiveness()}>
                                            <b>RESPONSIVENESS</b>
                                        </p>
                                    </a>
                                </div>
                            </div>

                            {hidePeople && (
                                <div>
                                    <p className='WhyUsContentReason'>
                                        We have seasoned professionals from global consulting firms and major corporations. These experts have
                                        proven experience that translates into immediate actions that meet your exact specifications
                                    </p>
                                </div>
                            )}
                            {hideProcess && (
                                <p className='WhyUsContentReason'>
                                    We have formal processes, which are the culmination of years of experience managing challenges,
                                    and assure our clients that solutions can be implemented quickly and effectively.
                                </p>
                            )}
                            {hideRresponsiveness && (
                                <p className='WhyUsContentReason'>
                                    We has organizational commitment in quickly solving problems and maintaining quality assurance practices
                                    with actively sharing knowledge and ideas across projects and accounts that keep clients satisfied.
                                </p>
                            )}
                        </div>
                        <div className='col-lg-6 col-md-auto'>
                            <div>
                                <img className='WhyUsImg' src={reason} alt="Reason"></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhyUs