import React from 'react';
import "./CareerjobDetail.css";
import { useSelector } from 'react-redux';
import JobApplyForm from '../../../components/JobApplyForm';

const CareerjobDetail = () => {
    const { JobData } = useSelector((state) => state.blog);
    const { BlogData } = useSelector((state) => state.blog);
    return (
        <div>
            <div>
                <h1>
                    {JobData.Role}
                </h1>
                <center><div data-aos="flip-right" className='Underline_Jobrole'></div></center>
            </div>
            <div className="container-job-details-job-apply-get container">
                <div className='row'>
                    <>
                        <div className='col-lg-9 col-md-auto'>
                            <h3 className=' responsibilities-heading-job-heading'>Job Description</h3>
                            <p>{JobData.JobDescription}</p>
                            <div>
                                <h3 className='responsibilities-heading-job-heading'>Responsibilities</h3>
                                <div dangerouslySetInnerHTML={{ __html: JobData.RolesandResponsibilities }} />
                                <h3 className="responsibilities-heading-job-heading">Qualifications</h3>
                                <div dangerouslySetInnerHTML={{ __html: JobData.Requirementsandqualifications }} />
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-auto'>
                            <div style={{display:"flex",flexDirection:"column",textAlign:"end",justifyContent:"center",margin:"auto"}}>
                            <h3 className='responsibilities-heading-job-heading'>Job Information</h3>
                            <h5 >Job Openings:</h5>
                            <p>{JobData.JobOpenings}</p>
                            <h5>Work Experience</h5>
                            <p>{JobData.WorkExperience}</p>
                            <h5>Salary</h5>
                            <p>{JobData.Salary}</p>
                            <h5>City</h5>
                            <p>{JobData.City}</p>
                            </div>
                        </div>
                    </>
                </div>
                <h3 className="responsibilities-heading-job-heading">Apply Now</h3>
                <div className='col-lg-8 col-md-auto'>
                    <JobApplyForm
                        Name="Full Name*"
                        Email="Email-ID*"
                        Phone="Phone*"
                        CollegeUniversityName="College/University Name*"
                        Degree="Degree*"
                        InterestedIn="Job Role"
                        PassingYear="Passing Year*"
                        Message="Mesaage"
                    />
                </div>

            </div>
        </div>
    )
}

export default CareerjobDetail