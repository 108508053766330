import React, { useState } from 'react';
import "../layout/Footer.css";
import perfectLogo from "../../assets/images/perfectLogo.png";
import { BsFacebook, BsInstagram, BsLinkedin, BsTwitter, BsYoutube } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import Facebook from '../../assets/images/facebook.png';
import Instagram from '../../assets/images/instagram.png';
import Youtube from '../../assets/images/youtube.png';
import Twitter from '../../assets/images/twitter.png';
import Linkedin from '../../assets/images/linkedin.png';
import { style } from '@mui/system';
import {FiPhoneCall} from 'react-icons/fi';
import {MdEmail} from 'react-icons/md';
import fc from "../../assets/images/fc.png"

const Footer = () => {
    const [selected, setSelected] = useState('');
    return (
        <div className="main-box">
            <div className="Container">
                <div className="row">
                    <div className="col-lg-3 col-md-auto">
                        <div className='FirstSection'>
                            <div className="Logo">
                                <img style={{ width: '220px' }} src={perfectLogo} alt='perfect logo'>
                                </img>
                                <p style={{ color: 'white' }}>UNWAVERING FOCUS ON QUALITY</p>
                            </div >
                            
                        </div>
                    </div>

                        <div className="col-lg-2 col-md-auto">
                            <h4 className="h4-text-decoration">Services</h4>

                            <div className="Footer-List">
                                <li>
                                    <Link className="hover-with-colors" to="/web-development">Web Development</Link>
                                </li>
                                <li>
                                    <Link className="hover-with-colors" to="/mobile-application">Mobile Application</Link>
                                </li>
                                <li>
                                    <Link className="hover-with-colors" to="/enterprise-application">Enterprise Application</Link>
                                </li>
                                <li>
                                    <Link className="hover-with-colors" to="/digital-marketing">Digital Marketing</Link>
                                </li>
                                <li>
                                    <Link className="hover-with-colors" to="/qa-testing">QA & Testing</Link>
                                </li>
                                <li>
                                    <Link className="hover-with-colors" to="/cloud-enablement">Cloud Enablement</Link>
                                </li>
                                <li>
                                    <Link className="hover-with-colors" to="/strategic-solution">Strategic Solutions</Link>
                                </li>
                                <li>
                                    <Link className="hover-with-colors" to="/technology-consulting">Technology Consulting</Link>
                                </li>
                            </div>

                        </div >
                        <div className="col-lg-2 col-md-auto">
                            <h4 className="h4-text-decoration">About Us</h4>
                            <div className="Footer-List">
                                <li>
                                    <Link to="/about#whoerwork" className="hover-with-colors">Who We Are</Link>
                                </li>
                                <li>
                                    <Link to="/about#our-vision-section"  className="hover-with-colors">Our Mission</Link>
                                </li>
                                <li>
                                    <Link to="/about#our-vision-section"  className="hover-with-colors">Our Vision</Link>
                                </li>
                            </div>
                            <h4 className="h4-text-decoration">Career</h4>
                            <div className="Footer-List">
                                <li>
                                    <Link to="/career" className="hover-with-colors">Career Opportunities</Link>
                                </li>
                                <li>
                                    <Link to="/life-at-perfectkode" className="hover-with-colors">Life At Perfectkode</Link>
                                </li>
                                <li>
                                    <Link to="/training" className="hover-with-colors">Training And Development</Link>
                                </li>
                            </div>
                        </div >
                        <div className="col-lg-2 col-md-auto">
                            <h4 className="h4-text-decoration">Process</h4>
                            <div className="Footer-List">
                                <li>
                                    <Link to="/how-we-work" className="hover-with-colors">How We Work</Link>
                                </li>
                                <li>
                                    <Link to="/devlopment-process" className="hover-with-colors">Development Process</Link>
                                </li>
                                <li>
                                    <Link to="/Industries-we-serve" className="hover-with-colors">Industries We Serve</Link>
                                </li>
                                <li>
                                    <Link to="/helping-startups" className="hover-with-colors">Helping Startups</Link>
                                </li>
                            </div>
                        </div >
                        <div className="col-lg-2 col-md-auto">
                            <h4 className="h4-text-decoration">Contact Us</h4>
                            <div className="Footer-List">
                                <p style={{ color: "white", margin: "auto" }}><FiPhoneCall /> Call Us</p>
                                <a className='links' href="tel:120-4941128">+91 120-4941128</a>
                                <a className='links' href="tel:9971052007">+91 99710 52007</a>
                                <p style={{ color: "white", margin: "auto" }}><MdEmail /> Email Us</p>
                                <a className='links' href="mailto:sales@perfectkode.com">Sales@perfectkode.com</a>
                            </div>
                            <div className="row">
                                <div className="social-icon">
                                    <div className="social-icon">
                                        <a href="https://www.facebook.com/perfectkode/" target="_blank"><img style={{ width: '34px' }} src={fc}></img></a>
                                    </div>
                                    <div className="social-icon">
                                        <a href="https://www.instagram.com/perfectkode/" target="_blank"><img style={{ width: '34px' }} src={Instagram}></img></a>
                                    </div>
                                    <div className="social-icon">
                                        <a href="https://www.linkedin.com/company/perfectkode/" target="_blank"><img style={{ width: '34px' }} src={Linkedin}></img></a>
                                    </div>
                                    <div className="social-icon">
                                        <a href="https://twitter.com/perfectkode" target="_blank"><img style={{ width: '34px' }} src={Twitter}></img></a>
                                    </div>
                                    <div className="social-icon">
                                        <a href="https://www.youtube.com/channel/UCVXSIkCskG-7agcTe4kYI-Q" target="_blank"><img style={{ width: '34px' }} src={Youtube}></img></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    <hr style={{ color: "white" }}></hr>


                    <div className="Copyright">
                        Copyright © PerfectKode Software Technologies LLP
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Footer;
