import { createSlice } from '@reduxjs/toolkit';

export const BlogSlice = createSlice({
	name: 'Blog',
	initialState: {
		BlogData: {
		},
		JobData:{
		},
	},
	reducers: {
		
		saveBlogData: (state, action) => {
			state.BlogData = action.payload;
		},
		saveJobData: (state, action) => {
			state.JobData = action.payload;
		},
	},
});

export const {saveBlogData,saveJobData } = BlogSlice.actions;

export default BlogSlice.reducer;
