import React, { useState  , useRef } from 'react';
import "./CoursesForm.css";
import { FaArrowRight } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from "@emailjs/browser";


const CoursesForm = () => {
    const form = useRef();
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");
    const [message, setMessage] = useState("");
    const [option, setOption] = useState("Web Development Training");

    const validateEmailAddress = (input) => {
        const regex = /[^\s@]+@[^\s@]+\.[^\s@]+/;
        return regex.test(input);
    };

    const saveValidate = () => {
        if (fname === "") {
            toast.error('First Name cannot be blank');
            return false;
        }
        if (lname === "") {
            toast.error('Last Name cannot be blank');
            return false;
        }
        if (phone.length !== 10) {
            toast.error('Phone number must be 10 digits');
            return false;
        }
        if (email === "") {
            toast.error('Email cannot be blank');
            return false;
        }
        if (!validateEmailAddress(email)) {
            toast.error('Invalid email address');
            return false;
        }
        if (company === "") {
            toast.error('College Name cannot be blank');
            return false;
        }
        if (message === "") {
            toast.error('Message cannot be blank');
            return false;
        }
        return true;
    };

    const onSubmit = (e) => {
        debugger;
        e.preventDefault();
  
        if (!saveValidate()) {
            return; 
        }
    
        emailjs
          .sendForm("contact_us", "template_0lnwnzg", form.current, {
            publicKey: "wpVb0lh0Js4LU1sCD",
          })
          .then(
            () => {
              toast.success(
                "We've received your request. Someone will contact you regarding your query soon.!"
              );
              console.log("SUCCESS!");
             
              setFname("");
              setLname("");
              setEmail("");
              setPhone("");
          
              setCompany("");
              setMessage("");
              setOption("");
          
            },
            (error) => {
              toast.error("error");
            }
          );
      
    };

    return (
        <form ref={form} onSubmit={onSubmit}>
        <div className='GetaQuoteFormAboveMain'>
            <div className='container GetaQuoteFormMain'>
                <div className='row'>
                    <div className='col-lg-6 pb-2'>
                        <span>First Name*</span>
                        <input
                            className='text-box-courses-form'
                            type='text'
                            value={fname}
                            name='First_Name'
                            onChange={(e) => setFname(e.target.value)}
                        />
                    </div>
                    <div className='col-lg-6 pb-2'>
                        <span>Last Name*</span>
                        <input
                            className='text-box-courses-form'
                            type='text'
                            value={lname}
                            name='Last_Name'
                            onChange={(e) => setLname(e.target.value)}
                        />
                    </div>
                    <div className='col-lg-6 pb-2'>
                        <span>Phone*</span>
                        <input
                            className='text-box-courses-form'
                            type='number'
                            value={phone}
                            name='Phone'
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </div>
                    <div className='col-lg-6 pb-2'>
                        <span>Email*</span>
                        <input
                            className='text-box-courses-form'
                            type='email'
                            value={email}
                            name='Email'
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className='col-lg-6 pb-2'>
                        <span>College Name*</span>
                        <input
                            className='text-box-courses-form'
                            type='text'
                            value={company}
                            name='College_Name'
                            onChange={(e) => setCompany(e.target.value)}
                        />
                    </div>
                    <div className='col-lg-6 pb-2'>
                        <span>Interested In</span>
                        <select
                            className='text-box-courses-form'
                            value={option}
                            name='Interested_In'
                            onChange={(e) => setOption(e.target.value)}
                        >
                            <option>Web Development Training</option>
                            <option>Mobile Application Training</option>
                            <option>Website Designing Training</option>
                            <option>QA & Testing Training</option>
                            <option>C/C++</option>
                        </select>
                    </div>
                </div>
                <div className='col-lg-12 pb-2'>
                    <span>Message*</span>
                    <textarea
                        className='text-box-courses-form'
                        value={message}
                        name='Message'
                        onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                </div>
                <div className='btn-align'>
                    <button className='GetaQuoteSubmitBtn'  type="submit" value="Send">
                        Submit <FaArrowRight />
                    </button>
                </div>
            </div>
            <ToastContainer />
        </div>
        </form>
    );
};

export default CoursesForm;
