import React, { useState } from 'react';
import "./Header.css";
import { useNavigate, useLocation } from "react-router-dom";
import {
    Navbar,
    NavbarBrand,
    NavbarToggler,
    Collapse,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';
import logo from "../../assets/images/logo.png";
import { Link } from 'react-router-dom';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { IoIosHome } from "react-icons/io"; //home
import { MdMiscellaneousServices } from "react-icons/md"; //services
import { SiProgress } from "react-icons/si";
import { GiStairsGoal } from "react-icons/gi"; //carrer
import { FaInfoCircle } from "react-icons/fa";
import { FaBlog } from "react-icons/fa";

const iconMappings = {
    'home': <IoIosHome />,
    'services': <MdMiscellaneousServices />,
    'process': <SiProgress />,
    'career': <GiStairsGoal />,
    'about-us': <FaInfoCircle />,
    'blog': <FaBlog />
};

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState('home');
    const [servicesSelected] = useState(['/web-development','/mobile-application','/enterprise-application','/digital-marketing','/qa-testing','/cloud-enablement','/strategic-solution','/technology-consulting']);
    const [processSelected] = useState(['/how-we-work','/helping-startups','/devlopment-process','/Industries-we-serve']);
    const [careerSelected] = useState(['/career','/life-at-perfectkode','/training']);
    const navigate = useNavigate();
    const location = useLocation();
    const {pathname} = location;

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    }

    const handleNavLinkClick = () => {
   
    
        // setSelected(path);
        if (isOpen) {
            toggleNavbar();
        }
    }
    const isActive = (route) => {
        return pathname === route ? 'selected-color' : 'unselected-color';
    }


    return (
        <div className='navbar'>
            <div className='container'>
                <Navbar expand="md" light style={{ width: '100%' }}>
                    <NavbarBrand >
                        <Link to='/'>
                            <img style={{ width: '200px' }} src={logo} alt="logo" />
                        </Link>
                    </NavbarBrand>
                    <NavbarToggler onClick={toggleNavbar} />
                    <Collapse isOpen={isOpen} navbar>
                        <Nav className="ms-auto" navbar>
                            <NavItem>
                                <NavLink >
                                    <div className='Header_list'>
                                    <Link className={isActive('/')} to='/'>
                                            <span className={isActive('/')}>
                                            {isOpen ? iconMappings['home'] : null} Home <span className='dropdownicon'></span>
                                            </span>
                                        </Link>
                                    </div>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink>
                                <div className="dropdown">
                                    {console.log('Path - ', servicesSelected, pathname)}
                                <div className={ servicesSelected.includes(pathname) ?  'dropbtn selected-color':'dropbtn'}>
                                    {isOpen ? iconMappings['services'] : null} Services <AiOutlineCaretDown />
                                </div>
                                <div className="dropdown-content">
                                    <Link to="/web-development"  className={isActive('/web-development')}   >Web Development</Link>
                                    <Link to="/mobile-application"  className={isActive('/mobile-application')} >Mobile Applications</Link>
                                    <Link to="/enterprise-application"  className={isActive('/enterprise-application')}>Enterprise Application</Link>
                                    <Link to="/digital-marketing"  className={isActive('/digital-marketing')} >Digital Marketing</Link>
                                    <Link to="/qa-testing"  className={isActive('/qa-testing')} >QA & Testing</Link>
                                    <Link to="/cloud-enablement" className={isActive('/cloud-enablement')}>Cloud Enablement</Link>
                                    <Link to="/strategic-solution" className={isActive('/strategic-solution')} >Strategic Solutions</Link>
                                    <Link to="/technology-consulting"  className={isActive('/technology-consulting')} >Technology Consulting</Link>
                                </div>
                            </div>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink>
                                    <div className="dropdown">
                                        <div className={ processSelected.includes(pathname) ?  'dropbtn selected-color':'dropbtn'}>
                                        {isOpen ? iconMappings['process']: null} Process <AiOutlineCaretDown />
                                        </div>
                                        <div className="dropdown-content">
                                            <Link to="/how-we-work" className={isActive('/how-we-work')}>How We Work</Link>
                                            <Link to="/helping-startups" className={isActive('/helping-startups')}>Helping Startups</Link>
                                            
                                            <Link to="/devlopment-process"className={isActive('/devlopment-process')}>Development Process</Link>
                                                    <Link to="/Industries-we-serve"className={isActive('/Industries-we-serve')}>Industries We Serve</Link>
                                            {/* Add more process links */}
                                        </div>
                                    </div>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink>
                                    <div className="dropdown">
                                        <div className={careerSelected.includes(pathname) ?  'dropbtn selected-color':'dropbtn'}>
                                        {isOpen ? iconMappings['career'] : null}  Career <AiOutlineCaretDown />
                                        </div>
                                        <div className="dropdown-content">
                                        <Link to="/career"className={isActive('/career')} >Career Opportunities</Link>
                                                    <Link to="/life-at-perfectkode"className={isActive('/life-at-perfectkode')} >Life At PerfectKode</Link>
                                                    <Link to="/training" className={isActive('/training')}>Training & Development</Link>
                                            {/* Add more career links */}
                                        </div>
                                    </div>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink>
                                    <div className='Header_list'>
                                    <Link className={isActive('/about')} to='/about'>
                                            <span className={isActive('/about')}>
                                            {isOpen ? iconMappings['about-us']: null}  About <span className='dropdownicon'></span>
                                            </span>
                                        </Link>
                                     
                                    </div>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink>
                                    <div className='Header_list'>
                                        <Link className={isActive('/blog')} to="/blog" >
                                        <span className={isActive('/blog')}>
                                                {isOpen ? iconMappings['blog'] : null}   Blog <span className='dropdownicon'></span>
                                                </span>
                                                </Link>
                                    </div>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        </div>
    )
}

export default Header;
