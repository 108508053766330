import React, { useEffect, useState } from "react";
import CoursesForm from "./CoursesForm";
import { Table } from "reactstrap";
import data from "../../assets/json/courses.json";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const Courses = () => {
  const params = useParams();
  const [filteredData, setFilterdData] = useState({});

  useEffect(() => {
    let courseType = params.courseType;
    let Obj = {};
    data &&
      data.course &&
      data.course.forEach((item) => {
        if (item.detail_url) {
          console.log(".......item.detail_url", item.detail_url);
          if (item.detail_url === courseType) {
            Obj = item;
          }
          setFilterdData(Obj);
        }
      });
  }, [params]);
  return (
    <>
      <div className="container">
        
              <Helmet>
                <title>{filteredData && filteredData.helmet && filteredData.helmet.title}</title>
                <meta name="description" content={filteredData && filteredData.helmet && filteredData.helmet.description} />
                <meta name="keywords" content={filteredData && filteredData.helmet && filteredData.helmet.keyword} />
              </Helmet>

        <div className="row">
          <div className="col-lg-7 col-md-auto">
            <>
              <>
                <h1>
                  {filteredData &&
                    filteredData.details &&
                    filteredData.details.heading}
                </h1>
                <div className="CoursesDiscreption">
                  <p>
                    {filteredData &&
                      filteredData.details &&
                      filteredData.details.description1}
                  </p>
                </div>
                <h4>
                  {filteredData.details &&
                    filteredData.details.objectives &&
                    filteredData.details.objectives.heading}
                </h4>
                <ul>
                  {filteredData.details &&
                    filteredData.details.objectives &&
                    filteredData.details.objectives.content &&
                    filteredData.details.objectives.content.map(
                      (row, index) => <li key={index}> {row} </li>
                    )}
                </ul>

                <h4>
                  {filteredData.details &&
                    filteredData.details.prerequisites &&
                    filteredData.details.prerequisites.heading}
                </h4>
                <ul>
                  {filteredData.details &&
                    filteredData.details.prerequisites &&
                    filteredData.details.prerequisites.content &&
                    filteredData.details.prerequisites.content.map(
                      (row, index) => <li key={index}> {row} </li>
                    )}
                </ul>

                <h4>
                  {filteredData.details &&
                    filteredData.details.goCourses &&
                    filteredData.details.goCourses.heading}
                </h4>
                <ul>
                  {filteredData.details &&
                    filteredData.details.goCourses &&
                    filteredData.details.goCourses.content &&
                    filteredData.details.goCourses.content.map((row, index) => (
                      <li key={index}> {row} </li>
                    ))}
                </ul>

                <h4>
                  {filteredData.details &&
                    filteredData.details.keyfeatures &&
                    filteredData.details.keyfeatures.heading}
                </h4>
                <ul>
                  {filteredData.details &&
                    filteredData.details.keyfeatures &&
                    filteredData.details.keyfeatures.content &&
                    filteredData.details.keyfeatures.content.map(
                      (row, index) => <li key={index}> {row} </li>
                    )}
                </ul>

                <h2>
                  {filteredData.details &&
                    filteredData.details.courseContentHeading &&
                    filteredData.details.courseContentHeading.heading}
                </h2>

                <h2>
                  {filteredData.details &&
                    filteredData.details.course &&
                    filteredData.details.course.heading}
                </h2>
                {filteredData.details &&
                  filteredData.details.course &&
                  filteredData.details.course.map((cou) => (
                    <>
                      <>
                        <h5>{cou.subheading}</h5>
                        <ul>
                          {cou &&
                            cou.content &&
                            cou.content.map((row, index) => (
                              <li key={index}> {row} </li>
                            ))}
                        </ul>
                      </>
                      <hr></hr>
                    </>
                  ))}
                <h2>
                  {filteredData.details &&
                    filteredData.details.trainer &&
                    filteredData.details.trainer.abouttrainer}
                </h2>
                <h4>
                  {filteredData.details &&
                    filteredData.details.trainer &&
                    filteredData.details.trainer.name}
                </h4>
                <h6>
                  {filteredData.details &&
                    filteredData.details.trainer &&
                    filteredData.details.trainer.designation}
                </h6>
                <h6>
                  {filteredData.details &&
                    filteredData.details.trainer &&
                    filteredData.details.trainer.portfolio}
                </h6>
                <ul>
                  {filteredData.details &&
                    filteredData.details.trainer &&
                    filteredData.details.trainer.achievement &&
                    filteredData.details.trainer.achievement.map(
                      (row, index) => <li key={index}> {row} </li>
                    )}
                </ul>

                <h4>
                  {filteredData.details &&
                    filteredData.details.newbatch &&
                    filteredData.details.newbatch.Heading}
                </h4>

                <Table>
                  <thead>
                    <tr>
                      {filteredData.details &&
                        filteredData.details.newbatch &&
                        filteredData.details.newbatch.newbatchTableheading &&
                        filteredData.details.newbatch.newbatchTableheading.map(
                          (batch, i) => <th key={i}> {batch} </th>
                        )}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.details &&
                      filteredData.details.newbatch &&
                      filteredData.details.newbatch.newbacthdata &&
                      filteredData.details.newbatch.newbacthdata.map(
                        (batch, i) => (
                          <>
                            {
                              <tr>
                                <th scope="row">{batch.Course}</th>
                                <td>{batch.Slot}</td>
                                <td>{batch.Date}</td>
                              </tr>
                            }
                          </>
                        )
                      )}
                  </tbody>
                </Table>
              </>
            </>
          </div>
          <div className="col-lg-5 col-md-auto">
            <div>
              <CoursesForm />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Courses;
