import React from 'react';
import "./AboutUsValues.css";
import { AiOutlineCaretRight } from 'react-icons/ai';
import PassionImg from "../../assets/images/Passion.png";
import RelationshipImg from "../../assets/images/Relationships.png";
import IntegrityImg from "../../assets/images/Integrity.jpg";
import data from "../../assets/json/about.json";

const AboutUsValues = () => {

    return (
        <div>
            <div className="AboutUsValue1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-auto ">
                            <div>
                                <img
                                    className="AboutUsImg"
                                    src={PassionImg}
                                    alt="Passion At Work"
                                ></img>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-auto">
                            <div className="Passion">
                                <h2> Passion</h2>
                                <div>
                                    <p>
                                        We are here because we love what we do.
                                    </p>
                                    <div className="row">
                                        {data && data.Passion && data.Passion.map((item) => (
                                            <div className="col-lg-6 col-md-auto">
                                                <h6>
                                                    <AiOutlineCaretRight className='ArrowIcon' />
                                                    {" "}
                                                    {item.subheading}
                                                </h6>
                                                <p className="Content">
                                                    {item.description}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="AboutUsValue2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-auto">
                            <div className="Relationships">
                                <h2>Relationships</h2>
                                <div>
                                    <p>
                                        We value people above everything else.
                                    </p>
                                    <div className="row">
                                        {data && data.Relationships && data.Relationships.map((item) => (
                                            <div className="col-lg-6 col-md-auto">
                                                <h6>
                                                    <AiOutlineCaretRight className='ArrowIcon' />
                                                    {" "}
                                                    {item.subheading}
                                                </h6>
                                                <p className="Content">
                                                    {item.description}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-auto">
                            <div>
                                <img
                                    className="AboutUsImg"
                                    src={RelationshipImg}
                                    alt='employees and customer relationships'
                                ></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="AboutUsValue3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-auto">
                            <div>
                                <img
                                    className="AboutUsImg"
                                    src={IntegrityImg}
                                    alt='Integrity at workplace'
                                ></img>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-auto">
                            <div className="Integrity">
                                <h2>Integrity</h2>
                                <div>
                                    <p>
                                        We trust, honor, respect, and serve
                                        others with humility.
                                    </p>
                                    <div className="row">
                                        {data && data.Integrity && data.Integrity.map((item) => (

                                            <div className="col-lg-6 col-md-auto">
                                                <h6>
                                                    <AiOutlineCaretRight className='ArrowIcon' />
                                                    {" "}
                                                    {item.subheading}
                                                </h6>
                                                <p className="Content">
                                                    {item.description}
                                                </p>
                                            </div>

                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUsValues