import React from "react";
import "./FAQ.css";
import {
  Card,
  CardBody,
  UncontrolledCollapse,
  AccordionHeader,
  AccordionItem,
  Accordion,
} from "reactstrap";

const FAQ = ({
  FAQQuestion1,
  FAQQuestion2,
  FAQQuestion3,
  FAQQuestion4,
  FAQQuestion5,
  FAQAnswer1,
  FAQAnswer2,
  FAQAnswer3,
  FAQAnswer4,
  FAQAnswer5,
}) => {
  return (
    <div>
      <div className="container FAQ">
        <div className="row">
          <div className="col-md-12 ">
            <div className="container toggle-button">
              <div>
                <div>
                  <button
                    className="dropdown-button-text border_bottom"
                    id="Accordion1"
                  >
                    <h5> {FAQQuestion1}</h5>
                  </button>
                </div>
                <UncontrolledCollapse toggler="#Accordion1">
                  <Card>
                    <CardBody>{FAQAnswer1}</CardBody>
                  </Card>
                </UncontrolledCollapse>
              </div>
              <div>
                <button
                  className="dropdown-button-text  border_bottom"
                  id="Accordion2"
                >
                  <h5> {FAQQuestion2}</h5>
                </button>
                <UncontrolledCollapse toggler="#Accordion2">
                  <Card>
                    <CardBody>{FAQAnswer2}</CardBody>
                  </Card>
                </UncontrolledCollapse>
              </div>
              <div>
                <button
                  className="dropdown-button-text  border_bottom"
                  id="Accordion3"
                >
                  <h5>{FAQQuestion3}</h5>
                </button>
                <UncontrolledCollapse toggler="#Accordion3">
                  <Card>
                    <CardBody>{FAQAnswer3}</CardBody>
                  </Card>
                </UncontrolledCollapse>
              </div>
              <div>
                <button
                  className="dropdown-button-text border_bottom"
                  id="Accordion4"
                >
                  <h5>{FAQQuestion4}</h5>
                </button>
                <UncontrolledCollapse toggler="#Accordion4">
                  <Card>
                    <CardBody>{FAQAnswer4}</CardBody>
                  </Card>
                </UncontrolledCollapse>
              </div>
              <div>
                <button
                  className="dropdown-button-text border_bottom"
                  id="Accordion5"
                >
                  <h5>{FAQQuestion5}</h5>
                </button>
                <UncontrolledCollapse toggler="#Accordion5">
                  <Card>
                    <CardBody>{FAQAnswer5}</CardBody>
                  </Card>
                </UncontrolledCollapse>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FAQ;
