import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import ProjectData from "../../assets/json/project.json";
import Button, { CustomButton } from "../../components/Button";
import "../Project/Project.css";

const Project = () => {
  const { heading, subHeading, categories, projects } = ProjectData;
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedDomain, setSelectedDomain] = useState("");
  const [projectList, setProjectList] = useState([]);
  const [domainList, setDomainList] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (selectedCategory === '') handleCategoryClick('web');
  }, [])
  const filterProjectList = (category, domain) => {
    const filteredProjects = projects.filter((project) => {
      if (domain !== "") {
        return project.category === category && project.domain === domain;
      } else {
        return project.category === category;
      }
    });
    setProjectList(filteredProjects);
    const domainObj = {};
    filteredProjects.forEach(p => {
      domainObj[p.domain] = p.domain;
    });
    setDomainList(Object.keys(domainObj));
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setSelectedDomain("");
    setSelectedProject(null);
    filterProjectList(category, "");
  };

  const handleDomainClick = (domain) => {
    setSelectedDomain(domain);
    filterProjectList(selectedCategory, domain);
  };

  const handleProjectClick = (project) => {
    setSelectedProject(project);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <>
      <div className="WebDevlopment">
        <h1>{heading}</h1>
        <h5>{subHeading}</h5>
        <div>
          <Link to="/getaquote">
            <Button ButtonText="Get A Quote" />
          </Link>
        </div>
      </div>
      <div className="container mt-4 mb-4">
        <div className="row">
          {categories.map((category, index) => (
            <div key={index} className="col-lg-3 col-md-6 col-12">
              <div
                className={`card mb-4 text-center ${
                  selectedCategory === category.key
                    ? "border-green"
                    : "border-red"
                }`}
                onClick={() => handleCategoryClick(category.key)}
              >
                <div className="card-body">
                  <h5 className="card-title">{category.name}</h5>
                  <p className="card-text">{category.description}</p>
                </div>
                <div
                  className="card-body text-center"
                  style={{ border: "0px" }}
                >
                  <img src={category.image} alt={category.name} className="imgwid" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {selectedCategory && (
        <div className="container mt-4 mb-4">
          <div className="selected-category">
            <div className="flex-wrapper">
              <div className="card" style={{ backgroundColor: "#80808040" }}>
                <div className="card-body">
                  <div className="button-wrapper">
                    <div style={{ padding: "10px" }}>
                      <CustomButton
                        ButtonText="All Domains"
                        onClick={() => handleDomainClick("")}
                        className={`custom-class ${
                          selectedDomain === "" ? "border-green" : "border-red"
                        }`}
                      />
                    </div>
                    {domainList.map((d, index) => (
                      <div style={{ padding: "10px" }} key={index}>
                        <CustomButton
                          ButtonText={d}
                          onClick={() => handleDomainClick(d)}
                          className={`custom-class ${
                            selectedDomain === d ? "border-green" : "border-red"
                          }`}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {projectList.length > 0 && (
        <div className="container mt-4 mb-4">
          <div className="row">
            {projectList.map((project, index) => (
              <div
                key={index}
                className="col-lg-3 col-md-6 col-12"
                onClick={() => handleProjectClick(project)}
              >
                <div
                  className={`card mb-4 text-center ${
                    selectedProject === project ? "border-green" : "border-red"
                  }`}
                >
                  <div className="card-body card-Header">
                    <h5 className="card-title">{project.name}</h5>
                    <p className="card-text">{project.description || ""}</p>
                  </div>
                  <div
                    className="card-body text-center pt-0"
                    style={{ border: "0px" }}
                  >
                    <img src={project.mainimage} alt={project.name} className={project.category === "mobile" ? "imgwidm" : "imgwid"} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {selectedProject && (
        <Modal
          centered
          show={showModal}
          onHide={handleCloseModal}
          size={selectedProject.category === "mobile" ? "sm" : "lg"}
        >
          <Modal.Header closeButton>
            <Modal.Title>{selectedProject.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body-scrollable">
            <Carousel activeIndex={index} onSelect={handleSelect} interval={1000}>
              {selectedProject["portfolio-image"].map((image, index) => (
                <Carousel.Item key={index}>
                  <img
                    src={image}
                    alt={`${selectedProject.name} ${index + 1}`}
                    style={
                      selectedProject.category === "mobile"
                        ? { width: "100%", height: "auto" }
                        : { width: "100%", height: "auto" }
                    }
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default Project;
