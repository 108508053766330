import React from 'react'
import GetaQuoteForm from './GetaQuoteForm'
import GetaQuoteAddress from './GetaQuoteAddress'
import "./GetaQuote.css"
const GetaQuote = () => {
    return (
        <div>
            <div className="GetaQuoteBanner">
                Do you have the next big idea? Let’s talk about it.

                <h4>Talk to us now or send us an email to let us know about your project</h4>

            </div>
            <div>
                <GetaQuoteForm />
            </div>
            <div>
                <GetaQuoteAddress />
            </div>


        </div>
    )
}

export default GetaQuote