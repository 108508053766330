import React, { useState ,useRef} from 'react';
import "./GetaQuote.css";
import { FaArrowRight, FaCode } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from "@emailjs/browser"

const GetaQuoteForm = () => {
    const form = useRef();
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");
    const [message, setMessage] = useState("");
    const [option, setOption] = useState("Web Development");

    const validateEmailAddress = (input) => {
        const regex = /[^\s@]+@[^\s@]+\.[^\s@]+/;
        return regex.test(input);
    };

    function validateName(input) {
        var regex =  /^[A-Za-z\s]+$/;
        if (regex.test(input)) {
            return 1;
        } else {
            return -1;
        }
    }

    const saveValidate = () => {
        if (fname === "") {
            toast.error('First Name Can Not Be Blank');
            return false;
        }
        if (fname !== "" && validateName(fname) === -1) {
            toast.error('First Name should only contain alphabetical letters');
            return false;
        }
        if (lname === "") {
            toast.error('Last Name Can Not Be Blank');
            return false;
        }
        if (lname !== "" && validateName(lname) === -1) {
            toast.error('Last Name should only contain alphabetical letters');
            return false;
        }
        if (phone.length !== 10) {
            toast.error('Phone number must be 10 digits long.');
            return false;
        }
        if (email === "") {
            toast.error('Email Can Not Be Blank');
            return false;
        }
        if (!validateEmailAddress(email)) {
            toast.error('Invalid email address');
            return false;
        }
        if (company === "") {
            toast.error('Company Name Can Not Be Blank');
            return false;
        }
        if (message === "") {
            toast.error('Please Enter Some Text Message');
            return false;
        }
        return true;
    };

    const handleFirstNameChange = (e) => {
        const { value } = e.target;
        // Allow only alphabets and spaces
        const updatedValue = value.replace(/[^A-Za-z\s]/gi, '');
        setFname(updatedValue);
    }

    const handleLastNameChange = (e) => {
        const { value } = e.target;
        // Allow only alphabets and spaces
        const updatedValue = value.replace(/[^A-Za-z\s]/gi, '');
        setLname(updatedValue);
    }
    const sendEmail = (e) => {
        e.preventDefault();
    
        if (!saveValidate()) {
            return;
        }
    
        emailjs
            .sendForm('contact_us', 'template_1hwe5ye', form.current, {
                publicKey: 'wpVb0lh0Js4LU1sCD'
            })
            .then(
                () => {
                    toast.success("We've received your request. Someone will contact you regarding your query soon.")
                    console.log('SUCCESS!');
    
                    // Reset form fields after successful submission
                    setFname("");
                    setLname("");
                    setPhone("");
                    setEmail("");
                    setCompany("");
                    setMessage("");
                    setOption("Web Development");
                },
                (error) => {
                    toast.error("error")
                },
            );
    };
    
    return (
        <div className='GetaQuoteFormAboveMain'>
            {/* Form fields */}
            <div className='row'>
                <div className='col-lg-6'>
                 <form ref={form} onSubmit={sendEmail}>    
         
                    {/* Form input fields */}
                    <div className='container GetaQuoteFormMain'>
                        <div className='row'>
                            <div className='col-lg-6 pb-3'>
                                <span>First Name*</span>
                                <input
                                    className='text-box'
                                    type='text'
                                    value={fname}
                                    onChange={handleFirstNameChange}
                                    name='first_name'
                                />
                            </div>
                            <div className='col-lg-6 pb-3'>
                                <span>Last Name*</span>
                                <input
                                    className='text-box'
                                    type='text'
                                    value={lname}
                                    onChange={handleLastNameChange}
                                    name='last_name'
                                />
                            </div>
                        </div>
                        {/* More form fields */}
                        <div className='row'>
                            <div className='col-lg-6 pb-3'>
                                <span>Phone*</span>
                                <input
                                    className='text-box'
                                    type='number'
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    name='number_name'
                                />
                            </div>
                            <div className='col-lg-6 pb-3'>
                                <span>Email*</span>
                                <input
                                    className='text-box'
                                    type='email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    name='email_name'
                                />
                            </div>
                        </div>
                        {/* More form fields */}
                        <div className='row'>
                            <div className='col-lg-6 pb-3'>
                                <span>Company Name*</span>
                                <input
                                    className='text-box'
                                    type='text'
                                    value={company}
                                    onChange={(e) => setCompany(e.target.value)}
                                    name='company_name'
                                />
                            </div>
                            <div className='col-lg-6 pb-3'>
                                <span>Interested In</span>
                                <select
                                    className='text-box'
                                    value={option}
                                    onChange={(e) => setOption(e.target.value)}
                                    name='option_name'
                                >
                                    <option>Web Development</option>
                                    <option>Mobile Application</option>
                                    <option>Enterprise Application</option>
                                    <option>Digital Marketing</option>
                                    <option>QA & Testing</option>
                                    <option>Cloud Enablement</option>
                                    <option>Strategic Solutions</option>
                                    <option>Technology Consulting</option>
                                </select>
                            </div>
                        </div>
                        {/* Message field */}
                        <div className='col-lg-12'>
                            <span>Message*</span>
                            <textarea
                                className='text-box'
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                name='message_name'   
                            ></textarea>
                        </div>
                        {/* Submit button */}
                        <div className='btn-align'>
                            <button className='GetaQuoteSubmitBtn' type="submit" value="Send">
                                Submit <FaArrowRight />
                            </button>
                        </div>
                    </div>
           
                </form>
                </div>
                {/* Additional content */}
                <div className='col-lg-6'>
                    <div className='container'>
                        <div className='row'>
                            <div className='PerfectKodeGetQuote'>
                                <h1>Why PerfectKode?</h1>
                            </div>
                            <div className='GetQuoteWhyPerfectKodeText'>
                                <p><FaCode /> Our real differentiator is the qualification of our people, intellectual property and professional approach to solving complex problems.</p>
                                <p><FaCode /> Our ideology is focused on the delivery of true value to our clients and employees.</p>
                                <p><FaCode /> We have immense experience ranging from building minimum-viable-products to managing existing products in mid-size and big organisations and technology startups.</p>
                                <p><FaCode /> We listen to our clients in order to understand and analyze their individual needs, and then combine the people, process and technology to address their unique requirements.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
       
    )
}

export default GetaQuoteForm;
