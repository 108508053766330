import React from 'react';
import ContactLeft from './ContactLeft';
import ContactRight from './ContactRight';
const contact = () => {
    return (
        <>
            <div className='contactDisplay'>
                    <div className='col-lg-5 col-md-auto'>
                        <ContactLeft />
                    </div>
                    <div className='col-lg-7 col-md-auto'>
                        <ContactRight />
                    </div>
                </div>
        </>
    )
}

export default contact