import React from 'react'
import Crousellifeat from './Crousellifeat'
import Crousellifeat2 from './Crousellifeat2'
import Carrbannerblue from './Carrbannerblue'
import Lifebannerlife from './Lifebannerlife'
import "../lifeatperfectkode/LifeAtPerfectKode.css"
const LifeAtPerfectKode = () => {
  return (
    <div>
         <div>
                <Lifebannerlife />
            </div>
            <div style={{margin:'4px 0px 0px 0px'}}>
              <h1  className='headlifeatpks'><span style={{color:"hsl(60deg 6.67% 38.24%)"}}>LIFE AT </span> <span style={{color:"hsl(194.64deg 97.21% 57.84%)"}}>PERFECT</span><span style={{color:"hsl(30.53deg 66.27% 50%)"}}>KODE</span></h1>
            </div>
       <div>
            <Crousellifeat/>

        </div>
        <div>
                <Carrbannerblue />
            </div>
          <div>
            <Crousellifeat2/>
        </div>   
    </div>
  )
}

export default LifeAtPerfectKode;