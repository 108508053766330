
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { saveJobData } from "../../../stores/reducers/Blog/BlogSlice";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'

const CareerjobCard = ({ key, data }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    console.log("abd", data);
    const handledetails = (datas) => {
        dispatch(saveJobData(datas));
        navigate('/career/' +datas.url_detail);
    }
    
    return (
        <>
        <div>
            <div className='jobbox' data-tooltip-id="my-tooltip" data-tooltip-content="explore more" onClick={() => handledetails(data)}>
                <div className='content-opportunities-career-data'>
                    <p className='para-description-first'>{data.Type}</p>
                    <h5>{data.Role}</h5>
                    <p>{data.City}</p>
                    <div className='btb-btnjob-full-details-description'  >
                    </div>
                </div>
            </div>
        
        </div>
            <Tooltip id="my-tooltip" />
            </>
    )
}

export default CareerjobCard