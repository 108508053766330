import React, { useEffect } from "react";
import "./Homepage.css";
import { Link } from "react-router-dom";
import mobile from "../../assets/images/mobile.png";
import AboutUS from "../../assets/images/AboutUs.jpg";
import MobApp from "../../assets/images/MobApp.jpg";
import EnterpriseApp from "../../assets/images/WeReDesign.jpg";
import QaTestingservice from "../../assets/images/TechConsulting.jpg";
import CloudServiceshome from "../../assets/images/CloudServiceshome.jpg";
import strategicservicehome from "../../assets/images/strategicservicehome.jpg";
import TechServiceHome from "../../assets/images/consultingServiceHome.jpg";
import digitalmarketingservicehome from "../../assets/images/digitalmarketingservicehome.jpg";
import Carousels from "./ClientCarousel";
import { trackPages, pageView, initGA } from "../../components/Tracking";
import { Helmet } from "react-helmet";
import CarouselNew from "./ClientCarousel";
import ServiceCard from "../../components/ServiceCard";
import { MdDesktopWindows, MdCloudDone } from "react-icons/md";
import { FaMobileAlt, FaLightbulb, FaCity, FaBullhorn } from "react-icons/fa";
import { SiTestinglibrary, SiWorldhealthorganization } from "react-icons/si";
import ClientCarousel from "./ClientCarousel";
import GetaQuote from "../layout/GetaQuote";
import TechCarousel from "./TechCarousel";
import Button from "../../components/Button";

const Home = () => {
  useEffect(() => {
    trackPages();
  }, []);
  return (
    <>
      <div className="Centre_align_banner_text">
        <Helmet>
          <title>
            PerfectKode | Software, Web, Mobile App Development Company
          </title>
          <meta
            name="description"
            content="Welcome to PerfectKode, the best software development company committed to empowering your business. Our team of experts provides transformative solutions."
          />
          <meta name="keywords" content="Best Software Development Company" />
        </Helmet>
        <div>
          <div className="section1">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/perfectkode1.appspot.com/o/Home%2FNewHomeBanner.png?alt=media&token=f771e3b7-4e56-46e4-86e1-8f2d9fa8eb83"
              className="banner1"
              alt="section1"
            />
          </div>
        </div>
        <div>
          <div class="wrapper">
            <div className="static-txt">WE ARE PERFECT</div>
            <div className="dynamic-txts">
              <ul className="margin-auto-animate">
                <li>
                  <span>APP DEVELOPERS</span>
                </li>
                <li>
                  <span>WEB DEVELOPERS</span>
                </li>
                <li>
                  <span>SOFTWARE TESTERS</span>
                </li>
                <li>
                  <span>TECH CONSULTANTS</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="section2">
          <div className="row">
            <div className="col-sm-8 ">
              <div className="BussinessHome">
                <span>
                  <h1 className="padding-top-bottom-20px">
                    WE BUILD TECHNOLOGY SOLUTIONS TO SOLVE YOUR BUSINESS NEEDS
                  </h1>
                </span>
                <div className="HomepageBussinessContent">
                  <div data-aos="flip-left">
                    <h4 className="font-family-serif padding-bottom-20px ">
                      Whether You Need a Website, Mobile App, or Software
                      Development, We Will Do Whatever It Takes To Build a
                      Successful Product That Meets Your Needs.
                    </h4>
                  </div>
                  <p>
                    We are a well-established and reputable web development
                    company in India, with our expertise and experience in the
                    global market have made it possible for us to make the
                    deliveries efficiently, perfectly and on time.
                  </p>
                  <p>
                    Being a Top web development company in India, we serve the
                    needs of our clients with the best possible solutions. We
                    are passionate about using the latest technologies to create
                    innovative user experiences. Our talented team is
                    experienced in a wide range of skill sets, and we love
                    nothing more than getting ideas off the ground and making
                    them a reality.
                  </p>
                  <p>
                    We at Perfectkode understand the importance of time and
                    budget. Hence, we have a team of expert developers and
                    designers who create the best mobile apps and websites. We
                    are best software development company and we have years of
                    experience in this field and we understand what our
                    customers want. Our services are designed to meet your
                    business needs effectively. Including our experienced team
                    in your business growth strategy will help you reach new
                    heights with our features such as high-end graphics, smooth
                    navigation, interactivity, and more.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <img className="bounce Mobileimg" src={mobile} alt="mobile" />
            </div>
          </div>
        </div>
      </div>
      <div>
        <h1>Our Services</h1>
        <center>
          <div data-aos="flip-right" className="border-big"></div>
        </center>
      </div>
      {/* <div className="section-info">
        <div className="container">
          <div className="row extraspace staffing-flex-d ">
            <div className="col-sm-6 HomeServicesHeight">
              <img className="FirstImageStaffing" src={WebDev} alt="Web Dev" />
            </div>

            <div className="col-sm-6 HomeServicesHeight">
              <h2 data-aos="zoom-out">WEB DEVELOPEMNT</h2>
              <p className="HomePageServiceContent">
                With many years of experience in the industry we are best software development company,
                PerfectKode Software Technologies has developed some of the
                finest Web apps that have driven growth, reduced costs, and won
                more customers. With our team possessing the skills and
                expertise, we can take up any complex project and deliver it
                with ease.With numerous projects done by us, we can proudly say
                that we are one of the best Web developers around
                <Link className="HomePageReadMore" to="/web-devlopment">
                  {" "}
                  <b> Read more...</b>
                </Link>
              </p>
            </div>
          </div>
          <div className="row extraspace">
            <div className="col-sm-6 HomeServicesHeight">
              <h2 data-aos="zoom-out">MOBILE APPLICATION</h2>
              <p className="HomePageServiceContent">
                We at PerfectKode understand why a company chooses to build an
                app, so we create apps that make the customer experience better
                than the customer's expectations. For many mobile users, apps
                are the primary way they interact with organizations. If they
                want to place an order, book an appointment, or just learn more
                about your products and services, it’s more convenient for them
                to do so through an app than with your website
                <Link className="HomePageReadMore" to="/mobile-application">
                  {" "}
                  <b> Read more...</b>
                </Link>{" "}
              </p>
            </div>
            <div className="col-sm-6 HomeServicesHeight">
              <img className="staffing" src={MobApp} alt="Mob App" />
            </div>
          </div>
          <div className="row extraspace staffing-flex-d">
            <div className="col-sm-6 HomeServicesHeight">
              <img
                className="staffing"
                src={EnterpriseApp}
                alt="We Re-Design"
              />
            </div>
            <div className="col-sm-6 HomeServicesHeight">
              <h2 data-aos="zoom-out">ENTERPRISE APPLICATION</h2>
              <p className="HomePageServiceContent">
                At PerfectKode Software Technologies, we help large scale
                companies to migrate their existing platforms to next-generation
                applications or build new solutions from scratch. Our enterprise
                app development services help organisations boost team
                performances and improve customer experience.
                <Link className="HomePageReadMore" to="/enterprise-application">
                  {" "}
                  <b> Read more...</b>
                </Link>
              </p>
            </div>
          </div>
          <div className="row extraspace">
            <div className="col-sm-6 HomeServicesHeight">
              <h2 data-aos="zoom-out">DIGITAL MARKETING</h2>
              <p className="HomePageServiceContent">
                We are a top digital marketing agency and we are experts in
                analyzing your current website and building a customized
                strategy to help you realize your business goals. We take pride
                in our work and never stop working until we get you the results
                you need. Our mission is to help you conquer the digital world
                and bring your dream business closer to reality.
                <Link className="HomePageReadMore" to="/digital-marketing">
                  <b> Read more...</b>
                </Link>
              </p>
            </div>
            <div className="col-sm-6 HomeServicesHeight">
              <img
                className="staffing"
                src={digitalmarketingservicehome}
                alt="Qa Testing"
              />
            </div>
          </div>
          <div className="row extraspace staffing-flex-d">
            <div className="col-sm-6 HomeServicesHeight">
              <img
                className="staffing"
                src={QaTestingservice}
                alt="Tech Consulting"
              />
            </div>
            <div className="col-sm-6 HomeServicesHeight">
              <h2 data-aos="zoom-out">QA TESTING</h2>
              <p className="HomePageServiceContent">
                We, at Perfectkode, understand the business impact of software
                flaws and hence offer you quality assurance services for
                software, mobile app, and web application products and services.
                We have an AI-driven and highly automated approach to QA testing
                that helps in significantly reduce your QA expenses.
                <Link className="HomePageReadMore" to="/qa-testing">
                  {" "}
                  <b> Read more...</b>
                </Link>
              </p>
            </div>
          </div>
          <div className="row extraspace">
            <div className="col-sm-6 HomeServicesHeight">
              <h2 data-aos="zoom-out">CLOUD ENABLEMENT</h2>
              <p className="HomePageServiceContent">
                The cloud enablement process eliminates the costs and
                inefficiencies of your existing environment while providing
                tremendous economic, operational, and administrative business
                value. Cloud enablement creates business flexibility by enabling
                new deployment models, large reductions in cost and operational
                expenditure, data consolidation across sites, faster agility and
                responsiveness to customer demand, and business continuity.
                <Link className="HomePageReadMore" to="/cloud-enablement">
                  {" "}
                  <b> Read more...</b>
                </Link>{" "}
              </p>
            </div>
            <div className="col-sm-6 HomeServicesHeight">
              <img className="staffing" src={CloudServiceshome} alt="Mob App" />
            </div>
          </div>

          <div className="row extraspace staffing-flex-d ">
            <div className="col-sm-6 HomeServicesHeight">
              <img
                className="FirstImageStaffing"
                src={strategicservicehome}
                alt="Web Dev"
              />
            </div>

            <div className="col-sm-6 HomeServicesHeight">
              <h2 data-aos="zoom-out">STRATEGIC SOLUTIONS</h2>
              <p className="HomePageServiceContent">
                We make sure your online presence caters to your business's
                needs, no matter what they may be. We understand how difficult
                it is to move through the ever-changing tech world, and we want
                to work with you every step of the way to make sure your
                business thrives.
                <Link className="HomePageReadMore" to="/strategic-solution">
                  {" "}
                  <b> Read more...</b>
                </Link>
              </p>
            </div>
          </div>
          <div className="row extraspace">
            <div className="col-sm-6 HomeServicesHeight">
              <h2 data-aos="zoom-out">TECHNOLOGY CONSULTING</h2>
              <p className="HomePageServiceContent">
                Technology consulting is the process of identifying and
                implementing the right technologies to suit a company's needs.
                As new technologies appear faster than ever before, such as
                Blockchain, artificial intelligence, and Internet of Things,
                companies face the increasingly complex task of choosing which
                ones are right for them.
                <Link className="HomePageReadMore" to="/technology-consulting">
                  {" "}
                  <b> Read more...</b>
                </Link>{" "}
              </p>
            </div>
            <div className="col-sm-6 HomeServicesHeight">
              <img className="staffing" src={TechServiceHome} alt="Mob App" />
            </div>
          </div>
        </div>
      </div> */}
      <div className="margin-40px"></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <ServiceCard
              title={"WEB DEVELOPEMNT"}
              description={
                "With many years of experience in the Web development industry, PerfectKode Software Technologies has developed some of the finest Web apps..."
              }
              num={"01"}
              link={"web-development"}
              icon={<MdDesktopWindows />}
            />
          </div>
          <div className="col-lg-3">
            <ServiceCard
              title={"MOBILE APPLICATION"}
              description={
                "We at PerfectKode understand why a company chooses to build an app, so we create apps that make the customer experience better than the customer's expectations..."
              }
              num={"02"}
              link={"/mobile-application"}
              icon={<FaMobileAlt />}
            />
          </div>
          <div className="col-lg-3">
            <ServiceCard
              title={"ENTERPRISE APPLICATION"}
              description={
                "At PerfectKode Software Technologies, we help large scale companies to migrate their existing platforms to next-generation applications...."
              }
              num={"03"}
              link={"/enterprise-application"}
              icon={<SiWorldhealthorganization />}
            />
          </div>
          <div className="col-lg-3">
            <ServiceCard
              title={"DIGITAL MARKETING"}
              description={
                "We are best digital marketing company in noida and we are experts in analyzing your current website and building a customized strategy..."
              }
              num={"04"}
              link={"/digital-marketing"}
              icon={<FaBullhorn />}
            />
          </div>
          <div className="col-lg-3">
            <ServiceCard
              title={"QUALITY ASSURANCE"}
              description={
                "We, at Perfectkode, understand the business impact of software flaws and hence offer you software quality assurance services for software..."
              }
              num={"05"}
              link={"/qa-testing"}
              icon={<SiTestinglibrary />}
            />
          </div>
          <div className="col-lg-3">
            <ServiceCard
              title={"CLOUD ENABLEMENT"}
              description={
                "Cloud enablement creates business flexibility by enabling new deployment models, large reductions in cost and operational expenditure, data consolidation..."
              }
              num={"06"}
              link={"/cloud-enablement"}
              icon={<MdCloudDone />}
            />
          </div>
          <div className="col-lg-3">
            <ServiceCard
              title={"STRATEGIC SOLUTIONS"}
              description={
                "We understand how difficult it is to move through the ever-changing tech world, and we want to work with you every step of the way to make sure your business thrives..."
              }
              num={"07"}
              link={"/strategic-solution"}
              icon={<FaLightbulb />}
            />
          </div>
          <div className="col-lg-3">
            <ServiceCard
              title={"TECHNOLOGY CONSULTING"}
              description={
                "Technology consulting is the process of identifying and implementing the right technologies to suit a company's needs..."
              }
              num={"08"}
              link={"/technology-consulting"}
              icon={<FaCity />}
            />
          </div>
        </div>
      </div>
      <div className="margin-40px">
        <center>
          <h1>Technologies we use</h1>
        </center>
        <center>
          <div data-aos="flip-right" className="Underline_CoreValues"></div>
        </center>
      </div>
      <div style={{ marginBottom: "80px" }} className="margin-bottom-20px-fix">
        <div className="container">
          <p className="Technology-Text">
            Perfectkode team of experts has the skills, experience, and
            knowledge to deliver high-quality services that are aligned with
            your business goals. Stay one step ahead of your competition and
            achieve industry success with our exceptional solutions.
          </p>
          <TechCarousel />
        </div>
      </div>
      <div className="margin-40px">
        <center>
          <h1>About Us</h1>
        </center>
        <center>
          <div data-aos="flip-right" className="border-big"></div>
        </center>
      </div>
      <div className="About-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <img
                src={AboutUS}
                className="banner-about"
                alt="section1"
              />
            </div>
            <div className="col-md-7 About-Text-Box">
              <h3 className="About-Head">
                Perfectkode: Your Comprehensive Business Solution
              </h3>
              <p className="About-Text">
                Perfectkode is the best software development company driven by a
                deep passion for crafting extraordinary digital experiences. We
                make sure your brand is always represented the way you want it
                to be, whether that means small tweaks or full-scale redesigns.
              </p>
              <p className="About-Text">
                With years of experience, our team of professional developers
                has the talent and expertise to create fully optimized mobile
                and web apps that are precisely tailored to your distinctive
                business needs. By applying our deep understanding of business,
                design, and technology, we craft beautiful mobile apps and
                web applications that enhance your customer experience and help build
                loyalty with each one of them.
              </p>
              <p className="About-Text">
                At Perfectkode, we pride ourselves on transparency and client
                satisfaction. With a clear focus on our client's needs, we work
                and create solutions that are innovative, practical, and
                result-oriented. We work with you to realize your goals and
                improve your business.
              </p>
              <div>
                <Link to="/get-a-quote">
                  {" "}
                  <Button ButtonText="Get A Quote" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="margin-40px">
        <center>
          <h1>Clients</h1>
        </center>
        <center>
          <div data-aos="flip-right" className="border-small"></div>
        </center>
      </div>
      <div className="margin-bottom-20px-fix">
        <ClientCarousel />
      </div>
    </>
  );
};

export default Home;
