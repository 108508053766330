import React from "react";
import { Carousel } from 'react-bootstrap';
import "./Crousellifeat.css";
import youwillgrow from "../../../assets/images/youwillgrow.png"
import technology from "../../../assets/images/technology.png"
import thinking from "../../../assets/images/thinking.png"
import clock from "../../../assets/images/clock.png"



const Crousellifeat = () => {

    return (
        <div className="container">
          <div className="mvbbequalgap">
                    <div className="row" data-aos="fade-right">
                        <div className=" col-sm-5">
                            <div className="image-upmove-image">
                                <img
                                    className="d-block-w-100-fistcrow-small-one "
                                    src={youwillgrow}
                                    alt="First slide"
                                />
                            </div>
                        </div>
                        <div className=" col-sm-7 rowcendiv">
                            <div className="crowsel-crowsel-text-head-para-txt-crow">
                                <h3 className="crowsel-lifeat-heading-lifeat">You will grow, a lot!</h3>
                                <p className="crowsel-lifeat-para-para-life">
                                    As a small business, we have always been happy to work with the team at Perfectkode. They have helped us with multiple projects, and each time they do an amazing job. The design, development and overall communication from everyone in the company are exceptional. The Project Manager is always there, even at strange hours of the day for our last minute requests. We will continue to work with them on all of our projects!</p>
                            </div>
                        </div>
                    </div>
                    </div>

               
                    <div className="mvbbequalgap">
                    <div className="row" data-aos="fade-right">
                    <div className=" col-sm-7 rowcendiv">
                            <div className="crowsel-crowsel-text-head-para-txt-crow">
                                <h3 className="crowsel-lifeat-heading-lifeat"> We Do Not Believe in Working With Obsolete Technologies. </h3>
                                <p className="crowsel-lifeat-para-para-life">
                                    As a part of the team at Perfectkode Software Technology, you would get a chance to work with the latest technologies in the market. The company believes that a great opportunity is nothing but an opportunity if it comes with proper grooming and support.</p>
                            </div>
                        </div>
                        <div className=" col-sm-5 ">
                            <div className="image-upmove-image">
                                <img
                                    className="d-block-w-100-fistcrow-small-one"
                                    src={technology}
                                    alt="First slide"
                                />
                            </div>
                        </div>
                     
                    </div>
                    </div>

                    <div className="mvbbequalgap">
                    <div className="row" data-aos="fade-right">
                        <div className=" col-sm-5 ">
                            <div className="image-upmove-image">
                                <img
                                    className="d-block-w-100-fistcrow-small-one"
                                    src={thinking}
                                    alt="First slide"
                                />
                            </div>
                        </div>
                        <div className=" col-sm-7 rowcendiv">
                            <div className="crowsel-crowsel-text-head-para-txt-crow">
                                <h3 className="crowsel-lifeat-heading-lifeat">What makes Perfectkode different?</h3>

                                <p className="crowsel-lifeat-para-para-life">
                                    We are not looking for robots, but curious-minded and creative people who enjoy challenges. Here at Perfectkode Software Technology, your ideas matter the most.</p>
                            </div>
                        </div>
                    </div>

                    </div>
                    <div className="mvbbequalgap">
                    <div className="row" data-aos="fade-right">
                    <div className=" col-sm-7 rowcendiv">
                            <div className="crowsel-crowsel-text-head-para-txt-crow">
                                <h3 className="crowsel-lifeat-heading-lifeat">Strict Office Timings?</h3>
                                <p className="crowsel-lifeat-para-para-life">We have a very flexible approach to working, and this includes office hours. While we need you to be present in the office long enough to get some work done, we don't track yours in and out time. We are focused on the quality of your work, and not just on how many hours you spent in front of a screen.</p>
                            </div>
                        </div>
                        <div className=" col-sm-5 ">
                            <div className="image-upmove-image">
                                <img
                                    className="d-block-w-100-fistcrow-small-one"
                                    src={clock}
                                    alt="First slide"
                                />
                            </div>
                        </div>
                     
                    </div>





         
        </div>
        </div>
    )
}

export default Crousellifeat;