import React from "react";
import "./Cart.css";

const Cart = (
    {
        CartImg1,
        Heading1,
        Paragraph1,
        CartImg2,
        Heading2,
        Paragraph2,
        CartImg3,
        Heading3,
        Paragraph3
    }
) => {
    return (
        <div className="Cart container">

            <div className="row" data-aos="fade-right">

                <div className="col-lg-4 col-md-auto CardParent">
                    <div className="Card">


                        <img className="careerImg" src={CartImg1}  alt="card image"/>
                        <h2 className="card-heading-color">{Heading1}</h2>
                        <span className="card-para-career">{Paragraph1}</span>


                    </div>
                </div>

                <div className="col-lg-4 col-md-auto CardParent">
                    <div className="Card">


                        <img className="careerImg" src={CartImg2} alt="card image"/>
                        <h2 className="card-heading-color">{Heading2}</h2>
                        <span className="card-para-career">{Paragraph2}</span>

                    </div>
                </div>

                <div className="col-lg-4 col-md-auto CardParent">
                    <div className="Card">


                        <img className="careerImg" src={CartImg3} alt="card image"/>
                        <h2 className="card-heading-color">{Heading3}</h2>
                        <span className="card-para-career">{Paragraph3}</span>

                    </div>
                </div>

            </div>
        </div>





    )

}

export default Cart;