import React, { useState, useRef } from "react";
import "./Contact.css";
import { FaArrowRight } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "@emailjs/browser";

const ContactLeft = () => {
  const form = useRef();

  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [option, setOption] = useState("Web Devlopment");

  function validateEmailAddress(input) {
    var regex = /[^\s@]+@[^\s@]+\.[^\s@]+/;
    if (regex.test(input)) {
      return 1;
    } else {
      return -1;
    }
  }

  function validateName(input) {
    var regex = /^[A-Za-z\s]+$/;
    if (regex.test(input)) {
      return 1;
    } else {
      return -1;
    }
  }

  const saveValidate = () => {
    if (fname === "") {
      toast.error("First Name Can Not Be Blank");
      return false;
    }
    if (fname !== "" && validateName(fname) === -1) {
      toast.error("First Name should only contain alphabetical letters");
      return false;
    }
    if (lname === "") {
      toast.error("Last Name Can Not Be Blank");
      return false;
    }
    if (lname !== "" && validateName(lname) === -1) {
      toast.error("Last Name should only contain alphabetical letters");
      return false;
    }
    if (phone.length !== 10) {
      toast.error("Phone number must be 10 digits long.");
      return false;
    }

    if (email === "") {
      toast.error("Email Can Not Be Blank");
      return false;
    }
    if (email !== "" && validateEmailAddress(email) === -1) {
      toast.error("Use a valid emailaddress");
      return false;
    }
    if (company === "") {
      toast.error("Company Name Can Not Be Blank");
      return false;
    }
    if (message === "") {
      toast.error("Please Enter Some Text Message");
      return false;
    }
    return true;
  };
  const sendEmail = (e) => {
    debugger;
    e.preventDefault();

    if (!saveValidate()) {
      return;
    }

    emailjs
      .sendForm("contact_us", "template_1hwe5ye", form.current, {
        publicKey: "wpVb0lh0Js4LU1sCD",
      })
      .then(
        () => {
          toast.success(
            "We've received your request. Someone will contact you regarding your query soon.!"
          );
          console.log("SUCCESS!");
          setFname("");
          setLname("");
          setPhone("");
          setEmail("");
          setCompany("");
          setMessage("");
          setOption("Web Development");
        },
        (error) => {
          toast.error("error");
        }
      );
  };

  return (
    <form ref={form} onSubmit={sendEmail}>
      <div className="Form">
        <div className="padding-div">
          <div className="head-contact">
            <h5>Fill out the form below and we'll be in touch soon.</h5>
          </div>
          <div className="gap-top pb-2">
            <label htmlFor="fname">First Name*</label>
            <input
              className="text-box-contact-form"
              type="text"
              id="fname"
              value={fname}
              onChange={(e) => setFname(e.target.value)}
              name="first_name"
            />
          </div>
          <div className="gap-top pb-2">
            <label htmlFor="lname">Last Name*</label>
            <input
              className="text-box-contact-form"
              type="text"
              id="lname"
              value={lname}
              onChange={(e) => setLname(e.target.value)}
              name="last_name"
            />
          </div>
          <div className="gap-top pb-2">
            <label htmlFor="Number">Phone*</label>
            <input
              className="text-box-contact-form"
              type="number"
              id="Number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              name="number_name"
            />
          </div>
          <div className="gap-top pb-2">
            <label htmlFor="mail"> Email*</label>
            <input
              className="text-box-contact-form"
              type="email"
              id="mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              name="email_name"
            />
          </div>
          <div className="gap-top pb-2">
            <label htmlFor="company"> Company Name*</label>
            <input
              className="text-box-contact-form"
              type="text"
              id="company"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              name="company_name"
            />
          </div>
          <div className="gap-top pb-2">
            <label htmlFor="interested">Interested In </label>
            <select
              className="text-box-contact-form"
              type="text"
              id="interested"
              value={option}
              name="option_name"
              onChange={(e) => setOption(e.target.value)}
            >
              <option>Web Devlopment</option>
              <option>Mobile Application</option>
              <option>Digital Marketing</option>
              <option>QA & Testing</option>
              <option>Technology Consulting</option>
            </select>
          </div>
          <div className="gap-top pb-2">
            <label htmlFor="message"> Message*</label>
            <textarea
              className="text-box-contact-form"
              id="message"
              value={message}
              name="message_name"
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
          <div>
            <button className="SubmitBtn" type="submit" value="Send">
              Submit <FaArrowRight />
            </button>
          </div>
        </div>
        <ToastContainer />
      </div>
    </form>
  );
};

export default ContactLeft;
