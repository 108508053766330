import React from "react";
import { Link } from "react-router-dom";
import ServiceBox from "../ServiceBox";
import Button from "../../../components/Button";
import Cart from "../../../components/Cart";
import AppDevelopment from "../../../assets/images/AppDevelopment.jpg";
import MobileTesting from "../../../assets/images/MobileTesting.jpg";
import userInterface from "../../../assets/images/userInterface.jpg";
import { Helmet } from "react-helmet";

const MobileApplication = () => {
  return (
    <>
      <div>
        <Helmet>
          <title>
            Top Mobile App Development Company in Noida | Perfectkode
          </title>
          <meta
            name="description"
            content="Welcome to the dynamic world of a mobile app development company in Noida, where we are dedicated to creating exceptional mobile experiences that attract users."
          />
          <meta name="keywords" content="mobile app development company in noida" />
        </Helmet>
        <div className="WebDevlopment">
          Mobile App Development Company
          <h5>Providing Scalable and Robust Mobility Solutions</h5>
          <div>
            <Link to="/get-a-quote">
              {" "}
              <Button ButtonText="Get A Quote" />
            </Link>
          </div>
        </div>
        <div className="WebDevCon1">
          <div className="container">
            <h1 className="WebHeader">App Development Company</h1>
              <center>
                  <div
                    data-aos="flip-right"
                    className="h-down-line-under1"
                  ></div>
                </center>
            <div className="contentWebDev">
              <p>
                Perfectkode is a top mobile app development company in Noida and
                We understand why a company chooses to build an app, so we
                create apps that make the customer experience better than the
                customer's expectations. For many mobile users, apps are the
                primary way they interact with organizations. If they want to
                place an order, book an appointment, or just learn more about
                your products and services, it’s more convenient for them to do
                so through an app than with your website.{" "}
              </p>
              <p>
                We provide the technology to your customers they love most and
                take your business to new heights.
              </p>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-3">
                <div className="text-snap">
                  <div className="cutom-shadow No_text ">
                    <span>5</span>
                    <sup className="orange-text">+</sup>
                    <p className="black-text">Years of Experience</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-3">
                <div className="text-snap">
                  <div className="cutom-shadow No_text ">
                    <span>200</span>
                    <sup className="orange-text">+</sup>
                    <p className="black-text">App Devloped</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-3">
                <div className="text-snap">
                  <div className="cutom-shadow No_text ">
                    <span>80</span>
                    <sup className="orange-text">%</sup>
                    <p className="black-text">Repeated Clients</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <h2 className="WebHeader">
              Do You Have Any Ideas For Mobile Apps?
            </h2>
              <center>
                  <div
                    data-aos="flip-right"
                    className="h-down-line-under1"
                  ></div>
                </center>
            <div className="contentWebDev">
              <p>
                We pride ourselves on our innovative mobile app development
                process and delivery methods, which help to keep costs down and
                deliverables high. We’re experts in what we do, so if you have a
                business challenge you want to address through apps and
                software, why not get in touch for a chat?
              </p>
              <p>
                We take great care to make sure our apps are benefiting your
                business and your customers. That is why before we start working
                on your project, we do research, analyze, and review your
                existing data. It helps us to deeply understand what
                opportunities exist and what it takes to utilize them.
              </p>
            </div>
          </div>
        </div>
        <div className="container WebDevCon2">
          <h2 className="">Complete App Development Solutions</h2>
            <center>
                  <div
                    data-aos="flip-right"
                    className="h-down-line-under1"
                  ></div>
                </center>
          <div className="contentWebDev">
            <p>
              {" "}
              Our primary passion is turning your ideas into game-changing
              products across global markets. With a supreme blend of creativity
              and technical expertise, our developers deliver you best-in-class
              cross platform mobile app development company in Noida within the budget and
              timeline. You can come to us at any stage of your project - from
              just an idea to architecture, from development to quality
              assurance and support.
            </p>
            <p>
              We build intuitive, seamless mobile apps. Our designs are based on
              user-friendly interfaces and high-grade performance.
            </p>
          </div>
        </div>
        <div>
          <Cart
            CartImg1={AppDevelopment}
            Heading1="App Development"
            Paragraph1="Our expert web developers strategize and build high-performance apps that are both engaging and monetizing. We help define not only your app but your entire product lifecycle to leverage long-term business growth. "
            CartImg2={MobileTesting}
            Heading2="Test, Test and Test"
            Paragraph2="Testing is an integral part of our App development process to ensure that the app works as desired. We will continuously and proactively test the app, fix bugs, and resolve any anomalies."
            CartImg3={userInterface}
            Heading3="User Interface"
            Paragraph3="We understand that engaging your audience is essential and we help you to create an effective, and user-friendly app that best reflects your brand."
          />
        </div>
        <div className="container">
          <div className="container WebDevCon2paddingnone">
            <h2 className="">What To Choose: Android, iOS or Both?</h2>
              <center>
                  <div
                    data-aos="flip-right"
                    className="h-down-line-under1"
                  ></div>
                </center>
            <div className="contentWebDev">
              <p>
                An app can be a better way to reach customers. With over 1.7
                million apps in the App Store and 1.6 million apps available on
                Google Play, it’s easy to become overwhelmed. When it comes to
                app development, Android & IOS are two of the leading platforms
                for building your app. But depending on your business needs, one
                may be better suited for you than the other.
              </p>
              <p>
                Looking for mobile app development company in Noida, We are the experts. But one of the
                key questions is whether to build for iOS or Android? Our
                experts consider a number of factors when making this decision
                including the target audience, timeline, budget, and ongoing
                maintenance or investment expectations.
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <ServiceBox
            Heading="You Don't Need Just a Developer, You Need a Partner"
            ParraFirst="We can help you convert your belief into reality, by building a state-of-the-art mobile application."
            ParraSecond="Connect with us today and get started with full-stack mobile app development."
          />
        </div>
      </div>
    </>
  );
};
export default MobileApplication;
