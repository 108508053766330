import React from "react";
import { Link } from "react-router-dom";
import ServiceBox from "../ServiceBox";
import Button from "../../../components/Button";
import Cart from "../../../components/Cart";
import { MdAssessment } from "react-icons/md";
import { MdShareLocation } from "react-icons/md";
import { GrGrow } from "react-icons/gr";
import FAQ from "../../../components/FAQ";
import BannerImg from "../../../assets/images/BannerImg.jpg";
import supportandpartner from "../../../assets/images/supportandpartner.jpg";
import research from "../../../assets/images/research.jpg";
import growing from "../../../assets/images/growing.jpg";
import { Helmet } from "react-helmet";
import "../technologyConsulting/Technologyconsulting.css"

const TechnologyConsulting = () => {
  return (
    <div>
      <Helmet>
        <title>
          Information and Technology Management | Perfect IT Solutions
        </title>
        <meta
          name="description"
          content="Our team of experts with deep knowledge of Information and Technology Management can help you identify and implement the optimal technologies for your business needs"
        />
        <meta name="keywords" content="information and technology management" />
      </Helmet>
      <div>
        <div className="WebDevlopment">
          Technology Consulting
          <h5>
            Our Technology Strategy & Advisory practice helps companies
            jumpstart their digital transformations.{" "}
          </h5>
          <div>
            <Link to="/get-a-quote">
              {" "}
              <Button ButtonText="Get A Quote" />
            </Link>
          </div>
        </div>
        <div className="WebDevCon1">
          <div className="container">
            <h1>Best Technology Consulting Services Provider</h1>
              <center>
                  <div
                    data-aos="flip-right"
                    className="h-down-line-under4"
                  ></div>
                </center>
            <div className="contentWebRedesign">
              <p>
                {" "}
                Technology consulting is the process of identifying and
                implementing the right technologies to suit a company's needs.
                As new technologies appear faster than ever before, such as
                Blockchain, artificial intelligence, and Internet of Things,
                companies face the increasingly complex task of choosing which
                ones are right for them. Technology consultants can simplify
                this process, offering expert guidance on where and how
                different technologies could be applied to generate maximum
                business value.{" "}
              </p>
            </div>
          </div>
          <div className="container">
            <h2 className="WebHeader">
              What’s Special About Our Technology Consulting Services?
            </h2>
              <center>
                  <div
                    data-aos="flip-right"
                    className="h-down-line-under4"
                  ></div>
                </center>
            <div className="contentWebRedesign">
              <p>
                Our information and technology management services are geared
                towards meeting the needs of your business. We offer top-quality
                technology consulting that enhance and drive your progress in a
                way that is necessary to succeed, while also creating new
                opportunities.
              </p>

              <p>
                {" "}
                With our best technology consulting services, you get a partner
                who turns their first-hand knowledge of developments in your
                industry into the practical, individually tailored advice your
                company needs to make informed and effective choices about new
                technologies available to you. So bring us your plans for growth
                and business model innovations, and we'll bring ours.
              </p>
            </div>
          </div>
          <div className="CartMid"></div>
          <div class="RedesignBannerContainer">
  <img class="RedesignBannerImg" src={BannerImg} alt="banner image"/>
  <div class="" style={{maxWidth:"50vw"}}>
    <p class="typewriter-text">
      Level up your IT with PerfectKode We work with you to improve
      your software setup and create a digital plan that's focused on
      technology.
    </p>
  </div>
</div>

          {/* <div>
            <img
              className="RedesignBannerImg"
              src={BannerImg}
              alt="banner image"
            />
            <p className="typewriter-text">
              Level up your IT with PerfectKode. We work with you to improve
              your software setup and create a digital plan that's focused on
              technology.
            </p>
          </div> */}
        </div>
        <div className="container WebDevCon2">
          <h2 className="WebHeader">
            How Technology Consultants Impact Business Growth?
          </h2>
            <center>
                  <div
                    data-aos="flip-right"
                    className="h-down-line-under4"
                  ></div>
                </center>
          <div className="contentWebDev"></div>
        </div>
        <div>
          <Cart
            CartImg1={supportandpartner}
            Heading1="
                        SUPPORT VS. PARTNER"
            Paragraph1="Having someone who helps you in monitoring the technology and upgrading it before critical issues become apparent. It's always better to be proactive than reactive, and with the right technology consultant, you will save not only time but also money. The right technology consultant should be a partner in your business- someone who comes to fix your technology when it breaks but is also proactively monitoring and upgrading your systems before that becomes an issue. "
            CartImg2={research}
            Heading2="
                        SELF RESEARCH VS. PROFESSIONAL RESEARCH"
            Paragraph2="The process of adapting to the latest technological trends is time-consuming, making it nearly impossible for your IT department to handle all the changes on their own. Here at Perfectkode we make sure you stay ahead of technological advances and implement them into your business. With a customized technology plan that fits our clients’ unique needs, we make working with technology easy.
                        "
            CartImg3={growing}
            Heading3="
                        RUNNING YOUR COMPANY VS. GROWING YOUR COMPANY"
            Paragraph3="While many vendors view your enterprise as a break-fix environment, technology consultants are different. We focus on providing true vendor partnership by finding smart solutions for your business challenges, conducting in-depth research to understand the best direction for your IT goals and generating innovative ideas that can propel your business growth into the future."
          />
        </div>
        <div className="container WebDevCon2">
          <h2 className="WebHeaderInfra">What You Get</h2>
            <center>
                  <div
                    data-aos="flip-right"
                    className="h-down-line-under5"
                  ></div>
                </center>
          <p>
            {" "}
            In the world of innovation, you need to continuously create and
            deliver new products to stay ahead of your competition. We are
            committed to helping you succeed. Our Deliverables include:{" "}
          </p>
          <div className="contentWebDev"></div>
        </div>
        <div>
          <div className="container">
            <div className="row">
              <div className="col-lg-4 md-auto benchmarkbox">
                <div className="IndustriesWeServeBox">
                  <div className="iconsBenchMark">
                    <MdShareLocation style={{color:"brown"}}/>
                  </div>
                  <p>
                    We offer expert consulting from one or more experts.
                    Sessions can take place in virtually or any geographic
                    location.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 md-auto benchmarkbox">
                <div className="IndustriesWeServeBox">
                  <div className="iconsBenchMark">
                    <MdAssessment style={{color:"green"}}/>
                  </div>
                  <p>
                    Technology gaps and areas of opportunity have been
                    identified within your organization.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 md-auto benchmarkbox">
                <div className="IndustriesWeServeBox">
                  <div className="iconsBenchMark">
                    <GrGrow style={{color:"red"}}/>
                  </div>
                  <p>
                    Strategy, market and competitive analysis for applying new
                    technologies to achieve your company’s desired business
                    outcomes
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="container WebDevCon2">
            <h2>FAQs</h2>
              <center>
                  <div
                    data-aos="flip-right"
                    className="h-down-line-under5"
                  ></div>
                </center>
            <div className="contentWebDevFQA">
              <FAQ
                FAQQuestion1="What is technology consulting?"
                FAQAnswer1="Also known as Information Technology (IT) consultancy. Technology consultants work with clients to solve business problems using technology. They help clients understand how to use technology effectively and efficiently to solve pressing business challenges. Clients go to technology consultants when they need to solve business issues involving the deployment, implementation, and adoption of new and emerging technologies in order to improve productivity and revenue performance."
                FAQQuestion2="Management consulting vs. technology consulting?"
                FAQAnswer2="Management consultants help organizations discover new opportunities and solve their most complex challenges, whether in marketing or operations. Technology consultants take a strategic look at how technology can help improve an organization which includes orchestrating implementation of new systems while ensuring they integrate seamlessly with existing ones. Both management consulting and technology consulting help companies identify strategies to capture a greater market opportunity, improve agility, and gain a competitive advantage."
                FAQQuestion3="Why technology consulting?"
                FAQAnswer3="Technology is a crucial component of a business's success. With its fast growth and development in almost every industry, it is now more important than ever for organizations to understand how to best power technology. Technology consulting helps organizations expand and grow, mitigate risk and enhance important processes."
                FAQQuestion4="How is IT consulting help running development projects forward??"
                FAQAnswer4="The insights of technology consulting services may be of real value for those who struggle with product development. Instead of leaning over backwards to discover whether your idea is good, which features should your app contain to succeed on the market, or how to launch a product minimizing costs and bringing profit to your business, find reliable tech consulting services. They will boost your projects development. "
                FAQQuestion5="When should a company use technology consulting services?"
                FAQAnswer5="Each organization is different, with distinct needs and requirements. Technology consulting is a broad and growing market because technology impacts the success of every business. Technology consultants can help businesses achieve their goals by offering solutions based on their existing technology, suggesting new tools to enable growth, and setting up infrastructure to support future technology solutions. "
              />
            </div>
          </div>
        </div>
        <div className="container">
          <ServiceBox
            Heading="Get A Quote Today About Your Consultancy Needs."
            ParraFirst="We’ll understand your business, help you to define the scope of the project, and be there for the long term."
          />
        </div>
      </div>
    </div>
  );
};
export default TechnologyConsulting;
