import React from 'react'
import "./IndustriesWeServe.css"
import { HiSpeakerphone } from 'react-icons/hi';
import { GiMusicalNotes } from 'react-icons/gi';
import { AiFillCar } from 'react-icons/ai';
import { MdMiscellaneousServices } from 'react-icons/md';
import { BsFillCartCheckFill } from 'react-icons/bs';
import { ImBook } from 'react-icons/im';
import { ImPower } from 'react-icons/im';
import { GiReceiveMoney } from 'react-icons/gi';
import { BiGame } from 'react-icons/bi';
import { RiGovernmentLine } from 'react-icons/ri';
import { RiHotelLine } from 'react-icons/ri';
import { MdComputer } from 'react-icons/md';
import { GoLaw } from 'react-icons/go';
import { MdPrecisionManufacturing } from 'react-icons/md';
import { HiNewspaper } from 'react-icons/hi';
import { MdHealthAndSafety } from 'react-icons/md';
import { RiHandHeartLine } from 'react-icons/ri';
import { GoRadioTower } from 'react-icons/go';
import { MdEmojiTransportation } from 'react-icons/md';
import { FaTools } from 'react-icons/fa';
import { BsThreeDots } from 'react-icons/bs';

const IndustriesWeServe = () => {
    return (
        <>
            <div className='container'>
                <div className='IndustriesWeServeHeading'>
                    <h1>INDUSTRIES WE SERVED</h1>
                    <div className="Barindustriesweserve"data-aos="flip-right"></div>
                    <p className='industriesParagraph1'>
                        Regardless of the industry, business objective or technology, PerfectKode has served a variety of niche
                        when it comes to developing Web apps. Some of the verticals we provide Web app development
                        services include:
                    </p>
                </div>
                <div className='Boxrow1' data-aos="fade-right">
                    <div className='row'>
                        <div className='col-lg-4 md-auto ParentServeBox'>
                            <div className='IndustriesWeServeBox'>
                                <div className='icons'style={{ color: 'blue' }}>
                                    <HiSpeakerphone />
                                </div>
                                <div>
                                    <h4>Advertising & marketing</h4>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 md-auto ParentServeBox'>
                            <div className='IndustriesWeServeBox'>
                                <div className='icons'style={{ color: 'yellow' }}>
                                    <GiMusicalNotes />
                                </div>
                                <div>
                                    <h4>Arts, entertainment & music</h4>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 md-auto ParentServeBox'>
                            <div className='IndustriesWeServeBox'>
                                <div className='icons'style={{ color: 'green' }}>
                                    <AiFillCar />
                                </div>
                                <div>
                                    <h4>Automotive</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='Boxrow1' data-aos="fade-right">
                    <div className='row'>
                        <div className='col-lg-4 md-auto ParentServeBox'>
                            <div className='IndustriesWeServeBox'>
                                <div className='icons'style={{ color: 'pink' }}>
                                    <MdMiscellaneousServices />
                                </div>
                                <div>
                                    <h4>Consumer products & services</h4>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 md-auto ParentServeBox'>
                            <div className='IndustriesWeServeBox'>
                                <div className='icons'style={{ color: 'darkblue' }}>
                                    <BsFillCartCheckFill />
                                </div>
                                <div>
                                    <h4>eCommerce</h4>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 md-auto ParentServeBox'>
                            <div className='IndustriesWeServeBox'>
                                <div className='icons'style={{ color: "purple" }}>
                                    < ImBook />
                                </div>
                                <div>
                                    <h4>Education</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='Boxrow1' data-aos="fade-right">
                    <div className='row'>
                        <div className='col-lg-4 md-auto ParentServeBox'>
                            <div className='IndustriesWeServeBox'>
                                <div className='icons'style={{ color: "red" }}>
                                    <ImPower />
                                </div>
                                <div>
                                    <h4>Energy & natural resources</h4>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 md-auto ParentServeBox'>
                            <div className='IndustriesWeServeBox'>
                                <div className='icons'style={{ color: "brown" }}>
                                    <GiReceiveMoney />
                                </div>
                                <div>
                                    <h4>Financial services</h4>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 md-auto ParentServeBox'>
                            <div className='IndustriesWeServeBox'>
                                <div className='icons'style={{ color: "palegreen" }}>
                                    <BiGame />
                                </div>
                                <div>
                                    <h4>Gaming</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='Boxrow1' data-aos="fade-right">
                    <div className='row'>
                        <div className='col-lg-4 md-auto ParentServeBox'>
                            <div className='IndustriesWeServeBox'>
                                <div className='icons'style={{ color: "black" }}>
                                    <RiGovernmentLine />
                                </div>
                                <div>
                                    <h4>Government</h4>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 md-auto ParentServeBox'>
                            <div className='IndustriesWeServeBox'>
                                <div className='icons'style={{ color: "#00FA9A" }}>
                                    <RiHotelLine />
                                </div>
                                <div>
                                    <h4>Hospitality & leisure </h4>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 md-auto ParentServeBox'>
                            <div className='IndustriesWeServeBox'>
                                <div className='icons'style={{ color: "#FFA500" }}>
                                    <MdComputer />
                                </div>
                                <div>
                                    <h4>Information technology</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='Boxrow1' data-aos="fade-right">
                    <div className='row'>
                        <div className='col-lg-4 md-auto ParentServeBox'>
                            <div className='IndustriesWeServeBox'>
                                <div className='icons'style={{ color: "#B0E0E6" }}>
                                    <GoLaw />
                                </div>
                                <div>
                                    <h4>Legal</h4>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 md-auto'>
                            <div className='IndustriesWeServeBox ParentServeBox'>
                                <div className='icons'style={{ color: "#FF4500" }}>
                                    <MdPrecisionManufacturing />
                                </div>
                                <div>
                                    <h4>Manufacturing</h4>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 md-auto ParentServeBox'>
                            <div className='IndustriesWeServeBox'style={{ color: "Olive" }}>
                                <div className='icons'>
                                    <HiNewspaper />
                                </div>
                                <div>
                                    <h4>Media</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='Boxrow1' data-aos="fade-right">
                    <div className='row'>
                        <div className='col-lg-4 md-auto ParentServeBox'>
                            <div className='IndustriesWeServeBox'>
                                <div className='icons'style={{ color: "#DB7093" }}>
                                    <MdHealthAndSafety />
                                </div>
                                <div>
                                    <h4>Healthcare</h4>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 md-auto ParentServeBox'>
                            <div className='IndustriesWeServeBox'>
                                <div className='icons'style={{ color: "Violet" }}>
                                    <RiHandHeartLine />
                                </div>
                                <div>
                                    <h4>Non-profit</h4>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 md-auto ParentServeBox'>
                            <div className='IndustriesWeServeBox'>
                                <div className='icons'style={{ color: "Grey" }}>
                                    <GoRadioTower />
                                </div>
                                <div>
                                    <h4>Telecommunications</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='Boxrow1' data-aos="fade-right">
                    <div className='row'>
                        <div className='col-lg-4 md-auto ParentServeBox'>
                            <div className='IndustriesWeServeBox'>
                                <div className='icons'style={{ color: "#C0C0C0" }}>
                                    <MdEmojiTransportation />
                                </div>
                                <div>
                                    <h4>Supply Chain, Logistics, and Transport</h4>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 md-auto ParentServeBox'>
                            <div className='IndustriesWeServeBox'>
                                <div className='icons'style={{ color: "#8B4513" }}>
                                    <FaTools />
                                </div>
                                <div>
                                    <h4>Utilities</h4>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 md-auto ParentServeBox'>
                            <div className='IndustriesWeServeBox'>
                                <div className='icons'style={{ color: "#DDA0DD" }}>
                                    <BsThreeDots />
                                </div>
                                <div>
                                    <h4>Other industries</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  </>

    )

}

export default IndustriesWeServe