import React, { useEffect, useState } from 'react';
import Header from './Header';
import './StickyHeader.css';

const StickyHeader = () => {
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            setIsSticky(scrollTop > 0);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <div className='col-lg-auto col-md-auto'>
                <header className={`header-section d-xl-block ${isSticky ? 'is-sticky' : ''}`}>
                    <Header />
                </header>
            </div>
        </>
    );
};

export default StickyHeader;
