import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import "./About.css";
import AboutUsValues from './AboutUsValues';
import AboutVisionMission from './AboutVisionMission';
import WhyUs from './WhyUs';
import Contact from '../contact/contact';
import Who_We_Are from "../../assets/images/Who_We_Are.png";
import Data from '../../assets/json/about.json';

const About = () => {
    const location = useLocation();

    useEffect(() => {
        // Scroll to the section specified in the URL hash
        const hash = location.hash;
        if (hash) {
            const target = document.querySelector(hash);
            if (target) {
                target.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location.hash]);
    return (
        <>
            <div className='About'>
                A Digital Agency Of Innovation And Creativity
            </div>
            <div className='container'>
                <div className='Awesome' id="whoerwork">
                </div>
                <div className='row'>
                    <div className='col-lg-6 col-md-auto'>
                        {Data && Data.WhoWeAre && Data.WhoWeAre.map((item) => (
                            <>
                                <div className='AboutWhoWeAre'> <h1>{item.heading}</h1></div>
                                <center><div data-aos="flip-right" className='Underline_WWhoWeAre'></div></center>
                                <div className='Who_we_are'>
                                    <p>{item.description1}</p>
                                    <p>{item.description2}</p>
                                </div>
                            </>
                        ))}
                    </div>
                    <div className='col-lg-6 col-md-auto'>
                        <div>
                            <img className='Who_we_are_img' src={Who_We_Are} alt='Who we are?'></img>
                        </div>
                    </div>
                </div>
            </div>
            <div className='OurCoreValues'>
                <h1>OUR CORE VALUES</h1>                
            </div>
            <center><div data-aos="flip-right" className='Underline_CoreValues'></div></center>
            <div>
                <AboutUsValues />
            </div>
            <div id="our-vision-section">
                <AboutVisionMission />
            </div>
            <div>
                <WhyUs />
            </div>
            <div className='ContactUs'>
                <h1>
                    CONTACT US
                </h1>
                <center><div data-aos="flip-right" className='border-big'></div></center>
            </div>
            <div>
                <Contact />
            </div>
        </>
    )
}

export default About