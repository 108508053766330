import React from 'react'
import TrainingCourses from './TrainingCourses'
import Trainingbanner from './Trainingbanner'
const Training = () => {
  return (
    <div>
      <div>
        <Trainingbanner />
      </div>
      <div>
        <TrainingCourses />
      </div>
    </div>
  )
}

export default Training;