import React from 'react'
import { FaMapMarkerAlt } from 'react-icons/fa';
import "./Contact.css";
import india from '../../assets/images/India.png';
import { useNavigate } from 'react-router-dom';
import Mapcart from '../../components/Mapcart';
import {FiPhoneCall,} from 'react-icons/fi';
import {MdEmail} from 'react-icons/md';


const ContactRight = () => {

    const navigate = useNavigate();

    const Click = () => {

        navigate.push('/');

    };


    return (
        <div>
            <div className='contactDisplay'>
              
            </div>



            <div className='contactDisplay'>
                <div className='col-lg-4 col-md-auto'>
                    <div className='CallUs_EmailUs'>
                        <h4><FiPhoneCall /> Call Us</h4>
                        <a className='links' href="tel:+911204941128">+91 120-4941128</a>
                        <a className='links' href="tel:9971052007">+91 99710 52007</a>
                    </div>

                </div>

                <div className='col-lg-4 col-md-auto'>
                    <div className='CallUs_EmailUs'>
                        <h4><MdEmail /> Email Us</h4>
                        <a className='links' href="mailto:sales@perfectkode.com">sales@perfectkode.com</a>
                    </div>

                </div>
                <div className='col-lg-4 col-md-auto'>
    <div className='CallUs_EmailUs'>
        <h4><FaMapMarkerAlt /> Address</h4>
        <a className='links' href="https://www.google.com/maps?q=B-98, Office: 101, First Floor, Sector 2, Noida, UP">B-98, Office: 101, First Floor, Sector 2, Noida, UP</a>
    </div>
</div>

            </div>
            <div className='Contact-address1'>
                <div className='contactDisplay'>
                    <Mapcart />
                    </div>


                </div>
            </div>



        
    )
}

export default ContactRight