import React from "react";
import './Service.css';

import {BsArrowRight} from 'react-icons/bs';
import { Link } from "react-router-dom";

const ServiceCard = ({title,description,num,link,icon}) => {
    return(
    <div className="container">
        <div className="cardBox">
            <div class="content-box">
                <h5>{title}</h5>
                <div className="descriptionText">{description}</div>
                <Link to={link} class="btn-details"><i class="flaticon-right-arrow-1"></i><BsArrowRight /> LEARN MORE</Link>
            </div>
            <div className="number-box">
                {num}
            </div>
            <div className="icon-box">
                <span class="flaticon-tablet">{icon}</span>
            </div>
        </div>

    </div>
    )
}
export default ServiceCard;