import React, { useState, useRef } from "react";
import { FaArrowRight } from "react-icons/fa";
import { FaCode } from "react-icons/fa";

import "react-toastify/dist/ReactToastify.css";
import "./JobApplyForm.css";
import { useSelector } from "react-redux";
import emailjs from "@emailjs/browser";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const JobApplyForm = ({
  Name,
  Email,
  Phone,
  CollegeUniversityName,
  Degree,
  InterestedIn,
  PassingYear,
  Message,
}) => {
  const form = useRef();
  const [fname, setFname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [university, setUniversity] = useState("");
  const [degree, setDegree] = useState("");
  const [intrested, setIntrested] = useState("");
  const [passingyear, setPassing] = useState("");

  const [message, setMessage] = useState("");
  const { JobData } = useSelector((state) => state.blog);
  const { BlogData } = useSelector((state) => state.blog);
  console.log("sdfsdf", JobData, BlogData);
  function validateEmailAddress(input) {
    var regex = /[^\s@]+@[^\s@]+\.[^\s@]+/;
    return regex.test(input);
  }

  function validateName(input) {
    var regex = /^[A-Za-z\s]+$/;
    return regex.test(input);
  }

  const saveValidate = () => {
    if (!fname || !validateName(fname)) {
      toast.error("Name should only contain alphabetical letters");
      return false;
    }

    if (!email || !validateEmailAddress(email)) {
      toast.error("Use a valid email address");
      return false;
    }

    if (phone.length !== 10) {
      toast.error("Enter 10 digit Phone No.");
      return false;
    }

    if (!degree) {
      toast.error("Degree cannot be empty");
      return false;
    }

    if (!university) {
      toast.error("University Name Can Not Be Blank");
      return false;
    }

    if (!passingyear) {
      toast.error("Please Enter year");
      return false;
    }

    return true;
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!saveValidate()) {
      return; // Don't submit if validation fails
    }

    // Form submission logic
    emailjs
      .sendForm("contact_us", "template_0lnwnzg", form.current, {
        publicKey: "wpVb0lh0Js4LU1sCD",
      })
      .then(
        () => {
          toast.success(
            "We've received your request. Someone will contact you regarding your query soon."
          );

          // Reset form fields
          setFname("");
          setEmail("");
          setPhone("");
          setUniversity("");
          setDegree("");
          setIntrested("");
          setPassing("");
        },
        (error) => {
          toast.error("Error submitting the form");
        }
      );
  };
  return (
    <form ref={form} onSubmit={onSubmit}>
      <div className="container jobapply-get-job-perfectkode">
        <div className="row">
          <div className="col-lg-9">
            <label className="text-box-jobapply-job-apply">
              {" "}
              {InterestedIn}
            </label>
            <input
              className="text-box"
              type="text"
              disabled={false}
              value={JobData.Role}
              name="Job_Role"
              onChange={(e) => setIntrested(e.target.value)}
            ></input>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <label className="text-box-jobapply-job-apply">{Name}</label>
            <input
              className="text-box"
              type="text"
              value={fname}
              name="Full_Name"
              onChange={(e) => setFname(e.target.value)}
            ></input>
          </div>
          <div className="col-lg-5">
            {/* <label className="text-box-jobapply-job-apply"> {Email}</label>
            <input
              className="text-box"
              type="email"
              value={jobemail}
              name="job_Email"
              onChange={(e) => setjobEmail(e.target.value)}
            ></input> */}
                  <label className="text-box-jobapply-job-apply">
              {" "}{Email}</label>
                        <input
                            className='text-box'
                            type='email'
                            value={email}
                            name='Email_id'
                            onChange={(e) => setEmail(e.target.value)}
                        />
          </div>

          <div className="col-lg-3 ">
            <label className="text-box-jobapply-job-apply">{Phone}</label>
            <input
              className="text-box"
              type="number"
              name="Phone_no"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            ></input>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <label className="text-box-jobapply-job-apply"> {Degree}</label>
            <input
              className="text-box"
              type="text"
              value={degree}
              name="Degree"
              onChange={(e) => setDegree(e.target.value)}
            ></input>
          </div>

          <div className="col-lg-5">
            <label className="text-box-jobapply-job-apply">
              {CollegeUniversityName}
            </label>
            <input
              className="text-box"
              type="text"
              value={university}
              name="College_University_Name"
              onChange={(e) => setUniversity(e.target.value)}
            ></input>
          </div>
          <div className="col-lg-3">
            <label className="text-box-jobapply-job-apply">{PassingYear}</label>
            <input
              className="text-box"
              type="text"
              value={passingyear}
              name="Passing_Year"
              onChange={(e) => setPassing(e.target.value)}
            ></input>
          </div>
        </div>
        <div className="col-lg-12">
          <label className="text-box-jobapply-job-apply"> {Message}</label>
          <textarea
            className="text-box"
            type="text"
            value={message}
            name="message"
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </div>
        <div className="btn-align">
          <button className="GetaQuoteSubmitBtn" type="submit" value="Send">
            Submit <FaArrowRight />
          </button>
        </div>
        <ToastContainer />
      </div>
    </form>
  );
};

export default JobApplyForm;
