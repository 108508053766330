import React from 'react';
import Banner from "../careeropportunity/Banner";
import Careerdataopportunity from './Careerdataopportunity';

const Career = () => {
    return (
        <>
            <div>
                <Banner />
            </div>
            <div>
                <Careerdataopportunity />
            </div>
        </>
    )
}

export default Career