
import React from 'react';
import { FaArrowRight } from 'react-icons/fa';
import "./Button.css";

const ContactUsButton = ({ ButtonText, className,  onClick }) => {
  const buttonStyle = {
    width: 'auto',


  };

  return (
    <button className={`SubmitBtn ${className}`} style={buttonStyle} onClick={onClick}>
      {ButtonText} <FaArrowRight />
    </button>
  );
}

export default ContactUsButton;

export const CustomButton = ({ ButtonText, className,  onClick }) => {
  const buttonStyle = {
    width: 'auto', 
    color: 'gray',
    background: 'white'
  };

  return (
    <button className={`SubmitBtn ${className}`} style={buttonStyle} onClick={onClick}>
      {ButtonText}
    </button>
  );
}