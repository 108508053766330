import React from "react";
import { Card, CardImg, CardImgOverlay, CardTitle, CardText } from "reactstrap";
import "./CardPortfolio.css";


const CardPortfolio = ({cardTitle, category,imgUrl}) => {
    return (
        <div>
            <div>
                <Card inverse>
                    <CardImg
                        alt="Card image cap"
                        src={imgUrl}
                        width="100%"
                        
                    />
                    <CardImgOverlay>
                        <CardTitle tag="h5" className="PortfolioCardTitle MarginBottom2rem">
                            {cardTitle}
                        </CardTitle>
                     
                        <CardText className="PortfolioCardTitle">
                            <small className="text-muted PortfolioCategories">
                                {category}
                            </small>
                        </CardText>
                    </CardImgOverlay>
                </Card>
            </div>
        </div>
    )

}
export default CardPortfolio;