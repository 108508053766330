import React from 'react';
import "./QAworking.css";
import { Link } from 'react-router-dom';
import Button from "../../../components/Button";
import QAProcess from "../../../assets/images/QAProcess.png";
import robotics from "../../../assets/images/robotics.png";
import robot from "../../../assets/images/robot1.jpg";
import TrainingAndSeminar from "../../../assets/images/TrainingAndSeminar.png";
import Expert from "../../../assets/images/Expert.png";
import ServiceBox from "../ServiceBox";
import { Helmet } from 'react-helmet';

const QAworkings = () => {
  return (
    <div>
  
      <Helmet>
        <title>Software Quality Assurance | Mobile App Testing | Perfectkode</title>
        <meta
          name="description"
          content="Looking for software quality assurance, we are here to help, Our testing services ensure the stable and smooth delivery of high-quality software products."
        />
        <meta name="keywords" content="software quality assurance" />
      </Helmet>
      <div className="WebDevlopment">
        Superiority in Quality Assurance & Software Testing Services
        <h5>Deliver The Finest Software Products With Minimized Risks.</h5>
        <div>
          <Link to="/get-a-quote">  <Button ButtonText="Get A Quote" /></Link>
        </div>
      </div>
      <div className='WebDevCon1'style={{padding:"40px 0px"}}>
        <div className='container'>
        <div className=''>
          <div class="">
            <h1 className='WebHeader'>Software Quality Assurance & Software Testing Services</h1>
    <center>
                  <div
                    data-aos="flip-right"
                    className="h-down-line-under6"
                  ></div>
                </center>
                <div className='contentQA'>
                <p>We, at Perfectkode, understand the business impact of software flaws and hence offer you quality assurance services for software, mobile app, and web application products and services. We have an AI-driven and highly automated approach to QA testing that helps in significantly reduce your QA expenses. Our testing services ensure the stable and smooth delivery of high-quality software products. Our excellence in software quality assurance and mobile app testing helps our customers achieve their business goals.</p>
                </div>
           
            <img className='image-training-image-qa-services' src={TrainingAndSeminar}
              style={{ width: '50%' }} alt="Training And Seminar"/>
          </div>
          <div className='container qa-testing-qa-manual-testing'>
            <div class="training-services">
              <h3 className='text-heading-qa-services-text'> Manual Testing Processes </h3>
      <center>
                  <div
                    data-aos="flip-right"
                    className="h-down-line-under1"
                  ></div>
                </center>
{/*          
             <h1 className='WebHeader'>What is Manual Testing?</h1> */}
             <div className='contentQA'>
              <p>Manual testing is the process of finding out the bugs in a software program. The tester plays an important role for the end-user and verifies that all the features of the application are working correctly. The tester manually executes test cases without using any automation tools. The tester prepares a test plan document that describes the detailed and systematic approach to testing software applications. Test cases are planned to cover almost 100% of the software application. As manual testing involves complete test cases it is a time-consuming test. The differences between actual and desired results are treated as defects. The defects are then fixed by the developer of the software application.</p>
              </div>
              <img className='image-training-image-qa-manual'  src={QAProcess}
                style={{ width: '50%' }} alt="QA Process"/>
            </div>
          </div>
          <div className='container-automation-test'>
          </div>
          <div className='container-automation-test'>
            <div className='Automation-qa-test-automation'>
              <h1 className='text-heading-qa-services-text'> Automation QA Services </h1>
      <center>
                  <div
                    data-aos="flip-right"
                    className="h-down-line-under1"
                  ></div>
                </center>

                <div className='contentQA'>
                <p>Our team of automation QA testing engineers designs the strategy to check various parameters such as functionality, speed, quality, loading time, and much more to give you a detailed report about the same. As both manual tests can be tedious and take a long time, our professionals do the automation testing rapidly to give you a detailed review in less time. We use multiple tools for automation testing services that include Selenium and Python etc. Getting your software tested by these tools not only increases the overall efficiency but also helps in enhancing it. With our vast experience in providing the best automation testing services and tools, we help you eradicate the lacking factors and work towards the betterment of your projects.</p>
              </div>
              <div>
                <img className='image-training-image-qa-automation' src={Expert}
                  style={{ width: '50%',display:"flex",margin:"auto" }} alt="Expert"/>
              </div>
          
                <div className='para-robot-para'>
              <h4 style={{textAlign:"left"}}>WHY CHOOSE OUR AUTOMATED TESTING SERVICES?</h4>
                <div className='contentQA'>
                <p>Best thing about automation is ROI. There is large ROI in automation and we at Perfectkode recommend every client to get benefit of ROI through automation and save time as well
                  as additional testing cost.</p>
              </div>

              </div>
            </div>
          </div>
        </div>
        <div className='robot-automation-process'>
          <h1  className='text-heading-qa-services-text'> Robotics Process Automation </h1>
  <center>
                  <div
                    data-aos="flip-right"
                    className="h-down-line-under1"
                  ></div>
                </center>
          <div>
            <div className='para-robot-para'>
              <h4 style={{textAlign:"left"}}>What is robotic process automation?</h4>
              <div className='contentQA'>
              <p > RPA, or Robotic Process Automation, is a powerful new way to automate process of software quality assurance. Unlike traditional systems that require you to develop an application, RPA works with your existing software and technology infrastructure. By leveraging the latest advances in machine learning and artificial intelligence, RPA solutions can identify patterns, understand complex processes and adapt to changing circumstances. It helps your people do their best work, instead of performing often repetitive tasks.</p>
              </div>
            </div>
          </div>
          <div>
            <img className='image-training-image-qa-robotics' src={robotics}
              style={{ width: '50%' }} alt="Robotics" />
          </div>
        </div>
        <div className=' container-qa-testing-qa' style={{paddingLeft:"30px",paddingRight:"30px"}}>
          <div class="training-services">
            <h1 className='text-heading-qa-services-text'> Performance Engineering of Software Systems</h1>
    <center>
                  <div
                    data-aos="flip-right"
                    className="h-down-line-under4"
                  ></div>
                </center>
            <div className='row'>
              <div className='col-md-12'>
            
                <h4 style={{textAlign:"left"}}> Why You Need It ?</h4>
                <div className='contentQA'>
                <p>Software engineers, QA testers and developers work together to ensure that a product is delivering its full potential in terms of performance. But issues in your software systems happen every day. Most performance issues are caused by faulty assumptions made when building the software. Developers are also humans and can make wrong assumptions when they code. Performance engineering is a process in which IT professionals use various tools and techniques to identify issues that are built into the software. By learning from these mistakes, engineers can create better code in the future so that systems perform well even when they have high loads or unusual usage patterns.</p>
               </div>
                
              <h4 style={{textAlign:"left"}}>What are some ways of overcoming the assumptions we all make when coding? </h4>
              <div className='contentQA'>
                <p>  Performance in software engineering helps to ensure that your IT team is on track and reliable in delivering the project, allowing you to minimize risks and reduce costs.</p>
              </div>
              </div>
             
              <div className='col-md-12'>
     <div>    <img className='image-training-imageqa-performance' src={robot}
               alt="Robot Image" /></div>
            
                      </div>
             
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <ServiceBox Heading="You Don't Need Just a Developer, You Need a Partner"
          ParraFirst="We can help you convert your belief into reality, by building a state-of-the-art mobile application."
          ParraSecond="Connect with us today and get started with full-stack mobile app development." />
      </div>
      </div>
    </div>
  )
}
export default QAworkings;




