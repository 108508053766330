import React from "react";
import "./trainingbanner.css"; 
import trainingBanner from "../../../assets/images/trainingBanner.png";

const Carrbannerblue=()=>{
  return (

    <div className="career-headbanner-midbanner">
        <img className="training-career-training-banner " src={trainingBanner}/>
    </div>
  )
}
export default Carrbannerblue;
