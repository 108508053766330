import React from 'react'
import india from '../../assets/images/India.png';
const GetaQuoteAddress = () => {
    return (
        <div className='container Adressmain'>
            <h2 className='HeaderAddress'>
                Our Workspaces
            </h2>
            <div>
                <div className="QuoteBar"></div>
            </div>
            <div className='row AdressRow'>

                <div className='col-lg-6 col-md-auto'>
                    <h4>Noida</h4>
                    <p>

                    B-98, Office: 101, First Floor, Sector 2, Noida, UP
                        <div>
                            <p>
                                Contact: +91 120-4174801 | +91 99710 52007
                                
                            </p>
                        </div>

                    </p>

                    {/* <a className='links' href="https://goo.gl/maps/ASNvuvoJ3wQLsgnCA" target="_blank" rel="noopener"><h6>Get direction</h6></a>
                    <hr></hr> */}

                </div>



                <div className='col-lg-6 col-md-auto'>
                    <h4>New Delhi</h4>
                    <p>
                        C - 3 / 62, Sector-11,
                        Rohini, Delhi - 110085
                        India
                    </p>
                    <br/>

                    {/* <a className='links' href="https://goo.gl/maps/xYpmE8ckGybpq4AQ9" target="_blank" rel="noopener"><h6>Get direction</h6></a>


                    <hr></hr> */}
                </div>
            </div>


        </div>
    )
}

export default GetaQuoteAddress