import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const BlogDetails = () => {
    let { id } = useParams();
    const { BlogData } = useSelector((state) => state.blog);

    return (
        <div>
            <div className="container PaddingBottom30px">
                <div className='row'>
                    {BlogData.map((item, i) => (
                        <>
                            <div className='H1HeaderBlog'>
                                <h1>{item.Heading}</h1>
                            </div>
                            <p><strong>{item.DateTime}</strong></p>
                            <div className='textAlign'>
                                <div dangerouslySetInnerHTML={{ __html: item.Description }} />
                            </div>
                            <div>
                                {item.AboutAuthor}
                            </div>
                        </>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default BlogDetails