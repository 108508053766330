import React from "react";
import { Link } from "react-router-dom";

const DifferentCourses = ({ image_url, name, description, detailurl }) => {
    return (
        <>
            <div className="card tj  "data-aos="fade-right">
                <div>
                    <img  className="image-card" src={image_url}></img>
                    
                </div>
                <h2 className="card-heading-color">{name}</h2>
                <p className="card-para-career-traing-web-java-front-back">{description}</p>
                <div className="button-job--training-job-exp">
                    <Link to={'/training/' + detailurl} className="scroll-down-web-intern cart-button-cart-add-button">
                        Read More..
                    </Link>
                </div>
            </div>
        </>
    )
}
export default DifferentCourses;