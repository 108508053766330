import React from 'react'
import "./DevlopmentProcess.css"
import { SiMicrostrategy } from 'react-icons/si';
import { FaProjectDiagram } from 'react-icons/fa';
import { MdDevicesOther } from 'react-icons/md';
import { AiOutlineCode } from 'react-icons/ai';
import { BsShieldCheck } from 'react-icons/bs';
import { MdPreview } from 'react-icons/md';
import { RiFileCopyLine } from 'react-icons/ri';
import { BiSupport } from 'react-icons/bi';

const DevlopmentProcess = () => {
    return (
        <><div className='container ProcessMain'>
            <div className='HeaderOfProcess'>
                <h1>
                    DEVELOPMENT PROCESS
                </h1>
                <div className="BarDevelopmentprocess"data-aos="flip-right"></div>
            </div>
            <div className="row ProcessRowPadding" >
                <div className="col-lg-5 md-auto ProcessBoxParent">
                    <div className="ProcessBox in-left" style={{ visibility: 'visible', animationName:' fadeInLeftdev',  animationDuration:"0.5s"  }}>
                        <div className='nummain'>
                            <div className="ProcessNum">
                                <div className="ProcessNumText">1</div>
                            </div>
                        </div>
                        <div>
                            <h5 className="ProcessHeader">
                                Strategy
                            </h5>
                            <p className="ProcessPara">
                                Discovering and recognizing your business goals
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 md-auto gapbox">
                </div>
                <div className="col-lg-5 md-auto ProcessBoxParent">
                    <div className="ProcessBox  faderights" style={{ visibility: 'visible', animationName:'fadeInRight', animationDuration:"0.5s" }}>
                        <div className='nummain'>
                            <div className="ProcessNum">
                                <div className="ProcessNumText">2</div>
                            </div>
                        </div>
                        <div>
                            <h5 className="ProcessHeader">
                                Project proposal
                            </h5>
                            <p className="ProcessPara">
                                Assembling detailed project proposals.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row ProcessRowPadding ">
                <div className="col-lg-5 md-auto ProcessBoxParent">
                    <div className="ProcessBox  in-left" style={{ visibility: 'visible', animationName:' fadeInLeftdev',  animationDuration:"0.5s"  }}>
                        <div className='nummain'>
                            <div className="ProcessNum">
                                <div className="ProcessNumText">3</div>
                            </div>
                        </div>
                        <div>
                            <h5 className="ProcessHeader">
                                UI/UX
                            </h5>
                            <p className="ProcessPara">
                                encourage fast-moving aesthetic trends.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 md-auto gapbox"></div>
                <div className="col-lg-5 md-auto ProcessBoxParent">
                    <div className="ProcessBox  faderights" style={{ visibility: 'visible', animationName:'fadeInRight', animationDuration:"0.5s" }}>
                        <div className='nummain'>
                            <div className="ProcessNum">
                                <div className="ProcessNumText">4</div>
                            </div>
                        </div>
                        <div>
                            <h5 className="ProcessHeader">
                                Development
                            </h5>
                            <p className="ProcessPara">
                                Implementing feasible frameworks and code.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row ProcessRowPadding">
                <div className="col-lg-5 md-auto ProcessBoxParent">
                    <div className="ProcessBox in-left" style={{ visibility: 'visible', animationName:' fadeInLeftdev',  animationDuration:"0.5s"}}>
                        <div className='nummain'>
                            <div className="ProcessNum">
                                <div className="ProcessNumText">5</div>
                            </div>
                        </div>
                        <div>
                            <h5 className="ProcessHeader">
                                Quality Assurance
                            </h5>
                            <p className="ProcessPara">
                                Uncompromising quality testing at every level.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 md-auto gapbox"></div>
                <div className="col-lg-5 md-auto ProcessBoxParent">
                    <div className="ProcessBox  faderights" style={{ visibility: 'visible', animationName:'fadeInRight', animationDuration:"0.5s" }}>
                        <div className='nummain'>
                            <div className="ProcessNum">
                                <div className="ProcessNumText">6</div>
                            </div>
                        </div>
                        <div>
                            <h5 className="ProcessHeader">
                                Security review
                            </h5>
                            <p className="ProcessPara">
                                Security inspection as indicated by OWASP.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row ProcessRowPadding">
                <div className="col-lg-5 md-auto ProcessBoxParent">
                    <div className="ProcessBox  in-left" style={{ visibility: 'visible', animationName:' fadeInLeftdev',  animationDuration:"0.5s"}}>
                        <div className='nummain'>
                            <div className="ProcessNum">
                                <div className="ProcessNumText">7</div>
                            </div>
                        </div>
                        <div>
                            <h5 className="ProcessHeader">
                                Deployment
                            </h5>
                            <p className="ProcessPara">
                                Replicating, arranging, and enabling code.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 md-auto gapbox"></div>
                <div className="col-lg-5 md-auto ProcessBoxParent">
                    <div className="ProcessBox  faderights" style={{ visibility: 'visible', animationName:'fadeInRight', animationDuration:"0.5s" }}>
                        <div className='nummain'>
                            <div className="ProcessNum">
                                <div className="ProcessNumText">8</div>
                            </div>
                        </div>
                        <div>
                            <h5 className="ProcessHeader">
                                Maintenance and support
                            </h5>
                            <p className="ProcessPara">
                                Maintenance support as long as you need us.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <div>
                <div className='container'>
                    <div className='DevProcessHead'>
                        <div className='DevProcessIcon'style={{color:"blueviolet"}}>
                            <SiMicrostrategy />
                        </div>
                        <div>
                            <div className='DevProcessDetailedHead'>
                                <h4 className='w-100'>
                                    Strategy
                                </h4>
                            </div>
                            <div>
                                <p className='DevProcessPara'>
                                    Let us help you build a better website. After you fill out the consultation request
                                    form on our website, one of our consultants contacts you to schedule a meeting.
                                    The first consultation is free of charge. We start with an analysis to better
                                    understand what your core users want, followed up by workshops to prioritize
                                    requirements.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='DevProcessHead'>
                        <div className='DevProcessIcon'style={{color:'pink'}}>
                            <FaProjectDiagram />
                        </div>
                        <div>
                            <div className='DevProcessDetailedHead'>
                                <h4 className='w-100'>
                                    Project proposal
                                </h4>
                            </div>
                            <div>
                                <p className='DevProcessPara'>
                                    A project proposal is a document that provides information about the items that should
                                    be implemented and/or executed to achieve the desired project result. It contains
                                    detailed information about what, why, how, when, and who.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='DevProcessHead'>
                        <div className='DevProcessIcon'style={{color:'purple'}}>
                            <MdDevicesOther />
                        </div>
                        <div>
                            <div className='DevProcessDetailedHead'>
                                <h4 className='w-100'>
                                    UI/UX
                                </h4>
                            </div>
                            <div>
                                <p className='DevProcessPara'>
                                    UI is an interface through which humans interact with computers. A successful user
                                    interface makes the user feel like they are naturally interacting with the computer.
                                    It should never seem complex and should be easy to understand.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='DevProcessHead'>
                        <div className='DevProcessIcon'style={{color:'yellow'}}>
                            <AiOutlineCode />
                        </div>
                        <div>
                            <div className='DevProcessDetailedHead'>
                                <h4 className='w-100'>
                                    Development
                                </h4>
                            </div>
                            <div>
                                <p className='DevProcessPara'>
                                    Development is the part of the process where software engineers actually program the
                                    code for the project. Implementing feasible frameworks, writing efficient code to solve
                                    the problem and sticking to the deadlines, while maintaining code quality is what a
                                    developer does everyday.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='DevProcessHead'>
                        <div className='DevProcessIcon'style={{color:'brown'}}>
                            <BsShieldCheck />
                        </div>
                        <div>
                            <div className='DevProcessDetailedHead'>
                                <h4 className='w-100'>
                                    Quality Assurance
                                </h4>
                            </div>
                            <div>
                                <p className='DevProcessPara'>
                                    Testing is important because it ensures that your requirements are met, defects are
                                    found early and corrected often, which prevents excessive rework and saves time in the
                                    long run.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='DevProcessHead'>
                        <div className='DevProcessIcon'style={{color:'green'}}>
                            <MdPreview />
                        </div>
                        <div>
                            <div className='DevProcessDetailedHead'>
                                <h4  className='w-100'>
                                    Security review
                                </h4>
                            </div>
                            <div>
                                <p className='DevProcessPara'>
                                    A security review is a process to work with the security and development teams to
                                    understand the current application security practices. This will be a collaborative
                                    process and would require engineers from the application team, cloud architecture,
                                    and information security.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='DevProcessHead'>
                        <div className='DevProcessIcon'style={{color:'blue'}}>
                            <RiFileCopyLine />
                        </div>
                        <div>
                            <div className='DevProcessDetailedHead'>
                                <h4 className='w-100'>
                                    Deployment
                                </h4>
                            </div>
                            <div>
                                <p className='DevProcessPara'>
                                    We’re all about building great software, so we wanted to give a little love to the steps
                                    after coding. In code deployment, developers place their code on web servers for use
                                    by visitors. It can include bug fixes and new features. Merging code into a build
                                    may require a review to ensure that it’s free from errors. Code deployment ensures
                                    quality!
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='DevProcessHead'>
                        <div className='DevProcessIcon'style={{color:'red'}}>
                            <BiSupport />
                        </div>
                        <div>
                            <div className='DevProcessDetailedHead'>

                                <h4 className='w-100'>
                                    Maintenance and support
                                </h4>
                            </div>
                            <div>
                                <p className='DevProcessPara'>
                                    Website maintenance and support services are essentially the most useful way to
                                    retain as well as attract your existing and potential customers. The website is
                                    integral to your business success, and so, it is imperative to ensure that it
                                    stays up-to-date, is optimized, secure and search engine optimized to keep
                                    delivering optimal user experience and remain competitive.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DevlopmentProcess



