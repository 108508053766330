import React, { useEffect, useState } from 'react';
import './portfolio.css';
import Button from "../../components/Button";
import { Link, useNavigate } from 'react-router-dom';
import CardPortfolio from './CardPortfolio';
import  getProjects  from '../../assets/json/portfolio.json';


const FIRST_LEVEL_FILTER = [{ id: 1, name: "BY TECHNOLOGIES", type: "technology" }, { id: 2, name: "BY INDUSTRY" }, { id: 3, name: "BY DOMAIN" }];
const SECOND_LEVEL_FILTER = {
    "1": [{ code: "all", name: "All" }, { code: "react", name: "React Js" }, { code: 3, name: "Angular" }, { code: 4, name: "Java" }],
    "2": [{ code: "all", name: "All" }, { code: "health", name: "Health" }, { code: 3, name: "Finance" }, { code: 4, name: "Social" }],
    "3": [{ code: "all", name: "All" }, { code: "non-profit", name: "Non-Profit" }, { code: 3, name: "Education" }, { code: 4, name: "Media" }],
};



const Portfolio = () => {

    const [firstLevelFilterList, setFirstLevelFilterList] = useState(FIRST_LEVEL_FILTER);
    const [selectedFirstLevelFilter, setSelectedFirstLevelFilter] = useState({});

    const [secondLevelFilterList, setSecondLevelFilterList] = useState([]);
    const [selectedSecondLevelFilter, setSelectedSecondLevelFilter] = useState({});

    const [allProjectList, setAllProjectList] = useState([]);
    const [filterProjectList, setFilterProjectList] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        getPortfolioProjects();
    }, [])

    const getPortfolioProjects = async () => {
        // const projects = await getProjects();
        console.log(getProjects && getProjects.portfolios);
        setAllProjectList(getProjects && getProjects.portfolios);
        setFilterProjectList(getProjects && getProjects.portfolios);
    };

    const handleFirstLevelFilter = (filter) => {
        if (filter && filter.id) {
            setSelectedFirstLevelFilter(filter);
            const secondLevelList = SECOND_LEVEL_FILTER[filter.id];
            setSecondLevelFilterList(secondLevelList);
        }
    }
    const handleSecondLevelFilter = (filter) => {
        console.log("filter",filter.code);
        if (filter && filter.code) {
            let filterList = [];
            if (filter.code === 'all') {
                filterList = allProjectList.filter(project => true);
                console.log("fjf",filterList);
            } else {
                filterList = allProjectList.filter(project => project[selectedFirstLevelFilter.type] === selectedSecondLevelFilter.code);
                console.log("fjfesle",filterList); 
            }
            setFilterProjectList(filterList);
            setSelectedSecondLevelFilter(filter);
        }
       
    }

    
     console.log(filterProjectList,allProjectList,secondLevelFilterList);
const portClick =(data)=>{
    console.log("g",data);
      navigate("/portfolio/"+data.url);
    
}
    return (
        <>
            <div>
                <img className='portbanner' src="https://firebasestorage.googleapis.com/v0/b/perfectkode1.appspot.com/o/Portfolio%2Fportfolio.png?alt=media&token=1bfe32d0-023b-4a2e-b948-e9bfdcbee2f5" alt="Port Banner"/>
            </div>

            <div className="container">
                <h1>
                    Projects
                </h1>
                <p IntroContentPortfolio>
                    The apps we deliver are always based on a great idea requiring a unique software solution. The scope of our projects ranges from individual apps for startups to large scale national and international solutions.
                </p>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-3">
                            <div className="text-snap">
                                <div className="cutom-shadow No_text ">
                                    <span>5</span>
                                    <sup className="orange-text">+</sup>
                                    <p className="black-text">Years of Experience</p>

                                </div>

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-3">
                            <div className="text-snap">
                                <div className="cutom-shadow No_text ">
                                    <span>200</span>
                                    <sup className="orange-text">+</sup>
                                    <p className="black-text">Web & App Devloped</p>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-3">
                            <div className="text-snap">
                                <div className="cutom-shadow No_text ">
                                    <span>80</span>
                                    <sup className="orange-text">%</sup>
                                    <p className="black-text">Repeated Clients</p>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div>
                <div className='container TapPortfolioHead'>
                    <div className='row'>
                        <div className='col-lg-auto col-md-auto OnTaps'>
                            {firstLevelFilterList && firstLevelFilterList.map((f, i) =>
                                <div key={i} id={f.id} className={f.id === selectedFirstLevelFilter.id ? 'Onclick_Tech active' : 'Onclick_Tech'} onClick={() => handleFirstLevelFilter(f)}>
                                    <h4>{f.name}</h4>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-auto col-md-auto OnTaps mb-3'>
                            {secondLevelFilterList && secondLevelFilterList.map((f, i) =>
                                <div key={i} id={f.code} className='OnTapContent' onClick={() => handleSecondLevelFilter(f)}>
                                    <button className='TapBtns'>{f.name}</button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className='row'>
                    {filterProjectList && filterProjectList.map((f, i) =>
                        <div className="col-lg-6 MarginBottom1rem" key={i} onClick={()=>portClick(f)}>
                            <CardPortfolio
                                cardTitle={f.name} category={f.domain} imgUrl="https://picsum.photos/318/270" />
                        </div>
                    )}
                </div>
            </div>

            <div className="Portfoliofooter">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-8 padiingatbottom">
                            <h4>
                                Ready To Build Your Custom Application Solution?
                            </h4>
                            <h6>
                                Send Your Requirements on Sales@PerfectKode.com
                            </h6>
                        </div>

                        <div className="col-lg-4 PortfolioBtn">

                            <Link to="/get-a-quote">  <Button ButtonText="Get A Quote" className="bg-red-color" /></Link>

                        </div>
                        <hr></hr>
                    </div>
                </div>
            </div>

        </>

    )
}
export default Portfolio;
