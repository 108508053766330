import React from "react";
import "./Carrbannerblue.css";
import Careermiddle from "../../../assets/images/Careermiddle.png";

const Carrbannerblue = () => {
  return (

    // <div className="career-headbanner-midbanner">
    //     <img className="imag-mid-blue-banner-midd " style={{ height: "80%" }}  src={Careermiddle}/>
    // </div>
    <div className="banner-LifeatPerfectkodeMiddle">
      We Never Think About Abort The Mission,
      We Just Complete It.
    </div>
  )
}
export default Carrbannerblue;