// import React from "react";
// import Carousel from 'react-bootstrap/Carousel';
// import bckimg from "../../assets/images/bckimg.png";
// import "./ClientCarousel.css";

// const Carousels = () => {

//   return (
//     <>
//       <Carousel fade className="margin-over-all-px">
//         <Carousel.Item>
//           <img
//             className="d-block w-100-680px w-100"
//             src={bckimg} alt="back image"
//           />
//           <Carousel.Caption className="width-100-fixs">

//             <h3 className="color-white-px">Owner of Fintech based company, UK</h3>
//             <p className="color-gray-px" >Kamal is a great guy, very responsive, and very knowledgeable. If he does not know something, he will find out and get stuff done for you. Very pleased.</p>
//           </Carousel.Caption>

//         </Carousel.Item>
//         <Carousel.Item>
//           <img
//             className="d-block w-100-680px w-100"
//             src={bckimg} alt="back image"
//           />
//           <Carousel.Caption className="width-100-fixs">
//             <h3 className="color-white-px">MD, Education based learning</h3>
//             <p className="color-gray-px">He's an excellent at web programming. It was fun and interesting working with you kamal and hope I can work with you again shortly soon.</p>
//           </Carousel.Caption>
//         </Carousel.Item>
//         <Carousel.Item>
//           <img
//             className="d-block w-100-680px w-100"
//             src={bckimg} alt="back image"

//           />

//           <Carousel.Caption className="width-100-fixs">
//             <h3 className="color-white-px">Owner of Sports ulility</h3>
//             <p className="color-gray-px">Always available, extremely knowledgeable, and a tremendous can-do attitude. Our company was on a tight timeline and strict budget to create a first-class mobile interpretation app.</p>
//           </Carousel.Caption>
//         </Carousel.Item>
//       </Carousel>

//     </>
//   )
// }
// export default Carousels;

import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import TestimonialCard from "../../components/TestimonialCard";
import TestimonialData from "../../assets/json/testimonial.json";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectCoverflow, Navigation, Pagination } from "swiper";
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";

function ClientCarousel() {
  const [Testimonial, setTestimonial] = useState(TestimonialData);
  return (
    <div>
      {window.innerWidth > 570 ? (
        <div class="container-fluid">
          <Swiper
            effect={"coverflow"}
            autoplay={true}
            grabCursor={true}
            centeredSlides={true}
            loop
            slidesPerView={3}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 2.5,
            }}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
              clickable: true,
            }}
            modules={[EffectCoverflow, Pagination, Navigation]}
            className="swiper_container"
          >
            {Testimonial &&
              Testimonial.clients &&
              Testimonial.clients.map((client, i) => (
                <>
                  <SwiperSlide>
                    <TestimonialCard details={client} />
                  </SwiperSlide>
                </>
              ))}
            <div className="slider-controler">
              <div className="swiper-button-prev slider-arrow">
                <AiOutlineArrowLeft />
              </div>
              <div className="swiper-button-next slider-arrow">
                <AiOutlineArrowRight />
              </div>
              <div className="swiper-pagination"></div>
            </div>
          </Swiper>
        </div>
      ) : (
        <div class="container-fluid">
          <Swiper
            effect={"coverflow"}
            autoplay={true}
            grabCursor={true}
            centeredSlides={true}
            loop
            slidesPerView={"auto"}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 2.5,
            }}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
              clickable: true,
            }}
            pagination={{ el: ".swiper-pagination", clickable: true }}
            modules={[EffectCoverflow, Pagination, Navigation]}
            className="swiper_container"
          >
            {Testimonial &&
              Testimonial.clients &&
              Testimonial.clients.map((client, i) => (
                <>
                  <SwiperSlide>
                    <TestimonialCard details={client} />
                  </SwiperSlide>
                </>
              ))}
            <div className="slider-controler">
              <div className="swiper-button-prev slider-arrow">
                <AiOutlineArrowLeft />
              </div>
              <div className="swiper-button-next slider-arrow">
                <AiOutlineArrowRight />
              </div>
              <div className="swiper-pagination"></div>
            </div>
          </Swiper>
        </div>
      )}
    </div>
  );
}

export default ClientCarousel;
