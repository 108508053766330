import React, { useState } from "react";
import Footer from "./Footer";
import StickyHeader from "./StickyHeader";
import GetaQuote from "./GetaQuote";
import { useLocation, useParams, useRouteMatch } from "react-router";

const Layout = ({ children, recordId }) => {
  const location = useLocation();
  const params = useParams();
  const currentUrl = location.pathname;
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const drawerToggleClickHandler = () => {
    setSideDrawerOpen(!sideDrawerOpen);
  };
  const backDropClickHandler = () => {
    setSideDrawerOpen(false);
  };
  const HIDE_FOOTER_PAGES = [
    "/pagenotfound"
    
  ];
  const HIDE_HEADER_PAGES = [
    "/pagenotfound"
  ];
  const HIDE_GETQUOTE_PAGES = [
    "/pagenotfound"
  ];
  const showFooter =
    HIDE_FOOTER_PAGES.includes(currentUrl) ||
      HIDE_FOOTER_PAGES.includes(
      )
      ? false
      : true;
  const showHeader =
    HIDE_HEADER_PAGES.includes(currentUrl) ||
      HIDE_HEADER_PAGES.includes(
      )
      ? false
      : true;
  const showQuote =
    HIDE_GETQUOTE_PAGES.includes(currentUrl) ||
      HIDE_GETQUOTE_PAGES.includes(
      )
      ? false
      : true;
  return (
    <>
      <div className='bg-color3 '>
        {showHeader && (
          <StickyHeader
            className='headr'
            drawerToggleClickHandler={() => drawerToggleClickHandler()}
          />
        )}
        {showQuote && (
          <GetaQuote />
        )}
        {children}
        {showFooter && <Footer />}
      </div>
    </>
  );
};

export default Layout;
