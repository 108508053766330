import React from "react";
import "./AboutVisionMission.css";
import VisionImg from "../../assets/images/VisionImg.png";
import missionImg from "../../assets/images/missionImg.png";
import data from "../../assets/json/about.json";

const AboutVisionMission = () => {
  return (
    <div>
      <div className="OurVisonMission">
        <h1>OUR VISION & MISSION</h1>
      </div>
      <center>
        <div data-aos="flip-right" className="Underline_CoreValues"></div>
      </center>
      <div id="our-vision">
        <div className="container Vision_Container">
          <div className="row">
            {data &&
              data.AboutVision &&
              data.AboutVision.map((item) => (
                <>
                  <div className="col-lg-6 col-md-auto">
                    <div className="Vision_content">
                      <h1 className="VisionHeadingH1">{item.heading}</h1>
                      <p>{item.description1}</p>
                      <p>{item.description2}</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-auto">
                    <div>
                      <img
                        className="Vision_img"
                        src={VisionImg}
                        alt="Vision"
                      ></img>
                    </div>
                  </div>
                </>
              ))}
          </div>
        </div>
      </div>
      <div id="our-mission">
        <div className="container Mission_Container">
          <div className="row">
            {data &&
              data.AboutMission &&
              data.AboutMission.map((item) => (
                <>
                  <div className="col-lg-6 col-md-auto">
                    <div>
                      <img
                        className="Mission_img"
                        src={missionImg}
                        alt="Mission"
                      ></img>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-auto">
                    <div className="Mission_Content">
                      <h1 className="MissionHeadingH1">{item.heading}</h1>
                      <p>{item.description1}</p>
                      <p>{item.description2}</p>
                    </div>
                  </div>
                </>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutVisionMission;
