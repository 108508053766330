import React from "react";
import "./PageNotFound.css";
import { useNavigate } from "react-router-dom";
const PageNotFound =()=>{
    const navigate = useNavigate();
    const HomePage =()=>{
        navigate("/")
    }
    return(
        <>
     <div className="margin-autos-w100">
        <div className="numberstyle404">
          <span>404</span>
        </div>
        <div className="oops-all-design">
          <span style={{fontWeight:'bold'}}>Oops!</span>
        </div>
        <div >
          <span className="oops-all-design">We're sorry, the page Your were looking for does not exit anymore</span>
        </div>
        <div  className="">
         <button className="backtohome-page" onClick={HomePage}>BACKTOHOME</button>
        </div>
     </div>
        </>
    )
}
export default PageNotFound;