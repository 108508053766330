import React from "react";
import "./Banner.css";
import dreamJob from "../../../assets/images/dreamJob.png";

const Carrbannerblue=()=>{
  return (
    <div className="career-headbanner-midbanner">
        <img className="BannerJobOpportunity" src={dreamJob}/>
    </div>
  )
}
export default Carrbannerblue;