import React from "react";
import "./App.css";
import { Switch, Route, BrowserRouter, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Contact from "./containers/contact/contact";
import Home from "./containers/home/Home";
import About from "./containers/aboutUs/About";
import Layout from "./containers/layout/Index";
import Career from "./containers/career/careeropportunity/Career";
import WebDevlopment from "./containers/services/webDevlopment/WebDevlopment";
import Training from "./containers/career/traininganddevlopment/Training";
import IndustriesWeServe from "./containers/process/industriesweserve/IndustriesWeServe";
import GetaQuote from "./containers/getaquote/GetaQuote";
import MobileApplication from "./containers/services/mobileApplications/MobileApplication";
import LifeAtPerfectKode from "./containers/career/lifeatperfectkode/LifeAtPerfectKode";
import DevlopmentProcess from "./containers/process/developmentProcess/DevlopmentProcess";
import HowWeWork from "./containers/process/howWeWork/HowWeWork";
import HelpingStartups from "./containers/process/mvp/Mvp";
import EnterpriseApplication from "./containers/services/enterpriseAApplication/EnterpriseApplication";
import QAworkings from "./containers/services/Qatesting/QAworkings";
import CloudEnablement from "./containers/services/cloudenablement/CloudEnablement";
import StrategicSolution from "./containers/services/strategicSolution/StrategicSolution";
import TechnologyConsulting from "./containers/services/technologyConsulting/TechnologyConsulting";
import Careerdataopportunity from "./containers/career/careeropportunity/Careerdataopportunity";
import BlogPage from "./containers/blog/BlogPage";
import BlogDetails from "./containers/blog/BlogDetails";
import CareerjobDetail from "./containers/career/careeropportunity/CareerjobDetail";
import TrainingCourses from "./containers/career/traininganddevlopment/TrainingCourses";
import Courses from "./components/Dfferentcourses/Courses";
import portfolioDetails from "./containers/portfolio/portfolioDetails";
import Portfolio from "./containers/portfolio/portfolio";
import PageNotFound from "./containers/PgeNotFound/PageNotFound";
import Digitalmarketing from "./containers/services/digitalmarketing/Digitalmarketing";
import Project from "./containers/Project/Project";

// toast.configure({
//   hideProgressBar: false,
//   closeOnClick: true,
//   pauseOnHover: true,
// });

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Layout>
        <Routes>
          <Route exact path="/" element={<Home />} />

          <Route exact path="/web-development" element={<WebDevlopment />} />
          <Route
            exact
            path="/mobile-application"
            element={<MobileApplication />}
          />
          <Route
            exact
            path="/enterprise-application"
            element={<EnterpriseApplication />}
          />
          <Route
            exact
            path="/digital-marketing"
            element={<Digitalmarketing />}
          />
          <Route exact path="/qa-testing" element={<QAworkings />} />
          <Route exact path="/cloud-enablement" element={<CloudEnablement />} />
          <Route
            exact
            path="/strategic-solution"
            element={<StrategicSolution />}
          />
          <Route
            exact
            path="/technology-consulting"
            element={<TechnologyConsulting />}
          />
          <Route exact path="/get-a-quote" element={<GetaQuote />} />

          <Route Exact path="/how-we-work" element={<HowWeWork />} />
          <Route
            Exact
            path="/devlopment-process"
            element={<DevlopmentProcess />}
          />
          <Route
            exact
            path="/Industries-we-serve"
            element={<IndustriesWeServe />}
          />
          <Route exact path="/helping-startups" element={<HelpingStartups />} />

          <Route exact path="/career" element={<Career />} />
          <Route exact path="/career/:jobpage" element={<CareerjobDetail />} />
          <Route
            Exact
            path="/life-at-perfectkode"
            element={<LifeAtPerfectKode />}
          />
          <Route exact path="/training" element={<Training />} />

          <Route exact path="/blog" element={<BlogPage />} />
          <Route exact path="/blog/:id" element={<BlogDetails />} />

          <Route exact path="/about" element={<About />} />

          <Route exact path="/contact" element={<Contact />} />
          {/* <Route exact path="/careerdata" element={Careerdataopportunity}/> */}
          {/* <Route exact path="/course" element={TrainingCourses} /> */}
          <Route exact path="/training/:courseType" element={<Courses />} />
          <Route
            exact
            path="/portfolio/:projectType"
            element={<portfolioDetails />}
          />
          <Route exact path="/portfolio" element={<Portfolio />} />
          <Route exact path="/project" element={<Project />} />

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
