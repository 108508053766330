import React from "react";
import "./Digitalmarketing.css";
import { Link } from "react-router-dom";
import FAQ from "../../../components/FAQ";
import ServiceBox from "../ServiceBox";
import Button from "../../../components/Button";
import { SiGoogleads } from "react-icons/si";
import { FcAdvertising } from "react-icons/fc";
import { FcGoogle } from "react-icons/fc";

import { MdEditNote } from "react-icons/md";
import { FcLink } from "react-icons/fc";
import { MdOutlineManageAccounts } from "react-icons/md";

import { AiOutlineFieldTime } from "react-icons/ai";
import { TbLanguage } from "react-icons/tb";
import { AiOutlineSolution } from "react-icons/ai";
import { FaSortAmountUp } from "react-icons/fa";

import { MdConnectWithoutContact } from "react-icons/md";
import { BiUserPin } from "react-icons/bi";
import { MdTrackChanges } from "react-icons/md";
import CountUp from 'react-countup';
import DigitalProcces from "../../../assets/images/DigitalProcces.png";

// import {FaRegPaperPlane} from 'react-icons/fa';
// import { SiDependabot } from "react-icons/si";
// import { BsFillDiagram3Fill} from 'react-icons/bs';
// import { MdOutlineContentPaste } from 'react-icons/md';

import { BsGraphUp } from "react-icons/bs";

import MarketingAgency from "../../../assets/images/MarketingAgency.png";
import ServicesBussiness from "../../../assets/images/ServicesBussiness.jpg";
import { Helmet } from "react-helmet";

const Digitalmarketing = () => {
  return (
    <div>
      <Helmet>
        <title>Explore the Best Digital Marketing Company in Noida</title>
        <meta
          name="description"
          content="Perfectkode is the best digital marketing company in Noida and has a proven track record of success in delivering impactful digital marketing campaigns."
        />
        <meta
          name="keywords"
          content="best digital marketing company in noida"
        />
      </Helmet>
      <div className="WebDevlopment">
        Top Digital Marketing Agency
        <h5>Grow your business with our digital marketing services</h5>
        <div>
          <Link to="/getaquote">
            {" "}
            <Button ButtonText="Get A Quote" />
          </Link>
        </div>
      </div>
      <div className="container-secondpart-image-text">
        <div className="row ">
          <div className="col-md-5 ">
            <img
              className="dm-image-dm"
              src={MarketingAgency}
              style={{ width: "90%" }}
              alt="Robotics"
            />
          </div>

          <div className="col-md-7">
            <h1 className="orange-hradinhead"> Top Digital Marketing Agency</h1>
            <p className="para-para-para">
              We are one of the best digital marketing company in noida and we are
              expert in analyzing your current website and building a customized
              strategy to help you realize your business goals. We take pride in
              our work and never stop working until we get you the results you
              need. Our mission is to help you conquer the digital world and
              bring your dream business closer to reality.
            </p>
            <p className="para-para-para">
            With a team of experts in SEO, PPC, social media, and content marketing, we're equipped to tackle any challenge and deliver exceptional results tailored to your unique business needs.
            </p>
            <p className="para-para-para">
            From optimizing your website's performance to crafting compelling ad campaigns, we're committed to maximizing your online presence and driving meaningful engagement with your target audience.
            </p>
            <p className="para-para-para">
              {" "}
              We understand that every client deserves the best services. Our
              main goal is to provide accurate, timely, and correct project
              reports along with detailed progress statuses of your projects. We
              never leave our clients hanging in the dark.
            </p>
          </div>
        </div>
      </div>
      <section className="prof-digital-marketing-service">
        <div className="container">
          <div className="row">
            <div className="col-md-7 mx-auto text-text-part-three">
              <div
                className="digital-marketing-text-heading"
                style={{ visibility: "visible", animation: "fadeInUp" }}
              >
                <h2 classname="professional-heading">
                  Digital Marketing Services To Establish a Solid Foundation For
                  Your Business
                </h2>
                <center>
                  <div
                    data-aos="flip-right"
                    className="h-down-line-under"
                  ></div>
                </center>
              </div>
            </div>
          </div>

          <div
            className="digital-marketing-text-para"
            style={{ visibility: "visible", animation: "fadeInUp" }}
          >
            <p className="para-para-para">
              Being online and online marketing is essential for your business
              to grow and succeed. We are here to help you find the right
              strategy that works for your business. From SEO and Social Media
              Marketing Services to Content Writing and Design, we’re here to
              bring your business the success it deserves.
            </p>

            <p className="para-para-para">
              We provide long-term benefits to our clients with a complete set
              of expertise, skills, and experience in Digital Marketing that
              helps us write success stories for them. Our comprehensive online
              marketing efforts allow us always to solve complex business
              problems associated with their digital marketing initiatives.
            </p>
          </div>
        </div>
      </section>
      <h1 className="professional-heading">Digital Marketing Services</h1>
      <center>
        <div data-aos="flip-right" className="h-down-line-under-hhh"></div>
      </center>

      <div className="icons-card-icons">
        <div className="container">
          <div className="row"data-aos="fade-right">
            <div className="row">
              <div className="col-sm-6  col-md-4 market">
                <div className="IndustriesWeServeBox">
                  <div className="iconsmarketing">
                    <SiGoogleads />
                  </div>
                  <div>
                    <h4>Paid Ads Management</h4>
                    <p></p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6  col-md-4 market">
                <div className="IndustriesWeServeBox">
                  <div className="iconsmarketing">
                    <FcAdvertising />
                  </div>
                  <div>
                    <h4>Social Media Services</h4>
                    <p></p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6  col-md-4 market">
                <div className="IndustriesWeServeBox">
                  <div className="iconsmarketing">
                    <FcGoogle />
                  </div>
                  <div>
                    <h4>Search Engine Optimization</h4>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6  col-md-4 market">
                <div className="IndustriesWeServeBox">
                  <div className="iconsmarketingContent">
                    <MdEditNote />
                  </div>
                  <div>
                    <h4>Content Writing</h4>
                    <p></p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6  col-md-4 market">
                <div className="IndustriesWeServeBox">
                  <div className="iconsmarketing">
                    <FcLink />
                  </div>
                  <div>
                    <h4>Link Building</h4>
                    <p></p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6  col-md-4 market">
                <div className="IndustriesWeServeBox">
                  <div className="iconsmarketing">
                    <MdOutlineManageAccounts />
                  </div>
                  <div>
                    <h4>Online Reputation</h4>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="why-choose-us-as-digital-partner">
        <div className="container">
          <div className="row">
            <div className="col-md-7 mx-auto text-text-part-three">
              <div
                className="digital-marketing-text-heading"
                style={{ visibility: "visible", animation: "fadeInUp" }}
              >
                <h2 classname="professional-heading">Why Choose Us?</h2>
                <center>
                  <div
                    data-aos="flip-right"
                    className="h-down-line-under"
                  ></div>
                </center>
              </div>
            </div>
          </div>
          <div
            className="digital-marketing-text-para"
            style={{ visibility: "visible", animation: "fadeInUp" }}
          >
            <p className="para-para-para">
              {" "}
              We at Perfectkode, as the best digital marketing company in noida,
              are committed to bringing your business benefits and results in
              terms of connecting with potential customers, creating trust and
              building loyal connections between you and them. We offer a broad
              range of online marketing services that help you to reach out to
              prospective clients one-on-one or through an audience in a big
              way. Our SEO Services create a unique position on search engines
              which get your website mentioned on Google Search Result pages.
              Then it leads to Brand Value & credibility. Brand Loyalty that
              converts prospects into subscribers.
            </p>
          </div>
          <div className="row ">
            <div className="col-sm-6  col-md-3 market">
              <div className="IndustriesWeServeBox">
                <div className="iconsmarketing">
                  <AiOutlineFieldTime />
                </div>
                <div>
                  <p>Years Of Experience In Online Marketing </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6  col-md-3 market">
              <div className="IndustriesWeServeBox">
                <div className="iconsmarketing">< TbLanguage /></div>
                <div>
                  <p>Multi-language Marketing Campaign Experience</p>
                </div>
              </div>
            </div>
            <div className="col-sm-6  col-md-3 market">
              <div className="IndustriesWeServeBox">
                <div className="iconsmarketingexpert">
                  <AiOutlineSolution />
                </div>
                <div>
                  <p>
                    Expertise In Delivering Results Across Diffrent Business
                    Domains
                  </p>
                </div>
              </div>
            </div>

            <div className="col-sm-6  col-md-3 market">
              <div className="IndustriesWeServeBox">
                <div className="iconsmarketing">
                  <FaSortAmountUp />
                </div>
                <div>
                  <p>High ROI and Measurable Results</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="benefits-of-digital-marketing">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto text-benefits-of-bussiness">
              <div
                className="heading-benefits-of-digital-marketing-service-to-bussiness"
                style={{ visibility: "visible", animation: "fadeInUp" }}
              >
                <h2 classname="professional-heading">
                  Digital Marketing Service Brings Benefits To Your business
                </h2>
                <center>
                  <div
                    data-aos="flip-right"
                    className="h-down-line-under"
                  ></div>
                </center>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-7">
              <div className="cc-para-para-para-cc">
                <ul
                  className="points-bullet-text-information"
                  style={{ visibility: "visible", animation: "fadeInUp" }}
                >
                  <li className="points-poionts">
                    {" "}
                    Digital marketing is not limited to just one region. As it
                    has become a <b>global industry</b>, there are many ways to
                    promote your brand across the world.
                  </li>
                  <li className="points-poionts">
                    <b>Niche targeting</b> is targeted marketing to a specific
                    audience or group of people who already know and trust you,
                    but you want especially for your product.
                  </li>
                  <li className="points-poionts">
                    Digital marketing is the way to go for business. It’s
                    incredibly <b>affordable</b> and there are numerous ways
                    that you can use it to your advantage.
                  </li>
                  <li className="points-poionts">
                    <b>Trackable and measurable</b> results create a clearer
                    picture of where you are and where you need to go.
                  </li>
                  <li className="points-poionts">
                    We invest in where we are, which means higher{" "}
                    <b>conversion rates</b> as we’re solving customers' needs.
                  </li>
                  <li className="points-poionts">
                    <b> Personalization</b> is the key to making your business
                    stand out from all the others, and it’s something that can
                    be done with limited resources.
                  </li>
                  <li className="points-poionts">
                    Digital Marketing is a way to promote your business and get
                    new customers. So, if you want to be <b>competitive</b> in
                    the market and compete with other digital marketing
                    solutions.{" "}
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-5 ">
              <img
                className="dm-image-dm"
                src={ServicesBussiness}
                style={{ width: "100%" }}
                alt="Robotics"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="why-choose-us-as-digital-partner">
        <div className="container">
          <div className="row">
            <div className="col-md-7 mx-auto text-text-part-three">
              <div
                className="digital-marketing-text-heading"
                style={{ visibility: "visible", animation: "fadeInUp" }}
              >
                <h2 classname="professional-heading">
                  What Makes Us Different?
                </h2>
                <center>
                  <div
                    data-aos="flip-right"
                    className="h-down-line-under"
                  ></div>
                </center>
              </div>
            </div>
          </div>
          <div
            className="digital-marketing-text-para"
            style={{ visibility: "visible", animation: "fadeInUp" }}
          >
            <p className="para-para-para">
              We offer a complete portfolio of digital marketing services for
              small businesses and corporations, like the Best Content Writing
              Services, Email Marketing Services and Social Media Marketing
              Services while covering all bases.
            </p>
          </div>
          <div className="row ">
            <div className="col-sm-6  col-md-3 market">
              <div className="IndustriesWeServeBox">
                <div className="iconsmarketing">
                  <MdConnectWithoutContact />
                </div>
                <div>
                  <h4> Communication</h4>
                  <p>
                    Our CRM tools provide a platform for staying in touch with
                    our clients. We can update them on progress and deliver
                    valuable content, including any special promotions and
                    offers we have to offer.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6  col-md-3 market">
              <div className="IndustriesWeServeBox">
                <div className="iconsmarketing">
                  <BsGraphUp />
                </div>
                <div>
                  <h4>Guaranteed Results</h4>
                  <p>
                    Our SEO services are in no way inferior to the existing
                    market standards. We assure you to get your site ranked on
                    the first page of Google search engine with the help of our
                    unique and precise SEO methods.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6  col-md-3 market">
              <div className="IndustriesWeServeBox">
                <div className="iconsmarketing">
                  <BiUserPin />
                </div>
                <div>
                  <h4>Reliable Management</h4>
                  <p>
                    It is our aim to ensure that each project has a dedicated
                    manager. This will lead to an improved level of
                    communication and interaction.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6  col-md-3 market">
              <div className="IndustriesWeServeBox">
                <div className="iconsmarketing">
                  <MdTrackChanges />
                </div>
                <div>
                  <h4>Track The Progress</h4>
                  <p>
                    We care about our clients and deliver them with regular
                    updates that help them stay a step ahead of the process
                    throughout the entire project - giving you an edge on your
                    competition.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="why-choose-us-as-digital-partner">
        <div className="container">
          <div className="row">
            <div className="col-md-7 mx-auto text-text-part-three">
              <div
                className="digital-marketing-text-heading"
                style={{ visibility: "visible", animation: "fadeInUp" }}
              >
                <h2 classname="professional-heading">Our Work Process</h2>
                <center>
                  <div
                    data-aos="flip-right"
                    className="h-down-line-under"
                  ></div>
                </center>
              </div>
            </div>
          </div>
          <div
            className="digital-marketing-text-para"
            style={{ visibility: "visible", animation: "fadeInUp" }}
          >
            <p className="para-para-para">
              We are best seo company in india and we are focused on the most
              important goal for growing your business- generating leads,
              increasing sales and driving more traffic to your company website.
              Whether you're looking for general search engine optimization or
              website optimization, we have a skilled team that can provide you
              with the results you need..
            </p>
          </div>

          {/* <div className="row ">
                        <div className='col-sm-6  col-md-3 market'>
                            <div className='IndustriesWeServeBox'>
                                <div className='iconsmarketing'>
                                    <FaRegPaperPlane />
                                </div>
                                <div>
                                    <h4>Always Ready To Go Beyond </h4>
                                    <p> For us, success is more than just an agency that delivers tactics instinctively. It's about understanding every aspect of your business; including your market and goals.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6  col-md-3 market'>
                            <div className='IndustriesWeServeBox'>
                                <div className='iconsmarketing'>
                                    <SiDependabot />
                                </div>
                                <div>
                                    <h4>True Dependency</h4>
                                    <p>
                                        We love sharing our thoughts and opinions about how you can start making more of your time. We think it's a win-win situation that works for all involved.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6  col-md-3 market'>
                            <div className='IndustriesWeServeBox'>
                                <div className='iconsmarketing'>
                                    <BsFillDiagram3Fill/>
                                </div>
                                <div>
                                    <h4>Hugging Flexibility</h4>
                                    <p> It's a fast-paced field that constantly adapts to new technologies, trends, and content. As a result, you will have the opportunity to learn something new every day.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6  col-md-3 market'>
                            <div className='IndustriesWeServeBox'>
                                <div className='iconsmarketing'>
                                    <MdOutlineContentPaste />
                                </div>
                                <div>
                                    <h4>Feel Contented</h4>
                                    <p>
                                        We’re a leading content marketing company with a proven formula for Growing Your Business. We have provided companies worldwide with the best services for Immediate and Consistent Revenue Growth.</p>
                                </div>
                            </div>
                        </div>
                    </div> */}
        </div>
        <div>
          <img
            className="DigitalprocessImg"
            src={DigitalProcces}
            alt="Our digital marketing process"
          />
        </div>
      </section>
      <section className="icons-eight-we-beleive-in-success">
        <div className="container we-believe-digital">
          <div className="row client-services">
            <div className="col-md-9 mx-auto text-center">
              <div
                className="header-section-believe-in-success"
                style={{ visibility: "visible", animation: "fadeInUp" }}
              >
                <h2 classname="professional-heading">
                  We believe In Our Success
                </h2>
                <center>
                  <div
                    data-aos="flip-right"
                    className="h-down-line-under"
                  ></div>
                </center>
              </div>
              <div
                className="description-believe-in-success"
                style={{ visibility: "visible", animation: "fadeInUp" }}
              >
                <p className="para-para-para">
                  Start your journey with best seo company in india to better
                  business. Our professional services such as digital marketing,
                  PPC, social media advertising and more will help you reach
                  your goals. Allow us to assist you with the best SEO services
                  that bring you the most return on investmen.
                </p>
              </div>
            </div>
          </div>
          <div className="row text-center mt-5 positive-m30">
            <div className="col-6 col-md-3 d-flex">
              <div
                className="animation-coloured-boxes-purple"
                style={{ visibility: "visible", animation: "fadeInUp" }}
              >
                <div className="hours-of-work-bluelightgradient">
                  <span className="working-hours" data-count="215">
                  <CountUp end={25}  duration={60} />K
                  </span>
                </div>
                <p className="online">Hard Working Hours</p>
              </div>
            </div>
            <div className="col-6 col-md-3 d-flex">
              <div
                className="animation-coloured-boxes-purple"
                style={{ visibility: "visible", animation: "fadeInUp" }}
              >
                <div className="hours-of-work-lightgreen">
                  <span className="working-hours" data-count="215">
                  <CountUp end={1}  duration={60} />k
                  </span>
                </div>
                <p className="online">Total Reviews</p>
              </div>
            </div>
            <div className="col-6 col-md-3 d-flex">
              <div
                className="animation-coloured-boxes-purple"
                style={{ visibility: "visible", animation: "fadeInUp" }}
              >
                <div className="hours-of-work-orange">
                  <span className="working-hours" data-count="215">
                  <CountUp end={200}  duration={60} />K
                  </span>
                </div>
                <p className="online">Well Satisfied Clients</p>
              </div>
            </div>
            <div className="col-6 col-md-3 d-flex">
              <div
                className="animation-coloured-boxes-purple"
                style={{ visibility: "visible", animation: "fadeInUp" }}
              >
                <div className="hours-of-work-green">
                  <span className="working-hours" data-count="215">
                  <CountUp end={200}  duration={60} />L
                  </span>
                </div>
                <p className="online">Lead generated</p>
              </div>
            </div>
            <div className="col-6 col-md-3 d-flex">
              <div
                className="animation-coloured-boxes-purple"
                style={{ visibility: "visible", animation: "fadeInUp" }}
              >
                <div className="hours-of-work-bluegradient">
                  <span className="working-hours" data-count="215">
                  <CountUp end={100} duration={60} />K
                  </span>
                </div>
                <p className="online">Keywords On Top</p>
              </div>
            </div>
            <div className="col-6 col-md-3 d-flex">
              <div
                className="animation-coloured-boxes-purple"
                style={{ visibility: "visible", animation: "fadeInUp" }}
              >
                <div className="hours-of-work-yellow">
                  <span className="working-hours" data-count="215">
                  <CountUp end={5}  duration={60} />K
                  </span>
                </div>
                <p className="online">Years of Experience</p>
              </div>
            </div>
            <div className="col-6 col-md-3 d-flex">
              <div
                className="animation-coloured-boxes-purple"
                style={{ visibility: "visible", animation: "fadeInUp" }}
              >
                <div className="hours-of-work-purple">
                  <span className="working-hours" data-count="215">
                  <CountUp end={200}  duration={60} />
                  </span>
                </div>
                <p className="online">Case Studies</p>
              </div>
            </div>
            <div className="col-6 col-md-3 d-flex">
              <div
                className="animation-coloured-boxes-purple"
                style={{ visibility: "visible", animation: "fadeInUp" }}
              >
                <div className="hours-of-work-darkbluegradient">
                  <span className="working-hours" data-count="215">
                  <CountUp end={90}  duration={60} />%
                  </span>
                </div>
                <p className="online">Project Delivered %</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="container WebDevCon2">
          <h2>FAQs</h2>
          <div className="contentWebDevFQA">
            <FAQ
              FAQQuestion1="What is digital marketing?"
              FAQAnswer1="Digital marketing is the promotion of any kind of products and services through the use of digital technology such as the Internet. Digital marketing also involves display advertising, social media marketing and other digital technology."
              FAQQuestion2="What are diffrent types of digital marketing?"
              FAQAnswer2="Search engine optimization(SEO), Search engine marketing(SMM), Social media marketing(SMM), Content Marketing, Email Marketing, Online advertising, Affiliate marketing and Viral marketing are some of the types in digital marketing."
              FAQQuestion3="What does a digital marketing agency do?"
              FAQAnswer3="A professional digital marketing agency will develop a strategy for your company's online marketing strategy. A professional agency will optimize your digital content in order to increase your SEO rankings. They will also ensure that your content appears in search engines. Digital marketing agencies also engage in marketing via multiple platforms, such as social media, email marketing, and online branding. "
              FAQQuestion4="Why invest in digital marketing?"
              FAQAnswer4="Investing in digital marketing will help you in building brand awareness. You will have a strong online presence. Your brand will be recognized online by people. It will help you in generate quality leads from your targeted audience. And the main thing is you will get a competitive advantage over competitors and be able to boost your profit and revenue."
              FAQQuestion5="How would I begin with digital marketing?"
              FAQAnswer5="We are among the best digital marketing company in noida, Contact us and get started today with the our best Digital Marketing services!"
            />
          </div>
        </div>
      </div>
      <div className="container">
        <ServiceBox
          Heading="Be Found, Be Seen and Get Results."
          ParraFirst="your website is an essential part of your business growth strategy."
          ParraSecond="But if no one can find it? It’s as if you’re missing sales"
        />
      </div>
    </div>
  );
};
export default Digitalmarketing;
