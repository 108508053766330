import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BlogData from "../../assets/json/Blog.json";
import AboutUS from "../../assets/images/AboutUs.jpg";
import { saveBlogData } from "../../stores/reducers/Blog/BlogSlice";
import { useNavigate } from "react-router-dom";
import "./Blog.css";

const BlogCard = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  console.log("data of blog", BlogData);

  const handledetails = () => {
    var detail =
      BlogData.Blog &&
      BlogData.Blog.filter((item) => item.Heading === data.Heading);
    console.log(".......details in Redux ", detail);
    dispatch(saveBlogData(detail));
    navigate("/blog/"+data.id);
  };
  return (
    <div className="constainer height-100">
      <div className="row BlogBox">
        <div className="col-md-5 Blogimg">
          <img src={data.image} className="banner-about" alt="section1" />
        </div>
        <div className="col-md-7">
          <div className="BlogHeading">
            <h5>{data.Heading}</h5>
          </div>
          <p className="margin-bottom-2rem">{data.ShortDescription}</p>
          <div className="Button-margin">
            <button
              className="btn btn-outline-secondary btn-sm"
              onClick={(e) => handledetails(e)}
            >
              {" "}
              READ MORE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
