import React from "react";
import { Link } from "react-router-dom";
import ServiceBox from "../ServiceBox";
import Button from "../../../components/Button";
import Cart from "../../../components/Cart";
import CloudBenifits from "../../../assets/images/CloudBenifits.png";
import { SiMinds } from "react-icons/si";
import { MdShareLocation } from "react-icons/md";
import { AiOutlineDeploymentUnit } from "react-icons/ai";
import FAQ from "../../../components/FAQ";
import availability from "../../../assets/images/availability.jpg";
import management from "../../../assets/images/management.jpg";
import monitoring from "../../../assets/images/monitoring.jpg";
import { Helmet } from "react-helmet";

const CloudEnablement = () => {
  return (
    <>
      <div>
        <Helmet>
          <title>
            Your Trusted Cloud Enablement Service Provider | Perfectkode
          </title>
          <meta
            name="description"
            content="Cloud is the solution to reduce the cost and inefficiencies of your existing environment and we are the top cloud enablement service provider in the industry."
          />
          <meta name="keywords" content="Cloud Enablement Service Provider" />
        </Helmet>
        <div className="WebDevlopment">
          Cloud Enablement Service Provider
          <h5>Excellent And Robust Cloud Solutions Provider</h5>
          <div>
            <Link to="/get-a-quote">
              {" "}
              <Button ButtonText="Get A Quote" />
            </Link>
          </div>
        </div>
        <div className="WebDevCon1">
          <div className="container">
            <h1 className="WebHeader">What Is Cloud Enablement?</h1>
    <center>
                  <div
                    data-aos="flip-right"
                    className="h-down-line-under"
                  ></div>
                </center>
            <div className="contentWebDev">
              <p>
                The cloud enablement process eliminates the costs and
                inefficiencies of your existing environment while providing
                tremendous economic, operational, and administrative business
                value. Cloud enablement creates business flexibility by enabling
                new deployment models, large reductions in cost and operational
                expenditure, data consolidation across sites, faster agility and
                responsiveness to customer demand, and business continuity.
                Enabling cloud capabilities can help you accelerate innovation
                while increasing agility and flexibility.
              </p>
              <p>
                Cloud enablement is the process of making your business ready
                for the cloud. With a cloud-first approach, the cloud moves from
                being an idea to a way of operating that unlocks huge potential
                for your customers.
              </p>
            </div>
            <div className="CartMid"></div>
            <div>
              <img
                style={{ width: "100%" }}
                src={CloudBenifits}
                alt="Cloud Benefits"
              />
            </div>
          </div>
        </div>
        <div className="container WebDevCon2">
          <h2 className="WebHeader">
            Services Rendered By PerfectKode Technologies Include
          </h2>
  <center>
                  <div
                    data-aos="flip-right"
                    className="h-down-line-under4"
                  ></div>
                </center>
          <div className="contentWebDev"></div>
        </div>
        <div>
          <div className="container">
            <div className="row"data-aos="fade-up-right">
              <div className="col-lg-4 md-auto benchmarkbox">
                <div className="IndustriesWeServeBox">
                  <div className="iconsBenchMark">
                    <SiMinds />
                  </div>
                  <div>
                    <p>Cloud Planning And Assessment</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 md-auto benchmarkbox">
                <div className="IndustriesWeServeBox">
                  <div className="iconsBenchMark">
                    <MdShareLocation />
                  </div>
                  <div>
                    <p>Cloud Migration</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 md-auto benchmarkbox">
                <div className="IndustriesWeServeBox">
                  <div className="iconsBenchMark">
                    <AiOutlineDeploymentUnit />
                  </div>
                  <div>
                    <p>Cloud Deployment and Integration</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="CartMid"></div>
        <div className="container WebDevCon2">
          <h2 className="WebHeader">
            Three Things to Highlight While Making Cloud The Core of Your
            Strategy
          </h2>
  <center>
                  <div
                    data-aos="flip-right"
                    className="h-down-line-under6"
                  ></div>
                </center>
          <div className="contentWebDev"></div>
        </div>
        <div>
          <Cart
            CartImg1={availability}
            Paragraph1="Maximum availability of an application or network to ensure the non-stop end-user experience"
            CartImg2={management}
            Paragraph2="Low-cost deployment and efficient management of platforms without compromising on performance."
            CartImg3={monitoring}
            Paragraph3="IT management & monitoring services to ensure availability, security, performance and compliance"
          />
        </div>
        <div className="container">
          <div className="container WebDevCon2">
            <h2>FAQs</h2>
            <div className="contentWebDevFQA">
              <FAQ
                FAQQuestion1="Can we switch to a hybrid cloud model?"
                FAQAnswer1="Yes, It's now possible to switch to a hybrid cloud model. Hybrid cloud computing is when you take advantage of both public and private servers. Your data is stored partly on a private cloud server and partly on a public cloud platform, such as Azure, so you can deploy workloads to a mix of the two. This enables private networking between the two services, so your data stays secure in a hybrid environment."
                FAQQuestion2="How much time does cloud migration take?"
                FAQAnswer2="Cloud migration typically takes an average of six months to complete, but factors like the scope of the migration and cloud onboarding process can often impact the timeline."
                FAQQuestion3="How can we guarantee our cloud model is secure enough for our client's requests?"
                FAQAnswer3="Security information and event management (SIEM) technology makes it simple to consolidate your security infrastructure, reduce complexity, and deploy faster to better meet your security needs. "
                FAQQuestion4="Why do we need cloud enablement services?"
                FAQAnswer4="According to a recent research, it has been found that 63% of companies are increasing investment in cloud services. Cloud enablement services provide businesses with agility and scalability that gives them a competitive advantage. It helps in opening up new markets and providing robust IT infrastructure. With the help of cloud enablement services, businesses can utilize the full potential of the cloud at all levels in additional to implementing the right strategies for insights."
                FAQQuestion5="How would I begin with cloud enablement services?"
                FAQAnswer5="We are the best Cloud Enablement Service Provider, Contact us and get started today with the cloud enablement services!"
              />
            </div>
          </div>
        </div>
        <div className="container">
          <ServiceBox
            Heading="We can help your organization to scale your cloud footprint quickly"
            ParraFirst="Reduces the risk of complex upgrades and migrations"
            ParraSecond="Contact us and get started today with the best cloud enablement services!"
          />
        </div>
      </div>
    </>
  );
};

export default CloudEnablement;
