import React from "react";
import "./Lifebanner.css";

const Lifebannerlife = () => {
    return (
        <div>
            <img className="BannerLifeAtPerfectkode" src= "https://firebasestorage.googleapis.com/v0/b/perfectkode1.appspot.com/o/Training%2FLifeatPerfectkodeV3.jpg?alt=media&token=751efcca-a59b-41e7-ad95-c8b9428989e6"/>
        </div>
    )
}
export default Lifebannerlife;