import React from 'react';
import './map.css';

const Mapcart =() => {
   
    return(
        <iframe class="gmap_iframe"  frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?hl=en&amp;q=perfectkode &amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=A&amp;output=embed"></iframe>
       
      
    )
}
export default Mapcart;