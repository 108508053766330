import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import BlogData from "../../assets/json/Blog.json";
import { Link } from "react-router-dom";
import { saveBlogData } from "../../stores/reducers/Blog/BlogSlice";
import { useNavigate } from "react-router-dom";

const RenderLatestBlog = ({latestBlog}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    console.log("latestBlog",latestBlog.id)
    const handledetails = () => {
        var detail =
          BlogData.Blog &&
          BlogData.Blog.filter((item) => item.id === latestBlog.id);
        console.log(".......details in Redux ", detail);
        dispatch(saveBlogData(detail));
        navigate("/blog/"+latestBlog.id);
      };
  return (
    <>
       


      <div className="col-md-5">
        <img src={latestBlog.image} className="banner-about" alt="section1" />
      </div>
      <div className="col-md-7 blog-card">
        <h3 className="Blog-Head">{latestBlog.Heading}</h3>
        <p className="Blog-Text">{latestBlog.ShortDescription}</p>
        <button
              className="btn btn-outline-secondary btn-sm"
              onClick={(e) => handledetails(e)}
            >
              {" "}
              READ MORE
            </button>
      </div>

    </>
  )
}

export default RenderLatestBlog
