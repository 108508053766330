import React from 'react'
import "./Careerdataopportunity.css"
import jobdatadata from "../../../assets/json/jobrequirement.json"
import CareerjobCard from './CareerjobCard'
const Careerdataopportunity = () => {
    console.log("abc", jobdatadata);

    return (
        <div>
            <div className='container careerdataoppor'>
                <div className='row'>
                    <div className='padding-bottom-job-profile'>
                        <h1 className='app-testing-heading-cap' >JOB PROFILES</h1>
                        <center><div data-aos="flip-right" className='Underline_Jobprofile'></div></center>
                    </div>

                    {jobdatadata && jobdatadata.job && jobdatadata.job.map((item, index) => (
                        <>
                            <div className='col-lg-4 col-md-auto develop-it-services-recruit-analy'>
                                <CareerjobCard key={index} data={item} />
                            </div>
                        </>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Careerdataopportunity;