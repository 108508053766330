import React, { useEffect, useState } from "react";
import BlogData from "../../assets/json/Blog.json";
import BlogCard from "./BlogCard";
import AboutUS from "../../assets/images/AboutUs.jpg";
import { Link } from "react-router-dom";
import "./Blog.css";
import { GoSearch } from "react-icons/go";
import Pagination from "../../components/Pagination/Pagination";
import ServiceBox from "../services/ServiceBox";
import { Button } from "reactstrap";
import RenderLatestBlog from "./RenderLatestBlog";

const BlogPage = () => {
  const [blogList, setBlogList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const PageSize = 2;

  useEffect(() => {
    const sortedBlogs = BlogData.Blog.sort(
      (a, b) => new Date(b.DateTime) - new Date(a.DateTime)
    );
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    setBlogList(sortedBlogs.slice(firstPageIndex, lastPageIndex));
  }, [currentPage, selectedCategory]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    // Scroll to top
    window.scrollTo(0, 0);
    // Reload page
    window.location.reload();
  };

  // Get the latest blog
  const latestBlog = BlogData.Blog[0];

  // Filter blogs based on the selected category
  const filteredBlogs = selectedCategory
    ? BlogData.Blog.filter((blog) => blog.Categories.includes(selectedCategory))
    : blogList;

  return (
    <div>
      <div>
        <center>
          <h1>Blogs</h1>
        </center>
        <center>
          <div data-aos="flip-right" className="border-small"></div>
        </center>
      </div>
      <div className="container">
        <div className="row blog-blog-page">
          {latestBlog && <RenderLatestBlog latestBlog={latestBlog} />}
        </div>
        <hr />
        <div className="latest-blog">
          <div>
            <h2>Latest Blog</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-9">
            {filteredBlogs.map((item, i) => (
              <div className="md-auto BlogBoxMain" key={i}>
                <BlogCard data={item} />
              </div>
            ))}
            <div style={{ float: "right" }}>
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={BlogData.Blog.length}
                pageSize={PageSize}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="category-box">
              <div>
                <div>
                  <h3 className="content-category">Categories</h3>
                  <hr style={{ color: "orange" }} />
                  {[
                    "API Development",
                    "APP Development",
                    "Blockchain",
                    "Clutch",
                    "Content Writing",
                  ].map((category, index) => (
                    <div key={index}>
                      <p
                        className="content-category"
                        onClick={() => handleCategoryClick(category)}
                      >
                        {category}
                      </p>
                      <hr />
                    </div>
                  ))}
                  <p
                    className="content-category"
                    onClick={() => setSelectedCategory(null)}
                  >
                    View All
                  </p>
                </div>
              </div>
            </div>
            <div className="help-card">
              <div>
                <p>Do You Need Help With App & Web Development Services?</p>
              </div>
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <Link className="help-button" to="">
                  No
                </Link>
                <Link className="help-button" to="/get-a-quote">
                  Yes
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPage;
